import dayjs from 'dayjs';

export const BADGE_STATUS = {
  0: 'cyan',
  2: 'green',
  3: 'purple',
  4: 'volcano',
  5: 'magenta',
  99: 'red',
};

export const getListData = (value, data) => {
  const dateValue = dayjs(value).format("DD/MM/YYYY");
  return data?.filter((d) => d.date === dateValue) || [];
};



const startYear = 2023;
const endYear = dayjs().add(1, "year").year();

const range = (start, end) => Array.from({ length: end - (start - 1) }, (_, i) => {
  return ({ value: endYear - i, label: endYear - i });
})

export const yearList = range(startYear, endYear);

export const months = Array.apply(null, Array(12)).map((monthNumber, i) => {
  return dayjs().month(i).format('MMM');
});

export const getEventData = (date, selectedDays) => {
  const dateToString = date.format('DD/MM/YYYY');
  return selectedDays.includes(dateToString);
};

import { VerticalSpace } from 'components/space/vertical-space';
import { DisabilityFamilyData } from '../components/disability-family-data';
import { DisabilityEducationWorkData } from '../components/disability-education-work-data';

export const DisabilityGeneralInformation = () => {
  return (
    <>
      <VerticalSpace>
        <DisabilityFamilyData />
        <DisabilityEducationWorkData />
      </VerticalSpace>
    </>
  );
};

import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Outlet, useParams } from 'react-router-dom';

import { MyServices } from "./my-services";
import { PersonalServices } from "../personal-services";
import {APPLICATION_TYPES} from "../applications/constants";

export const Services = () => {
    const [activeTab, setActiveTab] = useState();
    const params = useParams();

    const onChange = (val) => {
        setActiveTab(val);
    };

    useEffect(() => {
        params.type && setActiveTab(+params.type);
    }, [params.type]);


    const items = [
        {
            key: APPLICATION_TYPES.MY_APPLICATION,
            label: `Ծառայություն`,
            children: +params.type === APPLICATION_TYPES.MY_APPLICATION ? <Outlet /> : <MyServices />
        },
        {
            key: APPLICATION_TYPES.OTHER_APPLICATION,
            label: `Իմ ծառայությունները`,
            children: +params.type === APPLICATION_TYPES.OTHER_APPLICATION ? <Outlet /> : <PersonalServices />,
        }
    ];

    return (
        <Tabs defaultActiveKey={1} activeKey={activeTab} items={items} onChange={onChange} destroyInactiveTabPane={true} />
    )
}



import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { DefaultUserContent } from './default-user-content';
import { SubHeader } from '../sub-header';
import { useAuth } from '../../../context/auth-context';
import { BackgroundLayout } from '../main-layout';

export const LayoutUser = ({ content, footer }) => {
  const { user } = useAuth();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {user && <SubHeader text="Դիմում ընտանիքի անապահովության նպաստ  ստանալու մասին" />}
      <BackgroundLayout>
        {content || (
          <DefaultUserContent>
            <Outlet />
          </DefaultUserContent>
        )}
        {footer}
      </BackgroundLayout>
    </Layout>
  );
};

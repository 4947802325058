import { Skeleton } from 'antd';
import {URL_NOTIFICATION_TYPES} from "../../../../api/benefit/constants";
import { useGetFfromBenefit } from '../../../../api/benefit/use-get-dictionary';
import { FormSectionFullCard } from '../../../card/form-section-full-card';
import { ApplciationNotification } from './application-notification';

export const Notify = () => {
  const { data, isInitialLoading } = useGetFfromBenefit(URL_NOTIFICATION_TYPES);
  if (isInitialLoading) {
    return <Skeleton />;
  }
  return (
    <FormSectionFullCard>
      <ApplciationNotification data={data} />
    </FormSectionFullCard>
  );
};

import { Row, Col, Typography } from 'antd';


export const Footer = ({ printDate }) => {
    return (
        <div style={{ paddingTop: '20px', width: '100%' }}>
            <Row justify="space-between">
                <Col>
                    <Typography.Text strong>Տպման ամսաթիվ</Typography.Text>
                </Col>
                <Col>
                    <Typography.Text strong>{printDate}</Typography.Text>
                </Col>
            </Row>
        </div>
    )
}


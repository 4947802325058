import { Col, Form, Row } from 'antd';
import { URL_GET_ALL_HEALTHS, URL_GET_ALL_OFFICES } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { FormItem } from 'components/form/form-item';
import { Select } from 'components/select';
import { FIELD_NAMES_DEFAULT } from 'components/select/constants';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';

export const disabilityEvaluationList = [
  {
    label: 'ՄՍԾ կենտրոնում',
    value: 2,
  },
  {
    label: 'Գտնվելու վայրում',
    value: 1,
  },
];

export const disabilityEvaluationListSub = [
  {
    id: 1,
    name: 'Բժշկական հաստատությունում',
  },
  {
    id: 2,
    name: 'Փաստացի գտնվելու վայրում',
  },
];

export const DisabilityValuationPlaceSelect = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const subLocation = Form.useWatch(['assessmentAt', 'subLocation']);
  const location = Form.useWatch(['assessmentAt', 'location']);
  const community = Form.useWatch(['applicationForm', 'actualAddress', 'communityId']);

  const { data: hospitals } = useGetFfromBenefit(URL_GET_ALL_HEALTHS, {}, { enabled: subLocation === 1 });
  const { data: offices, isInitialLoading: officesLoading } = useGetFfromBenefit(
    URL_GET_ALL_OFFICES,
    { communityId: community?.value },
    {
      enabled: location === 2 && !!community?.value,
    }
  );

  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        {`Խնդրում ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'Է'} գնահատումն իրականացնել`}
      </Title>
      <Row>
        <Col span={7}>
          <FormItem name={['assessmentAt', 'location']} rules={[{ required: true }]}>
            <Select options={disabilityEvaluationList} placeholder="Ընտրել" />
          </FormItem>
        </Col>
      </Row>
      {!!location && (
        <Row>
          <Col span={7}>
            {location === 2 ? (
              <FormItem name={['assessmentAt', 'offices']} rules={[{ required: true }]}>
                <Select
                  options={offices}
                  placeholder="Ընտրել"
                  fieldNames={FIELD_NAMES_DEFAULT}
                  loading={officesLoading}
                />
              </FormItem>
            ) : (
              <FormItem name={['assessmentAt', 'subLocation']} rules={[{ required: true }]}>
                <Select options={disabilityEvaluationListSub} placeholder="Ընտրել" fieldNames={FIELD_NAMES_DEFAULT} />
              </FormItem>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={7}>
          {subLocation === 1 && (
            <FormItem name={['assessmentAt', 'hospitals']} rules={[{ required: true }]}>
              <Select options={hospitals} placeholder="Ընտրել" fieldNames={FIELD_NAMES_DEFAULT} labelInValue />
            </FormItem>
          )}
        </Col>
      </Row>
    </VerticalSpace>
  );
};

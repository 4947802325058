import { DOCUMENT_TYPES } from 'helpers/constants';
import { VerticalSpace } from '../../../space/vertical-space';
import { FormRequiredInfo } from '../../../typography/form-required-info';
import { Info } from '../../../typography/info';
import { UploadApplicationDocument } from '../../../upload/upload-application-documents';
import { Form } from '../../form';
import { LegalRepresentative } from './legal-representative';
import { OtherPersonType } from './other-person-type';

export const ApplicationNotForMe = () => {
  const applicationFor = Form.useWatch('fillingApplication');
  const profileData = Form.useWatch(['profileData', 'legalRepresentative'], { preserve: true });
  const form = Form.useFormInstance();

  return applicationFor === 2 ? (
    <VerticalSpace>
      <OtherPersonType />
      <Info text="Խնդրում ենք կցել տեղականք" />
      <UploadApplicationDocument
        infoText="Խնդրում ենք կցել տեղական ինքնակառավարման մարմնի կողմից տրամադրված տեղեկանք"
        userData={profileData}
        checksumName={['profileData', 'legalFilesChecksum']}
        tempApplicationId={form.getFieldValue('tempApplicationId')}
        attachedDocumentType={DOCUMENT_TYPES.LEGAL_REPRESENTITIVE}
      />
      <FormRequiredInfo />
      <LegalRepresentative />
    </VerticalSpace>
  ) : (
    <></>
  );
};

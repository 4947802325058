import { Card } from 'antd';
import styled from 'styled-components';

const StyleCard = styled(Card)`
  width: 476px;
  && {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
  }
  
  @media (max-width: 468px) {
    width: 330px;
  }
`;

export const SigninCard = ({ children }) => {
    return (
        <StyleCard
            bordered={false}
        >
            {children}
        </StyleCard>
    );
};

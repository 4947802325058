import { VerticalSpace } from 'components/space/vertical-space';
import { Text, Title } from 'components/typography';
import { Button, Card, Col, Modal, Row, Space } from 'antd';
import { COLORS } from 'helpers/constants';
import { useState } from 'react';

export const InitialConfirmStep = () => {
  const [open, setOpen] = useState(true);
  const confirmationModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      closable={false}
      title={<Title color={COLORS.PRIMARY.BLUE}>Ծանոթություն</Title>}
      footer={
        <div style={{ textAlign: 'left' }}>
          <Row align="center" justify="center">
            <Col span={8}>
              <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                <Button type="primary" shape="round" size="large" onClick={confirmationModal}>
                  Կարդացել եմ
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      }
      open={open}
      width="70%"
      destroyOnClose
    >
      <Card bordered={true} style={{ overflow: 'auto', height: '70vh' }}>
        <VerticalSpace>
          <Text>Հարգելի քաղաքացի,</Text>
          <Text>Խնդրում ենք դիմումը լրացնելիս հետեւել հետեւյալ պահանջներին՝</Text>
          <ul>
            <li>
              Ձեր և ընտանիքի մյուս անդամների անձնական տվյալները հարկավոր է լրացնել այնպես, ինչպես դրանք նշված են անձը
              հաստատող փաստաթղթերում
            </li>
            <li>
              Եթե չի լրացել Ձեր 18 տարին, ապա Ձեր համար դիմումը պետք է լրացվի Ձեր ծնողի, կամ օրինական ներկայացուցչի
              (խնամակալ, հոգաբարձու) կողմից
            </li>
            <li>
              Եթե դուք դիմումը լրացնում եք որպես օրինական ներկայացուցիչ, ապա խնդրում ենք կցել խնամակալ կամ հոգաբարձու
              լինելու մասին համապատասխան որոշման լուսապատճենը
            </li>
            <li>
              Եթե դիմումը լրացնելիս անհրաժեշտ է կցել որևէ փաստաթուղթ, խնդրում ենք վստահ լինել, որ կցում եք այդ
              փաստաթուղթը ճիշտ եւ սահմանված պահանջներին համապատասխանող թվային տարբերակով
            </li>
            <li>Կցվող փաստաթղթերը կարող են լինել jpg/jpeg/png/pdf ֆորմատի եւ առավելագույնը 10 MB չափի</li>
            <li>Դիմումը հարկավոր է լրացնել հայատառ</li>
            <li>
              Պարտադիր դաշտերը ցուցադրված են <span style={{ color: COLORS.ALERT.RED }}>*</span> նշանով։
            </li>
          </ul>
          <Text>
            Կախված ընտանիքի պայմաններից, դիմումին պետք է կցել համապատասխան փաստաթղթեր։ Անհրաժեշտ փաստաթղթերի ցանկը
            հետեւյալն է՝
          </Text>
          <ul>
            <li>
              <Text strong>
                Տեղեկանք տրված տեղական ինքնակառավարման մարմնի կողմից <br />
                Փաստաթուղթը կցվում է այն դեքում, երբ Ձեր հաշվառման հասցեն պետական, համայնքային ծրագրերով կամ աղետի
                հետեւանքով քանդված շենք/շինություն է
              </Text>
            </li>
            <li>
              Տեղեկանք տրված զորակոչային և զորահավաքային համալրման ծառայության տարածքային ստորաբաժանումից
              (զինկոմիսարիատից) Փաստաթուղթը կցվում է այն դեպքում, երբ Ձեր ընտանիքի անդամներից որևէ մեկը պարտադիր
              ժամկետային զինծառայող է
            </li>
            <li>
              Տեղեկանք տրված ուսումնական հաստատության կողմից Փաստաթուղթը կցվում է այն դեպքում, երբ Դուք կամ Ձեր ընտանիքի
              որեւէ անդամ առկա ուսուցման ուսանող է
            </li>
            <li>
              <Text strong>Տեղեկանք տրված համայնքային անասնաբույժի կողմից</Text>
              <br />
              Փաստաթուղթը կցվում է այն դեպքում, երբ Դուք կամ Ձեր ընտանիքն ունի ընտանի կենդանի(ներ)
            </li>
            <li>
              <Text strong>Ստորագրված համաձայնության թերթիկ</Text>
              <br />
              Համաձայնության թերթիկով Դուք եւ Ձեր ընտանիքի չափահաս անդամները, իսկ օրինական ներկայացուցչի կողմից դիմումը
              ներկայացնելիս՝ նաեւ Ձեր օրինական ներկայացուցիչը (խնամակալ կամ հոգաբարձու), տալիս եք համաձայնություն այն
              մասին, որ դիմումում ներառված բոլոր անձինք համաձայն են ներկայացվող դիմումի բովանդակության հետ, ինչպես նաեւ
              ընդունում են դիմումի հետ կապված գործընթացի պայմաններն ու պարտավորությունները։
            </li>
          </ul>
          <Text strong>Ուշադրություն </Text>
          <ul>
            <li>
              Ձեր դիմումը չի համարվի ընդունված, եթե կցված չէ <Text strong>համաձայնության թերթիկը։</Text>
            </li>
            <li>
              Համաձայնության թերթիկը անհրաժեշտ է կցել <Text strong>5-օրյա ժամկետում՝</Text> դիմումի ներկայացման պահից
              սկսած։
            </li>
            <li>
              Համաձայնության թերթիկը ստեղծվում է ինքնաշխատ՝ Ձեր լրացրած դիմումի տվյալների հիման վրա։ Այն հարկավոր է տպել
              եւ կցել դիմումին Ձեր կամ Ձեր օրինական ներկայացուցչի անհատական կաբինետից կամ Ձեր բնակության վայրը սպասարկող
              միասնական սոցիալական ծառայության (ՄՍԾ) տարածքային կենտրոնի աշխատակցի օգտահաշվից։
              <ul>
                <li>
                  Դիմումատուի անհատական կաբինետի «Իմ դիմումները» բաժնի տվյալ դիմումի էջում՝ «Տպել թերթիկը» հրահանգի
                  միջոցով անհրաժեշտ է տպել համաձայնության թերթիկը։ Թերթիկը տպելուց եւ ստորագրելուց հետո, այն անհրաժեշտ է
                  կցել դիմումին անհատական կաբինետի նույն էջում «Կցել թերթիկը» հրահանգի միջոցով։ Այնուհետեւ հարկավոր է
                  սեղմել «Հաստատել» սեղմակը։
                </li>
                <li>
                  Օրինական ներկայացուցչի անհատական կաբինետի «Իմ դիմումները» բաժնի «Օրինական ներկայացուցչի
                  ներգրավվածություն» ենթաբաժնի տվյալ դիմումի էջում՝ «Տպել թերթիկը» հրահանգի միջոցով անհրաժեշտ է տպել
                  համաձայնության թերթիկը։ Թերթիկը տպելուց եւ ստորագրելուց հետո, այն անհրաժեշտ է կցել դիմումին անհատական
                  կաբինետի նույն էջում «Կցել թերթիկը» հրահանգի միջոցով։ Այնուհետեւ հարկավոր է սեղմել «Հաստատել» սեղմակը։
                </li>
                <li>
                  Եթե հնարավորություն չկա համաձայնության թերթիկը կցել անհատական կաբինետում, ապա կարող եք մոտենալ Ձեր
                  դիմումում նշված հասցեն սպասարկող միասնական սոցիալական ծառայության (ՄՍԾ) տարածքային կենտրոն, որտեղ
                  մասնագետը Ձեզ կօգնի կցել այն։
                </li>
              </ul>
            </li>
            <li>
              Եթե Ձեզ ուղարկվել է հարցում դիմումում անճշտությունը վերացնելու նպատակով, ապա խմբագրված դիմումը
              ներկայացնելուց հետո անհրաժեշտ է կցել նոր համաձայնության թերթիկ։
              <ul>
                <li>
                  Նոր համաձայնության թերթիկ կարելի է տպել եւ կցել Ձեր կամ Ձեր օրինական ներկայացուցչի անհատական կաբինետից
                  կամ Ձեր բնակության վայրը սպասարկող միասնական սոցիալական ծառայության (ՄՍԾ) տարածքային կենտրոնի
                  աշխատակցի օգտահաշվից
                </li>
                <li>
                  Այս դեպքում նույնպես, համաձայնության թերթիկը անհրաժեշտ է կցել <Text strong>5-օրյա ժամկետում՝</Text>{' '}
                  խմբագրված դիմումի ներկայացման պահից սկսած։
                </li>
              </ul>
            </li>
            <li>
              Դիմումում անճշտությունների վերացման նպատակով կա հարցման ընդամենը մեկ հնարավորություն, հետեւաբար տվյալները
              լրացնելիս եւ փաստաթղթեր կցելիս անհրաժեշտ է հավաստիանալ դրանց ճշտության մեջ։
            </li>
            <li>
              Հարցված դիմումը խմբագրելու եւ կրկին ներկայացնելու համար սահմանված է 3-օրյա ժամկետ՝ հարցման պահից սկսած։
            </li>
            <li>Եթե հարցման արդյունքում այնուհանդերձ Ձեր ներկայացրած տվյալները թերի կհամարվեն, Ձեր դիմումը կմերժվի։</li>
            <li>
              Դիմումի ընթացքի, ինչպես նաեւ հետագա քայլերի մասին Դուք եւ Ձեր ընտանիքի անդամները կստանան ծանուցումներ
              էլեկտրոնային նամակով Ձեր կողմից նշած էլ. փոստի հասցեներին։
            </li>
            <li>
              Եթե ցանկանում եք ստանալ ծանուցումները նաեւ SMS տարբերակով, ապա դիմումում պետք է նշել համապատասխան
              ընտրության կոճակը։
              <ul>
                <li>
                  Հիմնական ծանուցումները, ներառյալ՝ SMS ծանուցումները ուղարկվում են միայն դիմումատուին (եւ օրինական
                  ներկայացուցչին, եթե կլինի այդպիսին):
                </li>
              </ul>
            </li>
          </ul>
          <Text>
            Առաջացած խնդիրների կամ հարցերի դեպքում խնդրում ենք մոտենալ Ձեր բնակության վայրը սպասարկող միասնական
            սոցիալական ծառայության (ՄՍԾ) տարածքային կենտրոն։
          </Text>
        </VerticalSpace>
      </Card>
    </Modal>
  );
};

import { useState } from 'react';
import { Popover, Badge } from 'antd';

import { Button } from '../../components/button';
import { SiderNotifications } from './sider-notifications';
import { BellOutlined } from '@ant-design/icons';
import { useGetNotifications } from '../../api/notifications/use-get-notification';
import { GET_UNREAD_FOR_CURRENT_USER } from '../../api/notifications/constants';


export const NotificationManage = () => {
    const [notificationModal, setNotificationModal] = useState(false);

    const { data: newData } = useGetNotifications(GET_UNREAD_FOR_CURRENT_USER, {}, {
        enabled: true,
    });
    const handleOpenChange = (newNotificationModal) => {
        setNotificationModal(newNotificationModal);
    };


    return (
        <Popover
            placement="bottom"
            trigger="click"
            content={<div style={{ width: 323, maxHeight: '90%' }} ><SiderNotifications /></div>}
            open={notificationModal}
            onOpenChange={handleOpenChange}
            getPopupContainer={trigger => trigger.parentElement}
            autoAdjustOverflow={false}
            destroyTooltipOnHide
        >
            <Badge dot={newData} color='#EA8F95' offset={[-17, 8]} >
                <Button type="text" style={{ padding: '0px 10px' }} onClick={() => setNotificationModal(true)}>
                    <BellOutlined style={{ cursor: 'pointer', fontSize: '23px' }} />
                </Button>
            </Badge>
        </Popover>
    );
}; 

import { Col, Form, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_ALL_DONOR_CATEGORIES, URL_GET_SUPPORT_CATEGORIES } from 'api/urrp/constants';
import { FormSectionCard } from 'components/card/form-section-card';
import { createOptionsFromArray } from 'helpers/utils';

export const SupportDetails = () => {
  const form = useFormInstance();
  const selectedSphere = useWatch('category', {
    preserve: true,
  });
  const { data: sphere } = useGetFfromBenefit(URL_GET_ALL_DONOR_CATEGORIES);
  const { data: supportTypes } = useGetFfromBenefit(
    URL_GET_SUPPORT_CATEGORIES,
    {
      refId: selectedSphere?.value,
    },
    {
      enabled: !!selectedSphere,
    }
  );

  return (
    <FormSectionCard title={'Աջակցության տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={'category'} label="Գործունեության ոլորտ" rules={[{ required: true }]}>
            <Select
              labelInValue
              options={sphere?.map((item) => {
                return { label: item.name, value: item.id };
              })}
              onChange={() => form.resetFields(['support'])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={'support'}
            label="Աջակցության տեսակ"
            tooltip="Նշեք Ձեր կամ Ձեր կազմակերպության գործունեության հիմնական ոլորտներից մեկը"
            rules={[{ required: true }]}
          >
            <Select options={createOptionsFromArray(supportTypes)} labelInValue />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};

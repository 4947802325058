import { Outlet } from 'react-router-dom';
import { Header } from '../layouts/header';

export const MainRoute = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

import { VerticalSpace } from '../../../space/vertical-space';
import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { Text, Title } from '../../../typography';
import { COLORS } from '../../../../helpers/constants';
import { ActualResidentialAddress } from '../actual-residential-address';
import { PersonAddress } from '../../person-address';
import { BuildingInfo } from './building-info';
import { Form } from '../../form';
import { BuildingInfoView } from './building-info-view';
import { useApplicationForText } from 'hooks/application/use-application-for-text';
import { actualAddress, formItemStepTwoParent, registrationAddress } from 'pages/application/constants';
import { useEffect } from 'react';
import { useMatches } from 'react-router-dom';

export const RegistrationAddress = ({
  formItemRegAddress = {},
  formItemActualAddressStepTwo,
  formItemAddressType,
  viewMode = false,
  actualAddressAutoFill = false,
}) => {
  const form = Form.useFormInstance();
  const isActualAddressMatch = Form.useWatch(formItemRegAddress.isActualAddressMatch.name);
  const regAddress = form.getFieldValue([formItemStepTwoParent, registrationAddress]);

  const actionType = [...useMatches()].slice(-1)[0].handle;

  useEffect(() => {
    if (actualAddressAutoFill && isActualAddressMatch && actionType !== 'edit') {
      form.setFieldsValue({
        [formItemStepTwoParent]: {
          [actualAddress]: {
            regionId: regAddress.regionId,
            region: regAddress.region,
            communityId: regAddress.communityId,
            community: regAddress.community,
            settlementId: regAddress.settlementId,
            settlement: regAddress.settlement,
            street: regAddress.street,
            building: regAddress.building,
            apartment: regAddress.apartment,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActualAddressMatch]);

  return (
    <FormSectionCard title="Հաշվառման հասցե">
      <VerticalSpace>
        <PersonAddress viewMode={viewMode} formItemAddress={formItemRegAddress} />
        <FormItem
          label={
            <Title level={1} as="p" color={COLORS.PRIMARY.BLUE}>
              {`Բնակվում ${useApplicationForText('եմ', 'է')} վերոնշյալ հաշվառման հասցեում`}
            </Title>
          }
          rules={[{ required: true }]}
          {...formItemRegAddress.isActualAddressMatch}
        >
          <Radio.Group>
            <Space>
              <Radio value={true}>
                <Text color={COLORS.PRIMARY.BLUE_DARK}>Այո</Text>
              </Radio>
              <Radio value={false}>
                <Text color={COLORS.PRIMARY.BLUE_DARK}>Ոչ</Text>
              </Radio>
            </Space>
          </Radio.Group>
        </FormItem>
        <ActualResidentialAddress viewMode={viewMode} formItemActualAddressStepTwo={formItemActualAddressStepTwo} />
        {isActualAddressMatch === false && !viewMode && <BuildingInfo formItemAddressType={formItemAddressType} />}
        {isActualAddressMatch === false && viewMode && <BuildingInfoView />}
      </VerticalSpace>
    </FormSectionCard>
  );
};

import { Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { COLORS } from '../../helpers/constants';
import { Text } from './text';

export const SuccessText = ({ text }) => {
  return (
    <Space size={5}>
      <CheckOutlined style={{ color: COLORS.ALERT.GREEN }} />
      <Text color={COLORS.ALERT.GREEN}>{text}</Text>
    </Space>
  );
};

import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PaperClipOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Select } from 'antd';
import { URL_GET_ATTACHED_DOCS, URL_GET_CAUSES } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_FILE_BY_NAME } from 'api/file/constants';
import { useFileDelete } from 'api/file/use-file-delete';
import { useGetFiles } from 'api/file/use-get-files';
import axios from 'axios';
import { Datepicker } from 'components/datepicker';
import { FormItem } from 'components/form/form-item';
import { FIELD_NAMES_DEFAULT } from 'components/select/constants';
import { VerticalSpace } from 'components/space/vertical-space';
import dayjs from 'dayjs';
import { useState } from 'react';
import { patternMessage, requiredMessage } from '../constants';
import { useParams } from 'react-router-dom';

export const uploadImgError = (
  <p style={{ marginTop: '50px', fontWeight: 'bold', color: 'red' }}>Փաստաթուղթը չի կցվել</p>
);

export const DisabilityDocumentsAttach = ({ requiredType = 1, isRequired = true }) => {
  const params = useParams();

  const form = Form.useFormInstance();
  const ssn = Form.useWatch(['applicationForm', 'ssn']);
  const birthDate = Form.useWatch(['applicationForm', 'birthDate']);

  const { data: dataCauses } = useGetFfromBenefit(URL_GET_CAUSES);
  const { data: documentList } = useGetFfromBenefit(
    URL_GET_ATTACHED_DOCS,
    { evaluationSubtype: params.subtypeId },
    { enabled: !!params.subtypeId }
  );

  const [getFileUrl, setrFileUrl] = useState(false);
  const [getFileType, setFileType] = useState(false);
  const [getImgPreview, setImgPreview] = useState('');
  const [getImgCheckError, setImgCheckError] = useState(false);
  const [getAddButtonDisabled, setAddButtonDisabled] = useState(true);
  const [getFirstDate, setFirstDate] = useState(false);

  useGetFiles(
    URL_GET_FILE_BY_NAME,
    {
      enabled: !!getFileUrl,
      onSuccess: (data) => {
        const objectURL = URL.createObjectURL(new Blob([data], { type: getFileType }));
        const projects = form.getFieldsValue('projects');

        const updatedProjects = projects.projects.map((project, key) => {
          if (key === getImgPreview) {
            return {
              ...project,
              file_item_show: objectURL,
              file_item_checksum: getFileUrl,
            };
          }

          return project;
        });
        projects.projects = updatedProjects;
        form.setFieldsValue({ ...projects });

        if (projects?.projects.length === 0) {
          setAddButtonDisabled(true);
          setFirstDate(false);
        }
      },
    },
    {
      fileChecksum: getFileUrl,
      contentType: getFileType,
    }
  );

  const handleChange = (e, fieldKey) => {
    e.preventDefault();
    const projects = form.getFieldsValue('projects');
    let boolProj = 0;
    if (projects.projects && projects.projects.length !== 0) {
      //! Check usage of reduce method
      projects.projects.reduce((projItem, projValue) => {
        if (projValue?.doc_type_table === requiredType) {
          boolProj += 1;
        }
      }, []);
    }

    if (boolProj === 0) {
      form.setFieldsValue({
        isMedicalDocumentAttached: '',
      });
    } else {
      form.setFieldsValue({
        isMedicalDocumentAttached: true,
      });
    }

    setImgCheckError(false);
    const fileSize = e.target.files[0].size;
    if (fileSize < 10000000) {
      const fileFields = form.getFieldValue(['projects', fieldKey]);
      // eslint-disable-next-line no-undef
      const url = `${process.env.REACT_APP_BASE_URL + 'EDisability/UploadFiles'}`;
      const img = e.target.files[0];
      const formDataImg = new FormData();
      formDataImg.append(
        'Ssn',
        form.getFieldValue(['applicationForm', 'ssn']) === undefined
          ? ''
          : form.getFieldValue(['applicationForm', 'ssn'])
      );
      formDataImg.append(
        'DocumentNumber',
        fileFields?.for_the_doc_table === undefined ? '' : fileFields?.for_the_doc_table
      );
      formDataImg.append(
        'DocumentName',
        fileFields?.doc_type_name_input === undefined ? '' : fileFields?.doc_type_name_input
      );
      formDataImg.append(
        'DisabilityCauseId',
        fileFields?.doc_type_name_select === undefined ? '' : fileFields?.doc_type_name_select
      );
      formDataImg.append(
        'OfficeName',
        fileFields?.name_of_the_provider_table === undefined ? '' : fileFields?.name_of_the_provider_table
      );
      formDataImg.append(
        'BirthDate',
        form.getFieldValue(['applicationForm', 'birthDate']) === undefined
          ? ''
          : dayjs(form.getFieldValue(['applicationForm', 'birthDate'])).format('YYYY-MM-DD')
      );
      formDataImg.append('Hash', '');
      formDataImg.append(
        'ValidUntil',
        fileFields?.issued_date ? dayjs(fileFields?.issued_date).format('YYYY-MM-DD') : ''
      );
      formDataImg.append(
        'IssuedDate',
        fileFields?.dalivery_date === undefined ? '' : dayjs(fileFields?.dalivery_date).format('YYYY-MM-DD')
      );
      formDataImg.append('AttachedDocumentTypeId', fileFields?.doc_type_table);
      formDataImg.append('File', img);

      axios
        .post(url, formDataImg)
        .then((res) => {
          if (res && res.data.isSuccess === true) {
            setrFileUrl(res.data.fileChecksum);
            setFileType(img.type);
            setImgPreview(fieldKey);
            setAddButtonDisabled(true);
            setFirstDate(false);
          }
        })
        .catch((error) => {
          if (error && (error.response.data.status === 400 || error.response.data.status === 500)) {
            setImgCheckError(uploadImgError);
          }
        });
    } else {
      setImgCheckError(uploadImgError);
    }
  };

  const onFileChange = (event) => {
    event.target.value = '';
  };

  const { mutate: mutateDeleteFile } = useFileDelete({
    onSuccess: () => {
      const projects = form.getFieldsValue('projects');
      if (projects.projects && projects.projects.length !== 0) {
        //! redundant usage of reduce method
        projects.projects.reduce((projItem, projValue) => {
          if (projValue.doc_type_table === 1) {
            form.setFieldsValue({
              isMedicalDocumentAttached: true,
            });
          } else {
            form.setFieldsValue({
              isMedicalDocumentAttached: '',
            });
          }
        }, []);
      }
      if (projects.projects.length === 0) {
        form.setFieldsValue({
          isMedicalDocumentAttached: '',
        });
      }
    },
    enabled: false,
  });

  const handleDelete = (e, fieldKey) => {
    e.stopPropagation();
    const projects = form.getFieldsValue('projects');

    function getFieldValue(arr) {
      for (let i = 0; i <= arr.length; i++) {
        if (i === fieldKey) return arr[i];
      }
    }

    const fileItemChecksum = getFieldValue(projects.projects);
    const updatedProjects = projects.projects.filter((project) => {
      return fileItemChecksum.file_item_checksum !== project.file_item_checksum;
    });
    projects.projects = updatedProjects;
    form.setFieldsValue({ ...projects });

    mutateDeleteFile({
      checksum: fileItemChecksum.file_item_checksum,
      ssn: form.getFieldValue(['applicationForm', 'ssn']),
      birthDate: dayjs(form.getFieldValue(['applicationForm', 'birthDate'])).format('YYYY-MM-DD'),
    });
  };

  const onChangeFirstDate = (e) => {
    var firstDate = e.format('YYYY-MM-DD');
    setFirstDate(firstDate);
  };

  return (
    <VerticalSpace>
      <Form.List name="projects" key={1}>
        {(projects, { add, remove }) => {
          return (
            <div key={1}>
              <Col span={7} sm={24} xs={6} md={24} style={{ overflow: 'scroll' }}>
                <table style={{ width: '100%', textAlign: 'center' }} cellPadding={20}>
                  <thead style={{ background: '#0B847F', color: '#FFF' }}>
                    <tr>
                      <td style={{ borderRadius: '10px 0 0 0' }}>Փաստաթղթի տեսակ</td>
                      <td>Փաստաթղթի անվանում</td>
                      <td>Փաստաթղթի համար</td>
                      <td>Տրամադրող հաստատության անվանում</td>
                      <td>Տրամադրման ամսաթիվ</td>
                      <td>Վավեր է մինչև</td>
                      <td style={{ borderRadius: '0 10px 0 0' }}>
                        {getAddButtonDisabled && getAddButtonDisabled === true && (
                          <PlusSquareOutlined
                            style={{ fontSize: '20px' }}
                            onClick={() => {
                              add(setAddButtonDisabled(false));
                              setFirstDate(false);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((field) => (
                      <tr key={field.key}>
                        <td style={{ margin: 0, padding: 0 }}>
                          <FormItem
                            name={[field.name, 'doc_type_table']}
                            rules={[{ required: true, message: requiredMessage }]}
                          >
                            <Select
                              placeholder="Ընտրել"
                              style={{ width: '200px' }}
                              fieldNames={FIELD_NAMES_DEFAULT}
                              options={documentList}
                            />
                          </FormItem>
                        </td>
                        <td style={{ margin: 0, padding: 5 }}>
                          <FormItem
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues?.projects?.[field.name]?.doc_type_table !==
                              currentValues?.projects?.[field.name]?.doc_type_table
                            }
                          >
                            {({ getFieldValue }) => {
                              return (
                                <div key={2}>
                                  {getFieldValue(['projects', field.name, 'doc_type_table']) === 2 ? (
                                    <FormItem
                                      name={[field.name, 'doc_type_name_select']}
                                      rules={[{ required: true, message: requiredMessage }]}
                                    >
                                      <Select
                                        placeholder="Ընտրել"
                                        style={{ width: '200px' }}
                                        fieldNames={FIELD_NAMES_DEFAULT}
                                        options={dataCauses}
                                      />
                                    </FormItem>
                                  ) : getFieldValue(['projects', field.name, 'doc_type_table']) === 1 ||
                                    getFieldValue(['projects', field.name, 'doc_type_table']) === 3 ||
                                    [4, 5, 6, 7, 8].includes(
                                      getFieldValue(['projects', field.name, 'doc_type_table'])
                                    ) ? (
                                    <FormItem
                                      name={[field.name, 'doc_type_name_input']}
                                      rules={[
                                        { required: true, message: requiredMessage },
                                        {
                                          pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/,
                                          message: patternMessage,
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Լրացնել"
                                        size="large"
                                        style={{
                                          borderRadius: 10,
                                          color: '#000',
                                          width: '200px',
                                        }}
                                      />
                                    </FormItem>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              );
                            }}
                          </FormItem>
                        </td>
                        <td style={{ margin: 0, padding: 5 }}>
                          <FormItem
                            name={[field.name, 'for_the_doc_table']}
                            rules={[
                              { required: true, message: requiredMessage },
                              // { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                            ]}
                          >
                            <Input
                              placeholder="Լրացնել"
                              size="large"
                              style={{ borderRadius: 10, color: '#000', width: '200px' }}
                            />
                          </FormItem>
                        </td>
                        <td style={{ padding: 5, margin: 0 }}>
                          <FormItem
                            name={[field.name, 'name_of_the_provider_table']}
                            rules={[
                              { required: true, message: requiredMessage },
                              { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                            ]}
                          >
                            <Input
                              placeholder="Լրացնել"
                              size="large"
                              style={{ borderRadius: 10, color: '#000', width: '200px' }}
                            />
                          </FormItem>
                        </td>
                        <td style={{ padding: 5, margin: 0 }}>
                          <FormItem
                            name={[field.name, 'dalivery_date']}
                            rules={[{ required: true, message: requiredMessage }]}
                          >
                            <Datepicker
                              onChange={onChangeFirstDate}
                              disabledDate={(current) => current.isAfter(dayjs())}
                              style={{
                                borderRadius: 10,
                                color: '#000',
                                width: '200px',
                              }}
                              size="large"
                              placeholder=""
                            />
                          </FormItem>
                        </td>

                        <td>
                          <FormItem name={[field.name, 'issued_date']}>
                            <DatePicker
                              disabledDate={(current) => {
                                if (getFirstDate !== false) {
                                  return dayjs().add(-1, 'days') >= current;
                                  // return current && current < moment(getFirstDate, "YYYY-MM-DD");
                                  // return current && current < moment();
                                } else {
                                  return current;
                                }
                              }}
                              style={{
                                borderRadius: 10,
                                color: '#000',
                                width: '200px',
                              }}
                              size="large"
                              placeholder=""
                            />
                          </FormItem>
                        </td>

                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <FormItem name={[field.name, 'dalivery_date_hidden']} style={{ visibility: 'hidden' }}>
                            <Input type="hidden" />
                          </FormItem>
                          <FormItem
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues?.projects?.[field.name]?.file_item_show !==
                              currentValues?.projects?.[field.name]?.file_item_show
                            }
                          >
                            {({ getFieldValue }) => {
                              if (getFieldValue(['projects', field.name, 'file_item_show']) === undefined) {
                                return (
                                  <FormItem
                                    key={field.key}
                                    style={{ marginBottom: 0 }}
                                    name={[field.name, 'file_item_upload']}
                                    rules={[{ required: true, message: requiredMessage }]}
                                  >
                                    <label htmlFor="filePicker" style={{ cursor: 'pointer' }}>
                                      <PaperClipOutlined style={{ color: '#000' }} />
                                    </label>
                                    <Input
                                      id="filePicker"
                                      key={1}
                                      style={{
                                        visibility: 'hidden',
                                        width: 0,
                                        height: 0,
                                        padding: 0,
                                      }}
                                      type={'file'}
                                      onChange={(e) => handleChange(e, field.key)}
                                      onClick={onFileChange}
                                    />
                                  </FormItem>
                                );
                              } else {
                                return <CheckOutlined style={{ color: 'green' }} />;
                              }
                            }}
                          </FormItem>

                          {ssn && birthDate && (
                            <div key={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <FormItem
                                style={{ padding: '5px', marginBottom: 0 }}
                                name={[field.name, 'file_item_show']}
                              >
                                <Input type="hidden" />
                              </FormItem>

                              <FormItem
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues?.projects?.[field.name]?.file_item_show !==
                                  currentValues?.projects?.[field.name]?.file_item_show
                                }
                              >
                                {({ getFieldValue }) => {
                                  return (
                                    <a
                                      href={getFieldValue(['projects', field.name, 'file_item_show'])}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <EyeOutlined style={{ color: '#000', marginRight: '7px' }} />
                                    </a>
                                  );
                                }}
                              </FormItem>

                              <FormItem name={[field.name, 'file_item_checksum']}>
                                <Input type="hidden" />
                              </FormItem>
                              <FormItem
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues?.projects?.[field.name]?.file_item_show !==
                                  currentValues?.projects?.[field.name]?.file_item_show
                                }
                              >
                                {({ getFieldValue }) => {
                                  if (getFieldValue(['projects', field.name, 'file_item_show']) !== undefined) {
                                    return (
                                      <CloseOutlined
                                        style={{ color: '#000', cursor: 'pointer' }}
                                        onClick={(e) => handleDelete(e, field.key)}
                                      />
                                    );
                                  } else {
                                    return (
                                      <CloseOutlined
                                        style={{ color: '#000', cursor: 'pointer' }}
                                        onClick={() => {
                                          remove(field.name);
                                          setAddButtonDisabled(true);
                                          setFirstDate(false);
                                        }}
                                      />
                                    );
                                  }
                                }}
                              </FormItem>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
              {getImgCheckError}
            </div>
          );
        }}
      </Form.List>
      <FormItem
        name="isMedicalDocumentAttached"
        rules={[
          ...(params.subtypeId !== '10'
            ? [
                {
                  required: isRequired,
                  message:
                    params.subtypeId === '9'
                      ? 'Պատճառահետևանքային կապը հաստատող փաստաթղթի կցումը պարտադիր է'
                      : params.subtypeId === '8' || params.subtypeId === '11'
                      ? 'Բժշկական փաստաթղթի կցումը, որի հիման վրա պետք է փոփոխվի Ծառայությունների անհատական ծրագիրը (ԾԱԾ), պարտադիր է:'
                      : 'Անհրաժեշտ է կցել բժշկական փաստաթղթերը՝ ընտրելով "Բժշկական փաստաթուղթ" տեսակը',
                },
              ]
            : []),
          {
            validator() {
              const projects = form.getFieldsValue('projects');

              if (
                params.subtypeId !== '10' ||
                documentList
                  .filter((item) => item.isMandatory === true)
                  ?.map((option) => option.id)
                  .every((r) => projects.projects?.map((project) => project?.doc_type_table).includes(r))
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Անհրաժեշտ է կցել բոլոր պարտադիր փաստաթղթերը'));
            },
          },
        ]}
      >
        <Input type="hidden" value={true} />
      </FormItem>
      <div style={{ width: '80%' }}>
        {params.subtypeId === '8' && (
          <p style={{ marginTop: '15px', color: '#555965' }}>
            <ExclamationCircleOutlined /> Բժշկական փաստաթղթի կցումը, որի հիման վրա պետք է փոփոխվի Ծառայությունների
            անհատական ծրագիրը (ԾԱԾ), պարտադիր է:
          </p>
        )}
        <p style={{ marginTop: '15px', color: '#555965' }}>
          <ExclamationCircleOutlined /> Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
        </p>
        {+params.subtypeId === 10 && (
          <p style={{ marginTop: '15px', color: '#555965' }}>
            <ExclamationCircleOutlined /> Անհրաժեշտ կցվող փաստաթղթերն են՝
            <ul>
              <li>
                ծառայության համապատասխան ստորաբաժանման կողմից ԽՍՀՄ զինված ուժերում զինվորական ծառայության ընթացքում
                հաշմանդամություն ձեռք բերած շարքային կազմի զինծառայողների, պաշտպանության նախարարության, արդարադատության
                նախարարության, արտակարգ իրավիճակների նախարարության կողմից իրենց համակարգերի զինծառայողների համար տրված
                ուղեգրերը, որտեղ նշվում է ուղեգրման նպատակը;
              </li>
              <li>մահվան վկայականը;</li>
              <li>մահվան մասին բժշկական վկայականը;</li>
              <li>
                տարած հիվանդությունների կամ կրած վնասվածքների փաստը հավաստող կամ ռազմաբժշկական փաստաթղթերից որևէ մեկը,
                կամ զինվորական կոմիսարիատի տված տեղեկանքը 1986 թվականի ապրիլի 26-ից մինչև 1987 թվականի դեկտեմբերի 31-ը
                Չեռնոբիլի ատոմային էլեկտրակայանի վթարի հետևանքների վերացման աշխատանքների կատարման ժամանակ զինվորական
                ծառայողական պարտականությունները կատարելու մասին կամ Հայաստանի Հանրապետության առողջապահության
                նախարարության «Ճառագայթային բժշկության և այրվածքների գիտական կենտրոն» փակ բաժնետիրական ընկերության
                (նախկինում` բժշկական ճառագայթաբանության գիտահետազոտական ինստիտուտ) տված փաստաթուղթը կամ միջգերատեսչական
                փորձաքննության խորհրդի եզրակացությունը հիվանդության պատճառական կապի մասին;
              </li>
              <li>բժշկական քարտից քաղվածք ( առկայության դեպքում);</li>
              <li>այլ (առկայության դեպքում):</li>
            </ul>
          </p>
        )}
      </div>
    </VerticalSpace>
  );
};

export const menuText = {
  lostJob: {
    label: 'Կորցրել եմ աշխատանքս',
    // subMenu: {
    //     nonReliableApplication: 'Անհուսալի վարկեր'
    // }
  },
  needAllowance: {
    label: 'Կարիք ունեմ',
    subMenu: {
      insecurityAllowance: 'Դիմում Ընտանիքի անապահովության նպաստ ստանալու մասին',
      applicationAppeal: 'Դիմում բողոքարկում',
      firstGrade: 'Դիմում առաջին դասարան ընդունվելու դեպքում միանվագ օգնության մասին',
      emergencyAssistance: 'Դիմում եռամսյա հրատապ օգնություն ստանալու մասին',
      familyComposition: 'Դիմում ընտանիքի կազմի փոփոխության մասին',
      changeAddress: 'Դիմում հասցեի փոփոխության մասին',
    },
  },
  receivePension: (
    <>
      Ցանկանում եմ ստանալ կենսաթոշակ
      <br />
      /պարգևավճար/այլ դրամական վճար
    </>
  ),
  goingHaveBaby: 'Պատրաստվում եմ երեխա ունենալ',
  needCare: 'Ունեմ խնամքի կարիք',
  needApartment: 'Ունեմ բնակարանի կարիք',
  functionalEvaluation: (
    <>
      Ցանկանում եմ անցնել
      <br />
      ֆունկցիոնալության գնահատում
    </>
  ),
  depositRefund: (
    <>
      Ցանկանում եմ ստանալ ավանդի
      <br />
      փոխհատուցում
    </>
  ),
  difficultSituation: {
    label: <>Հայտնվել եմ կյանքի դժվարին <br></br> իրավիճակում</>,
    subMenu: {
      badCredit: 'Ունեմ անհուսալի վարկեր և ցանկանում եմ ֆինանսական աջակցություն ստանալ',
      unifiedRapidResponse: 'Ցանկանում եմ դիմել արագ արձագանքման միասնական հարթակի աջակցությանը',
    },
  },
  provideSupport: 'Տրամադրում եմ աջակցություն',
  displaced: 'ԼՂ-ից տեղահանված եմ',
};

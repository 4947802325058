import { Row } from 'antd';

import { Text } from "../../../components/typography"

export const MyApplicationPrint = () => {
    return (
        <Row gutter={[40]}>
            <Text>
                Խնդրում եմ ինձ (ընտանիքիս) հաշվառել ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում: Կից ներկայացնում եմ իմ և ընտանիքիս չափահաս անդամների համաձայնության հավաստիացումները։
                Մենք՝ ներքոստորագրյալներս, համաձայն ենք սույն դիմումի բովանդակությանը, ինչպես նաև ընդունում ենք դիմումում ներկայացված պարտավորությունները։
            </Text>
            <ul>
                <li>
                    համաձայն ենք, որ ՀՀ կառավարության կողմից սահմանված կարգով ստուգվեն մեր ընտանեկան, այդ թվում՝ ամուսնական կապերը, եկամուտների (աշխատավարձերի, դրանց հավասարեցված այլ վճարումների, բանկային հաշիվների և ավանդների) և ծախսերի (վարկային պարտավորությունների, սպառած խմելու ջրի, էլեկտրաէներգիայի, բնական գազի քանակի) վերաբերյալ տվյալները, ինչպես նաև ՀՀ կառավարության 2014 թ․ 145 – Ն որոշմամբ սահմանված այլ տվյալներ,
                </li>
                <li>
                    երաշխավորում ենք, որ ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում հաշվառվելու և հաշվառված լինելու ընթացքում ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (այսուհետ՝ նախարարություն) միասնական սոցիալական ծառայության տարածքային կենտրոնի (այսուհետ՝ տարածքային կենտրոնի) աշխատողը և ՀՀ օրենսդրությամբ նման իրավասություն ունեցող այլ անձինք իմ ընտանիքի կազմի և պայմանների ուսումնասիրման նպատակով կարող են առանց խոչընդոտների կատարել այս դիմումում ներկայացված և պահանջվող այլ տեղեկությունների հավաստիության ուսումնասիրություն, այդ թվում՝ տնայց,
                </li>
                <li>
                    տեղյակ ենք, որ առաջին տնայցը կատարվելու է դիմումի հաստատումից 15 աշխատանքային օրվա ընթացքում, եւ պարտավորվում ենք համագործակցել տնայց իրականացնողի հետ անհրաժեշտ տվյալների լիարժեք հավաքագրման նպատակով,
                </li>
                <li>
                    երաշխավորում ենք, որ ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում հաշվառվելու և հաշվառված լինելու ընթացքում ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (այսուհետ՝ նախարարություն) միասնական սոցիալական ծառայության տարածքային կենտրոնի (այսուհետ՝ տարածքային կենտրոնի) աշխատողը և ՀՀ օրենսդրությամբ նման իրավասություն ունեցող այլ անձինք իմ ընտանիքի կազմի և պայմանների ուսումնասիրման նպատակով կարող են առանց խոչընդոտների կատարել այս դիմումում ներկայացված և պահանջվող այլ տեղեկությունների հավաստիության ուսումնասիրություն, այդ թվում՝ տնայց,
                </li>
                <li>
                    տեղյակ ենք, որ առաջին տնայցը կատարվելու է դիմումի հաստատումից 15 աշխատանքային օրվա ընթացքում, եւ պարտավորվում ենք համագործակցել տնայց իրականացնողի հետ անհրաժեշտ տվյալների լիարժեք հավաքագրման նպատակով,
                </li>
                <li>
                    համաձայն ենք, որ տնայցի ընթացքում տարածքային կենտրոնի աշխատողը լուսանկարի իմ կամ ընտանիքիս կեցության պայմանների առանձին հատվածներ (առանց լուսանկարելու անձանց դեմքերը), և տեղյակ ենք, որ արված լուսանկարները կիրառվելու են միայն ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում, ինչպես նաև պահվելու և մշակվելու են ՀՀ օրենսդրությամբ սահմանված կարգով,
                </li>
                <li>
                    տեղյակ ենք, որ անապահովության նպաստը (այսուհետ՝ նպաստ) նշանակվում է մինչև դադարեցման հիմքերն առաջանալը, սակայն ոչ ավելի, քան 12 ամիս ժամկետով,
                </li>
                <li>
                    տեղեկացված ենք, որ նպաստ կամ այլ սոցիլական աջակցություն նշանակվում է ընտանիքում չափահաս աշխատունակ, բայց չզբաղված անդամ(ներ)ի՝ զբաղվածության ոլորտի տեղեկատվական համակարգում հաշվառվելու և ակտիվացման ծրագրերում ներառվելու նախապայմանով (ՀՀ կառավարության 2014 թ․ 145 – Ն որոշմամբ սահմանված են բացառությունները),
                </li>
                <li>
                    տեղյակ ենք, որ ընտանիքի մասին ոչ հավաստի տվյալներ ներկայացնելու կամ դրանք թաքցնելու պատճառով ավել վճարված գումարը ՀՀ օրենսդրությամբ սահմանված կարգով հետ է գանձվելու,
                </li>
                <li>
                    պարտավորվում ենք ընտանիքի կազմի, բնակության հասցեի, եկամուտների, ունեցվածքի և այլ բնույթի փոփոխությունների դեպքում 15 օրվա ընթացքում կատարել փոփոխություններ այս դիմումում կամ դրանց մասին տեղյակ պահել տարածքային կենտրոնին` անհրաժեշտության դեպքում ներկայացնելով նոր դիմում և համապատասխան փաստաթղթեր,
                </li>
                <li>
                    համաձայն ենք, որ այս դիմումում նշված անձանց նույնականացման տվյալները փոխանցվեն սոցիալական ծառայություններ տրամադրող այն կազմակերպություներին, որոնք սոցիալական դեպքի վարման ժամանակ պետք է աշխատեն ինձ և/կամ ընտանիքիս անդամների հետ, ինչպես նաև օրենսդրությամբ սահմանված սոցիալական աջակցության ծրագրեր (դրամական և ոչ դրամական աջակցություն, արտոնություններ, զեղչեր և այլն) իրականացնող պետական մարմիններին, տեղական ինքնակառավարման մարմիններին, նախարարության հետ կնքված համագործակցության հուշագրերի (պայմանագրերի) հիման վրա՝ այլ կազմակերպությունների,
                </li>
                <li>
                    համաձայն ենք, որ ընտանիքի անապահովության գնահատման նպատակով այս դիմումում նշված նույնականացման տվյալների հիման վրա իմ եւ ընտանիքիս վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը և հաջորդ մեկ տարվա ընթացքում դրան վերաբերող փոփոխությունները նախարարության և «ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ-ի միջև կնքված պայմանագրով սահմանված կարգով փոխանցվեն նախարարությանը:
                </li>
                <li>
                    համաձայն ենք, որ այս դիմումում նշված չափահաս անձանց նույնականացման տվյալները տրամադրվեն դրույքախաղեր առաջարկող կազմակերպություններին՝ չափահաս անձանց մուտքը խաղադրույքով ծառայություններին արգելափակելու նպատակով:
                </li>
            </ul>
        </Row>
    )
}
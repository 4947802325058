
import { useQuery } from '@tanstack/react-query';

import { client } from '../client';


export const useGetNotifications = (url, params, options = { enabled: true }) => {
  const result = useQuery([url, params], () => client.get(url, { params }), {
    select: (data) => data.data,
    ...options,
  });
  const { data, isSuccess } = result;

  return {
    days: isSuccess ? data?.data : [],
    ...result,
  };
};

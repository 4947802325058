import { Col, Form, InputNumber, Row } from 'antd';
import { Select } from 'components/select';

import { APPLICATION_MODE, genderOptions } from 'pages/rapid-need/constants';
import { useEffect, useState } from 'react';
import { Input } from 'components/input';
import { RejectionReasons } from '../rejection-reason-list';
import { useWatch } from 'antd/es/form/Form';
import { useMatches } from 'react-router-dom';
import { arePathsEqual } from 'pages/rapid-need/utils';
import { PATHS } from 'helpers/constants';
import { URL_GET_SHOE_SIZE } from 'api/urrp/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { createOptionsFromArray } from 'helpers/utils';
import { MinusCircleOutlined } from '@ant-design/icons';
import { FormSectionCard } from 'components/card/form-section-card';
import { VerticalSpace } from 'components/space/vertical-space';

export const ShoeListItem = ({ field, itemId, itemData, remove }) => {
  const [currentNeedData, setCurrentNeedData] = useState({});
  const { data: size } = useGetFfromBenefit(URL_GET_SHOE_SIZE);
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;
  const currentItemData = useWatch(['needs', 'shoes', itemId, 'selections', field.name]);
  const companyList = currentNeedData?.satisfiedCompany?.split(';');

  //* needs additional filtering for gender, size and shoeType as back sends non-filtered and general data for current need
  useEffect(() => {
    const currentNeedMatch = itemData?.find(
      (item) =>
        item?.sex === currentItemData?.gender &&
        item?.sizeId === currentItemData?.size?.value &&
        item?.shoeId === currentItemData?.itemId
    );
    setCurrentNeedData(currentNeedMatch);
  }, [currentItemData, itemData]);
  return (
    <FormSectionCard
      title={
        field.name !== 0 && type !== APPLICATION_MODE.VIEW && <MinusCircleOutlined onClick={() => remove(field.name)} />
      }
    >
      <Row gutter={[12, 12]}>
        <Col span={18}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={8} style={{ width: '100%' }}>
              <Form.Item
                rules={[{ required: true }]}
                label={'Սեռը'}
                style={{ width: '100%' }}
                name={[field.name, 'gender']}
              >
                <Select options={genderOptions} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item hidden initialValue={itemId} name={[field.name, 'itemId']} />
            </Col>
            <Col xs={24} sm={24} md={8} style={{ width: '100%' }}>
              <Form.Item
                rules={[{ required: true }]}
                label={'Չափսը'}
                style={{ width: '100%' }}
                name={[field.name, 'size']}
              >
                <Select options={createOptionsFromArray(size || [])} style={{ width: '100%' }} labelInValue />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} style={{ width: '100%' }}>
              <Form.Item
                rules={[{ required: true }]}
                label={'Քանակը'}
                style={{ width: '100%' }}
                name={[field.name, 'quantity']}
              >
                <InputNumber style={{ width: '100%' }} min={1} max={9999999} />
              </Form.Item>
            </Col>

            {type === APPLICATION_MODE.VIEW && arePathsEqual(match.pathname, PATHS.RAPID_NEED_VIEW) && (
              <>
                <Col span={12}>
                  <Form.Item label="Կարգավիճակ">
                    <Input disabled value={currentNeedData?.status?.label} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {currentNeedData?.satisfiedQuantity ? (
                    <Form.Item label="Բավարարված քանակ">
                      <Input disabled value={currentNeedData?.satisfiedQuantity} />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </Col>
                {currentNeedData?.reasons?.length ? (
                  <Col span={12}>
                    <RejectionReasons reasons={currentNeedData.reasons} />
                  </Col>
                ) : (
                  <></>
                )}
                {currentNeedData?.satisfiedCompany ? (
                  <Col span={12}>
                    <Form.Item label="Ինչ միջոցներով">
                      <VerticalSpace>
                        {companyList.map((name) => {
                          return <Input disabled value={name} key={name} />;
                        })}
                      </VerticalSpace>
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
                <Col span={12}>
                  <Form.Item label="Կարգավիճակի ամսաթիվ">
                    <Input disabled value={currentNeedData?.statusDate} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Կարիքը գնահատող">
                    <Input disabled value={currentNeedData?.socWorkerName} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </FormSectionCard>
  );
};

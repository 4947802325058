import { Table, Row, Col, Typography } from 'antd';

import { useColumns } from './use-column';

export const Info = ({ data, title }) => {

    const columns = useColumns();

    return (
        <div>
            <Row justify="start">
                <Col>
                    <Typography.Title level={5} style={{ color: '#456FAE' }}>{title}</Typography.Title>
                </Col>
            </Row>
            <Table dataSource={data} columns={columns} pagination={false} bordered locale={{ emptyText: ' ' }} />
        </div>
    )
} 
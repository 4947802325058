import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import {URL_EMAIL_CHANGE} from "../../../api/auth/constants";
import {PATHS, REQUEST_TYPES} from "../../../helpers/constants";
import {Form} from "../form";
import {useMutateUserData} from "../../../api/auth/use-mutate-user-data";
import {FormItem} from "../form-item";
import {VerticalSpace} from "../../space/vertical-space";
import {Button} from "../../button/index";
import {Notification} from "../../modal/Notification";
import {Input} from "../../input";
import {errorMessage} from "../../../helpers/utils";
import {Col, Row} from "antd";
import {RegistrationCard} from "../../card/registration-card";

export const EmailChange = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent]= useState(false);

    const [form] = Form.useForm();
    const { mutate } = useMutateUserData(URL_EMAIL_CHANGE, REQUEST_TYPES.POST, {
        onSuccess: () => {
            setContent("Շարունակելու համար խնդրում ենք ստուգել Ձեր էլեկտրոնային փոստը։");
            setIsModalOpen(true);
        },
        onError: errorMessage,
    });
    const onFinish = (values) => {
        mutate(values);
    };

    const handleCancel = () => {
        navigate(`${PATHS.SERVICES}`);
        setContent('');
        setIsModalOpen(false);
    };

    return (
        <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
            <Col lg={5}>
                <RegistrationCard>
                    <Form
                        name="user-form-email"
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={false}
                    >
                        <VerticalSpace size="large">
                            <FormItem
                                name="newEmail"
                                label=" Նոր էլ. հասցե*"
                                rules={[{ required: true }, { type: 'email' }]}
                            >
                                <Input placeholder="example@gmail.com" prefix={<MailOutlined />} />
                            </FormItem>
                            <VerticalSpace size="middle">
                                <Button htmlType="submit" block type="primary" size="large">
                                    Հաստատել
                                </Button>
                            </VerticalSpace>
                        </VerticalSpace>
                    </Form>
                    {
                        isModalOpen &&
                        <Notification centered title="Ծանուցում" footer={null} open={isModalOpen} onCancel={handleCancel}>
                            <p>{content}</p>
                        </Notification>
                    }
                </RegistrationCard>
            </Col>
        </Row>
    );
};
import { DeleteOutlined } from '@ant-design/icons';
import { Col, Collapse, Form, Row, Space, message } from 'antd';
import { Button, ManageFamilyMemberButton } from 'components/button';
import { COLORS } from 'helpers/constants';
import { familyData } from '../constants';
import { Input } from 'components/input';
import { Datepicker } from 'components/datepicker';

import { createOptionsFromArray } from 'helpers/utils';
import { URL_VALIDATE_FAMILY_MEMBER } from 'api/urrp/constants';
import { ArmenianInput } from 'components/input/armenian-input';
import { usePostMutate } from 'api/urrp/use-post-mutate';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useWatch } from 'rc-field-form';
import dayjs from 'dayjs';
import { Select } from 'components/select';
import { FIELD_NAMES_DEFAULT } from 'components/select/constants';

const { Panel } = Collapse;
export const FamilyMemberForm = ({ field, remove, relativeTypes, educationTypes, vulnerability }) => {
  const form = useFormInstance();
  const name = Form.useWatch([familyData, 'familyMembers', field.name, 'name']);
  const surname = Form.useWatch([familyData, 'familyMembers', field.name, 'surname']);
  const isValidated = useWatch([familyData, 'familyMembers', field.name, 'isValidated'], {
    preserve: true,
  });

  const { mutate, isLoading } = usePostMutate(URL_VALIDATE_FAMILY_MEMBER, {
    onSuccess: () => {
      form.setFields([
        {
          name: [familyData, 'familyMembers', field.name, 'isValidated'],
          value: true,
        },
      ]);
      message.success('Անձը նույնականացվել է։');
    },
    onError: () => message.error('Անձը չի նույնականացվել'),
  });

  const removeFamilyMember = () => {
    remove(field.name);
  };

  const handleClick = () => {
    if (isValidated) {
      form.resetFields([
        [familyData, 'familyMembers', field.name, 'name'],
        [familyData, 'familyMembers', field.name, 'surname'],
        [familyData, 'familyMembers', field.name, 'patronymicName'],
        [familyData, 'familyMembers', field.name, 'birthdate'],
        [familyData, 'familyMembers', field.name, 'ssn'],
      ]);
      form.setFields([
        {
          name: [familyData, 'familyMembers', field.name, 'isValidated'],
          value: null,
        },
      ]);
    } else {
      form
        .validateFields([
          [familyData, 'familyMembers', field.name, 'name'],
          [familyData, 'familyMembers', field.name, 'surname'],
          [familyData, 'familyMembers', field.name, 'patronymicName'],
          [familyData, 'familyMembers', field.name, 'birthdate'],
          [familyData, 'familyMembers', field.name, 'ssn'],
        ])
        .then((values) => {
          const memberData = {
            ...values.familyDatas.familyMembers[field.name],
            birthdate: dayjs(values.familyDatas.familyMembers[field.name].birthdate)
              .startOf('day')
              .format('YYYY-MM-DD'),
            firstName: values.familyDatas.familyMembers[field.name].name,
            lastName: values.familyDatas.familyMembers[field.name].surname,
          };

          mutate({
            ...memberData,
          });
        })
        .catch(() => message.error('Լրացրեք բոլոր անհրաժեշտ դաշտերը'));
    }
  };
  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        header={name && surname ? `${name} ${surname}` : 'Ընտանիքի անդամի տվյալներ'}
        key={field.name}
        extra={
          field.name !== 0 && (
            <Space>
              <ManageFamilyMemberButton
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                doAction={removeFamilyMember}
              />
            </Space>
          )
        }
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Անուն" rules={[{ required: true }]} name={[field.name, 'name']}>
              <ArmenianInput disabled={isValidated} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Ազգանուն" rules={[{ required: true }]} name={[field.name, 'surname']}>
              <ArmenianInput disabled={isValidated} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Հայրանուն" rules={[{ required: true }]} name={[field.name, 'patronymicName']}>
              <ArmenianInput disabled={isValidated} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} xl={12}>
            <Form.Item label="Ծննդյան թիվ" rules={[{ required: true }]} name={[field.name, 'birthdate']}>
              <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled={isValidated} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} xl={12}>
            <Form.Item
              label="ՀԾՀ/ՀԾՀ չունենալու վերաբերյալ տեղեկանքի համար"
              rules={[{ required: true }]}
              name={[field.name, 'ssn']}
            >
              <Input disabled={isValidated} />
            </Form.Item>
          </Col>
        </Row>
        <Space direction="horizontal" style={{ justifyContent: 'center', width: '100%', margin: '20px 0' }}>
          <Button
            type="primary"
            danger={isValidated}
            size="large"
            shape="round"
            style={{ margin: '0 auto' }}
            onClick={handleClick}
            loading={isLoading}
          >
            {isValidated ? 'Չեղարկել' : 'Նույնականացնել'}
          </Button>
        </Space>
        <Form.Item hidden name={[field.name, 'isValidated']} rules={[{ required: true }]} initialValue={null} />
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Form.Item name={[field.name, 'relationToMainMember']} label="Կապը դիմողի հետ" rules={[{ required: true }]}>
              <Select options={createOptionsFromArray(relativeTypes || [])} labelInValue />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={[field.name, 'education']} label="Կրթություն" rules={[{ required: true }]}>
              <Select options={educationTypes} fieldNames={FIELD_NAMES_DEFAULT} labelInValue />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'workHistory']}
              label="Աշխատանքային ստաժ՝ վերջին 3 տարվա ընթացքում"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={[field.name, 'physicalIssue']} label="Առողջական խնդիրներ" rules={[{ required: true }]}>
              <ArmenianInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={[field.name, 'mentalIssue']} label="Հոգեբանական խնդիրներ" rules={[{ required: true }]}>
              <ArmenianInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[field.name, 'vulnerability']}
              label="Արդյո՞ք դուք կամ Ձեր ընտանիքի անդամներից որևէ մեկն ունեք նշված սոցիալական կարգավիճակներից"
              rules={[{ required: true }]}
            >
              <Select options={createOptionsFromArray(vulnerability || [])} labelInValue />
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

import {Col, Row} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {
    UserOutlined,
    LogoutOutlined,
    HomeOutlined,
    SettingOutlined,
    FlagFilled,
    PhoneOutlined
} from '@ant-design/icons';

import {useAuth} from '../../context/auth-context';
import {Button} from '../button';
import {COLORS, PATHS} from '../../helpers/constants';
import {NotificationManage} from '../../pages/notifications/notification-manage';

export const ManagePage = () => {
    const navigate = useNavigate();
    const {user, logout} = useAuth();

    const logOut = () => {
        logout();
    };

    return (
        <>
            <Row justify="end" style={{background: user ? "none" : COLORS.SECONDARY.OIL, height: "50px"}} align="middle">
                <Col>
                    {user ?
                        <>
                            <NotificationManage/>
                            <Button type="text" onClick={() => navigate(`${PATHS.HOME}`)} style={{padding: '0px 10px'}}>
                                <HomeOutlined style={{cursor: 'pointer', fontSize: '23px'}}/>
                            </Button>
                            <Button type="text" onClick={() => navigate(`${PATHS.ACCOUNT}`)}
                                    style={{padding: '0px 10px'}}>
                                <UserOutlined style={{cursor: 'pointer', fontSize: '23px'}}/>
                            </Button>
                            <Button type="text" onClick={() => navigate(`${PATHS.SETTINGS}`)}
                                    style={{padding: '0px 10px'}}>
                                <SettingOutlined style={{cursor: 'pointer', fontSize: '23px'}}/>
                            </Button>
                            <Button type="text" onClick={logOut} style={{padding: '0px 10px'}}>
                                <LogoutOutlined style={{cursor: 'pointer', fontSize: '23px'}}/>
                            </Button>
                            {/***********Checkin with management******************/}
                            {/*<Row justify={"end"}>*/}
                            {/*    <Text style={{padding: 20}}>{user?.firstName} {user?.lastName}</Text>*/}
                            {/*</Row>*/}
                        </>
                        :
                        <>
                            <Button type="primary"
                                    onClick={() => navigate(user ? `${PATHS.HOME}` : `${PATHS.SIGN_IN}`, {state: {param: 1}})}
                                    style={{padding: '0px 10px', color: user ? "none" : COLORS.PRIMARY.WHITE, background: "none"}}>
                                <UserOutlined style={{cursor: 'pointer', fontSize: '23px'}}/>
                            </Button>
                            <Button type="primary"
                                    style={{padding: '0px 10px', color: user ? "none" : COLORS.PRIMARY.WHITE, background: "none"}}>
                                <FlagFilled style={{cursor: 'pointer', fontSize: '23px'}}/>
                            </Button>
                            <Button type="primary"
                                    style={{padding: '0px 10px', color: user ? "none" : COLORS.PRIMARY.WHITE, background: "none"}}>
                                <Link to={PATHS.HOT_LINE}><PhoneOutlined style={{cursor: 'pointer', fontSize: '23px'}}/></Link>
                            </Button>
                        </>
                    }
                </Col>
            </Row>
        </>
    );
};


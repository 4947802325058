import { forwardRef } from 'react';
import { Upload } from 'antd';
import { UploadButton } from '../button/upload-button';

export const UploadDocument = forwardRef(({ actionIcon, text, className, ...props }, ref) => {
  return (
    <Upload ref={ref} className={`document-uploader ${className}`} showUploadList={false} {...props}>
      <UploadButton disabled={props.disabled} actionIcon={actionIcon} text={text} />
    </Upload>
  );
});

import { useMutation } from '@tanstack/react-query';
import { client } from '../client';

export const useMutateUserData = (url, type, options) => {
    const mutation = useMutation({
        mutationFn: (values) => {
            return client[type](url, values);
        },
        ...options,
    });
    return mutation;
};

import { Checkbox, Col, Row } from 'antd';
import { InputNumber } from '../../../input-number';
import { Form } from '../../form';
import { FormItem } from '../../form-item';
import { formItemStepTwoParent } from '../../../../pages/application/constants';

export const AnimalFormView = ({ field }) => {
  const form = Form.useFormInstance();
  const name = form.getFieldValue([formItemStepTwoParent, 'animals', field.name, 'name']);

  return (
    <Row>
      <Col span={12}>
        <FormItem valuePropName="checked" value={field.name} name={[field.name, 'id']}>
          <Checkbox>{name}</Checkbox>
        </FormItem>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <FormItem name={[field.name, 'count']}>
          <InputNumber min={1} placeholder="Նշել գլխաքանակ" style={{ width: '160px', float: 'right' }} />
        </FormItem>
      </Col>
    </Row>
  );
};

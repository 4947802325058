import { Col, Radio, Row, Space } from 'antd';
import { files, formItemStepTwoParent } from '../../../../pages/application/constants';
import { FormSectionCard } from '../../../card/form-section-card';
import { Form } from '../../form';
import { AnimalFormView } from './animal-form-view';
import { VerticalSpace } from '../../../space/vertical-space';
import { COLORS } from '../../../../helpers/constants';
import { Text } from '../../../typography';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';

export const ViewFamilyLivestock = () => {
  const hasAnimals = Form.useWatch([formItemStepTwoParent, 'animals']);

  return (
    <FormSectionCard title="Ես/Ընտանիքս ունի ընտանի կենդանիներ">
      <VerticalSpace>
        <Radio.Group value={!!hasAnimals}>
          <Space>
            <Radio value={true}>
              <Text color={COLORS.PRIMARY.BLUE}>Այո</Text>
            </Radio>
            <Radio value={false}>
              <Text color={COLORS.PRIMARY.BLUE}>Ոչ</Text>
            </Radio>
          </Space>
        </Radio.Group>
        <Form.List name={[formItemStepTwoParent, 'animals']}>
          {(fields) => (
            <Row gutter={[24, 24]} justify="space-start">
              {fields.map((field) => (
                <Col span={12} key={field.key}>
                  <AnimalFormView field={field} />
                </Col>
              ))}
            </Row>
          )}
        </Form.List>
        <UploadApplicationDocument
          checksumName={[formItemStepTwoParent, files, 'animalFilesChecksum']}
          viewMode
          noInfo
        />
      </VerticalSpace>
    </FormSectionCard>
  );
};

import { Skeleton } from 'antd';
import { forwardRef } from 'react';
import { Select } from '.';
import { URL_SETTLEMENT_BY_COMMUNITY_ID } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_DEFAULT } from './constants';

export const SettlementSelect = forwardRef(({ communityId, ...props }, ref) => {
  const { data, isInitialLoading } = useGetFfromBenefit(
    URL_SETTLEMENT_BY_COMMUNITY_ID,
    { communityId },
    { enabled: !!communityId }
  );
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />;
  }
  return <Select fieldNames={FIELD_NAMES_DEFAULT} style={{ width: '100%' }} options={data} {...props} ref={ref} />;
});

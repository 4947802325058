import { Form, Radio, Spin } from 'antd';
import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { DisabilityLegalRepresentetive } from '../components/disability-legal-representetive';
import { useNavigate } from 'react-router-dom';
import { URL_CITIZEN_INFO } from 'api/application/constants';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { errorMessage } from 'helpers/utils';
import dayjs from 'dayjs';
import { PATHS } from 'helpers/constants';
import { useEffect } from 'react';
import { useDataForDisabilityApplication } from 'api/application/use-get-data-for-disability-application';

export const DisabilityApplicant = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication']);
  const navigate = useNavigate();
  const form = Form.useFormInstance();
  const phoneNumber = Form.useWatch(['applicationForm', 'legalRepresentative', 'phoneNumber'], { preserve: true });

  const { isInitialLoading, data } = useDataForDisabilityApplication(
    URL_CITIZEN_INFO, //URL_LEGAL_REPRESENTITIVE_INFO
    {},
    {
      enabled: applicantType === 2,
      onError: (err) => {
        errorMessage(err);
        navigate(PATHS.SERVICES);
      },
    }
  );

  useEffect(() => {
    if (data.id && applicantType === 2) {
      const dataToSet = { ...data };
      delete dataToSet?.phoneNumber;
      form.setFieldsValue({
        id: data.id,
        profileId: data.profileId,
        applicationForm:
          applicantType === APPLICATION_FOR_HIMSELF
            ? null
            : {
                legalRepresentative: {
                  ...dataToSet,
                  ...(phoneNumber
                    ? {}
                    : {
                        phoneNumber: data?.phoneNumber,
                      }),
                  validSSN: data.ssn,
                  birthDate: dayjs(data.birthdate),
                  gender: dataToSet.sex,
                  ...(data.documents.length === 1
                    ? {
                        documentType: data.documents[0].documentType,
                        documentNumber: data.documents[0].documentNumber,
                        showDocumentType: data.documents[0].documentType,
                        showDocumentNumber: data.documents[0].documentNumber,
                      }
                    : {}),
                },
              },
      });
    }
  }, [applicantType, data, form, phoneNumber]);

  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace>
        <Title level={1} color="#0B847F">
          Դիմումը լրացնում եմ
        </Title>
        <FormItem name={['applicationForm', 'fillingApplication']} rules={[{ required: true }]}>
          <Radio.Group
            onChange={(option) => {
              if (option.target.value === 1) {
                form.setFieldValue(['applicationForm', 'legalRepresentative'], undefined);
              }
            }}
            options={[
              { value: 1, label: 'Ինձ համար' },
              { value: 2, label: 'Որպես դիմումատուի օրինական ներկայացուցիչ' },
            ]}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          />
        </FormItem>
        {applicantType === 2 && <DisabilityLegalRepresentetive />}
      </VerticalSpace>
    </Spin>
  );
};

import { useState } from 'react';
import dayjs from 'dayjs';
import { Spin, Form } from 'antd';

import { FormItem } from '../../components/form/form-item';
import { Select } from '../../components/select';
import { CalendarYear } from './components/calendar-year';
import { yearList } from './utils';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import { URL_GET_CALENDAR } from '../../api/application/constants';

export const Calendar = () => {
  const [year, setYear] = useState(dayjs().year());
  const [days, setDays] = useState(['']);
  const { isLoading } = useDataForApplication(
    URL_GET_CALENDAR,
    { year },
    {
      onSuccess: (data) => {
        const date = Object.values(data)?.map((d) => d.date);
        setDays(date);
      },
      enabled: true,
    }
  );

  return (
    <Spin spinning={isLoading}>
      <Form>
        <FormItem name="year" initialValue={year}>
          <Select
            style={{ width: 120 }}
            defaultActiveFirstOption
            options={yearList}
            optionFilterProp="label"
            onChange={(value) => {
              setYear(value);
            }}
          />
        </FormItem>
        {days.length && <CalendarYear days={days} year={year} />}
      </Form>
    </Spin>
  );
};


import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Spin } from 'antd';
import styled from 'styled-components';

import { useGetApplicationTypes } from "../../api/benefit/use-get-application-types";
import { URL_GET_SERVICE_BY_ID } from '../../api/application/constants';
import { VerticalSpace } from 'components/space/vertical-space';
import { SectionWrapper } from 'components/collapse/section-wrapper';
import { FamilyService } from './components/family-service';
import { HistoryService } from './components/history-service';
import { PaymentsService } from './components/payments-service';
import { DownOutlined } from '@ant-design/icons';
import { COLORS } from '../../helpers/constants';
import { PrintButton } from 'components/button/print-button';

const CollapseWrapper = styled(SectionWrapper)` 
     .ant-collapse-content-box  {
        background: white;
        padding-top: 41px !important; 
   } 
`;
export const PersonalServicesView = () => {
    const params = useParams();

    const { data, isLoading } = useGetApplicationTypes(URL_GET_SERVICE_BY_ID, { applicationId: params.id });

    return (
        <Spin spinning={isLoading}>
            <Row justify="space-between" gutter={25}>
                <Col>
                    <Typography.Title
                        level={5}
                        style={{
                            borderBottom: ` 2px solid ${COLORS.PRIMARY.BLUE}`,
                            paddingBottom: '8px',
                            width: 'fit-content',
                            color: `${COLORS.PRIMARY.BLUE}`
                        }}>{`Որոշման N ${data?.decisionNumber}`}
                    </Typography.Title>
                </Col>
                <Col>
                    <PrintButton
                        block
                        text="Տպել որոշումը"
                    />
                </Col>
            </Row>
            <VerticalSpace>
                <Row justify="end" gutter={10}>
                    <Col span={12}>
                        <CollapseWrapper title="Ընտանեկան նպաստի տվյալներ"
                            expandIcon={({ isActive }) => <DownOutlined style={{ color: '#FA396B', fontSize: '20px' }} rotate={isActive ? 180 : 0} />}
                        >
                            <VerticalSpace>
                                <FamilyService results={data} />
                            </VerticalSpace>
                        </CollapseWrapper>
                    </Col>
                    <Col span={12}>
                        <CollapseWrapper
                            title="Կարգավորումներ"
                            expandIcon={({ isActive }) => <DownOutlined style={{ color: '#FA396B', fontSize: '20px' }} rotate={isActive ? 180 : 0} />}
                        >
                            <VerticalSpace>
                                Կարգավորումներ
                            </VerticalSpace>
                        </CollapseWrapper>
                    </Col>
                </Row>
                <Row justify="end" gutter={10}>
                    <Col span={12}>
                        <CollapseWrapper title="Վճարման հաշվիչ"
                            expandIcon={({ isActive }) => <DownOutlined style={{ color: '#FA396B', fontSize: '20px' }} rotate={isActive ? 180 : 0} />}

                        >
                            <VerticalSpace>
                                <PaymentsService results={data} />
                            </VerticalSpace>
                        </CollapseWrapper>
                    </Col>
                    <Col span={12}>
                        <CollapseWrapper title="Պարտավորությունների հաշվիչ"
                            expandIcon={({ isActive }) => <DownOutlined style={{ color: '#FA396B', fontSize: '20px' }} rotate={isActive ? 180 : 0} />}
                        >
                            <VerticalSpace>
                                Պարտավորությունների հաշվիչ
                            </VerticalSpace>
                        </CollapseWrapper>
                    </Col>
                </Row>
                <Row justify="end" gutter={10}>
                    <Col span={12}>
                        <CollapseWrapper title="Պատմություն"
                            expandIcon={({ isActive }) => <DownOutlined style={{ color: '#FA396B', fontSize: '20px' }} rotate={isActive ? 180 : 0} />}
                        >
                            <VerticalSpace>
                                <HistoryService results={data} />
                            </VerticalSpace>
                        </CollapseWrapper>
                    </Col>
                    <Col span={12}></Col>
                </Row>
            </VerticalSpace>
        </Spin>
    )
}
import { useApplicationForText } from 'hooks/application/use-application-for-text';
import { FormSectionCard } from '../../../card/form-section-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { UtilityItem } from './item';

export const UtilityAccountsForm = ({ formItemUtility = {} }) => {
  const applicationForText = useApplicationForText('եմ', 'է');
  return (
    <FormSectionCard title={`Օգտվում ${applicationForText} կոմունալ ծառայություններից`}>
      <VerticalSpace>
        {Object.keys(formItemUtility).map((item) => (
          <UtilityItem key={item} item={formItemUtility[item]} dataKey={item} />
        ))}
      </VerticalSpace>
    </FormSectionCard>
  );
};

import { Row, Col, Divider } from 'antd';

export const FamilyService = ({ results }) => {
    return (
        <>
            <Row justify="space-between">
                <Col>Տիպ</Col>
                <Col>{results?.familyType}</Col>
            </Row>
            <Divider />
            <Row justify="space-between">
                <Col>Վճարի մեթոդ</Col>
                <Col>{results?.paymentMethod}</Col>
            </Row>
            <Divider />
            <Row justify="space-between">
                <Col>Վճարի չափ</Col>
                <Col>{results?.money ? `${results?.money} դր` : ''}</Col>
            </Row>
            <Divider />
            <Row justify="space-between">
                <Col>Բանկի անվանում</Col>
                <Col>{results?.bank}</Col>
            </Row>
            <Divider />
            <Row justify="space-between">
                <Col>Ստացողի անուն</Col>
                <Col>{results?.receiverFirstName}</Col>
            </Row>
            <Divider />
            <Row justify="space-between">
                <Col>Ստացողի ազգանուն</Col>
                <Col>{results?.receiverLastName}</Col>
            </Row>
        </>
    )
}

import { useState } from 'react';
import { Badge, Spin } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Calendar } from '../../../components/calendar';
import { useDataForApplication } from '../../../api/application/use-get-data-for-application';
import { URL_GET_CALENDAR } from '../../../api/application/constants';
import { BADGE_STATUS, getListData } from '../utils';


const StyledWrapper = styled.div`
  border-radius: 5px;
  background: #F0F5FF;
  border: 1px dashed #7B61FF;
`;
export const CalendarMonth = ({ year, month }) => {
  const [data, setData] = useState([])

  const { isLoading } = useDataForApplication(
    URL_GET_CALENDAR,
    { year },
    {
      onSuccess: (data) => {
        setData(data);
      },
      enabled: !!year,
    }
  );
  const dateCellRender = (value) => {
    const listData = getListData(value, data);
    return (
      <ul className="events">
        {listData && listData[0]?.agendaInfo?.map((item) => (
          <li key={item.message}>
            <Badge color={BADGE_STATUS[item?.status]} text={item?.message} />
          </li>
        ))}
      </ul>
    );
  };
  return (
    <Spin spinning={isLoading}>
      <StyledWrapper>
        <Calendar
          headerRender={() => {
            return (<></>);
          }}
          dateCellRender={dateCellRender}
          value={dayjs().set('year', year).month(month)}
        />
      </StyledWrapper>
    </Spin>
  )
}
import { armenianLettersPattern } from 'helpers/constants';

export const APPLICATION_FOR_HIMSELF = 1;
export const LEGAL_REPRESENTITIVE = 2;

export const formItemStepTwoParent = 'citizenData';
export const citizenInfo = 'citizenInfo';
export const registrationAddress = 'registrationAddress';
export const actualAddress = 'actualAddress';
const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

export const utilityAccounts = 'utilityAccounts';
export const files = 'files';

export const formItemPropStepTwo = {
  fname: {
    name: [formItemStepTwoParent, citizenInfo, 'fname'],
    rules: armenianPattern,
  },
  lname: {
    name: [formItemStepTwoParent, citizenInfo, 'lname'],
    rules: armenianPattern,
  },
  patronymicName: {
    name: [formItemStepTwoParent, citizenInfo, 'patronymicName'],
    rules: armenianPattern,
  },
  ssn: {
    name: [formItemStepTwoParent, citizenInfo, 'ssn'],
    rules: [{ required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' }],
  },
  birthdate: {
    name: [formItemStepTwoParent, citizenInfo, 'birthdate'],
  },
  isApproved: {
    name: [formItemStepTwoParent, citizenInfo, 'isApproved'],
  },
  contact: {
    phoneNumber: {
      name: [formItemStepTwoParent, citizenInfo, 'phoneNumber'],
      rules: [{ required: true }],
    },
    email: {
      name: [formItemStepTwoParent, citizenInfo, 'email'],
      rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
    },
    gender: {
      name: [formItemStepTwoParent, citizenInfo, 'gender'],
    },
  },
};

export const formItemPropStepTwoRead = {
  fname: {
    name: [formItemStepTwoParent, citizenInfo, 'fname'],
    readOnly: true,
  },
  lname: {
    name: [formItemStepTwoParent, citizenInfo, 'lname'],
    readOnly: true,
  },
  patronymicName: {
    name: [formItemStepTwoParent, citizenInfo, 'patronymicName'],
    readOnly: true,
  },
  ssn: {
    name: [formItemStepTwoParent, citizenInfo, 'ssn'],
    readOnly: true,
  },
  birthdate: {
    name: [formItemStepTwoParent, citizenInfo, 'birthdate'],
    readOnly: true,
  },
  contact: {
    phoneNumber: {
      name: [formItemStepTwoParent, citizenInfo, 'phoneNumber'],
      rules: [{ required: true }],
    },
    email: {
      name: [formItemStepTwoParent, citizenInfo, 'email'],
      rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
    },
    gender: {
      name: [formItemStepTwoParent, citizenInfo, 'gender'],
      readOnly: true,
    },
  },
};

export const formItemPropStepTwoView = {
  ...formItemPropStepTwoRead,
  contact: {
    ...formItemPropStepTwoRead.contact,
    phoneNumber: {
      name: [formItemStepTwoParent, citizenInfo, 'phoneNumber'],
      readOnly: true,
    },
    email: {
      name: [formItemStepTwoParent, citizenInfo, 'email'],
      readOnly: true,
    },
  },
};

export const formItemPropSignIn = {
  fname: {
    name: ['firstName'],
    rules: [{ required: true }, armenianPattern],
    readOnly: false,
  },
  lname: {
    name: ['lastName'],
    rules: [{ required: true }, armenianPattern],
    readOnly: false,
  },
  patronymicName: {
    name: ['patronymicName'],
    rules: [armenianPattern],
    readOnly: false,
  },
  ssn: {
    name: ['ssn'],
    rules: [{ required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' }],
    readOnly: false,
  },
  birthdate: {
    name: ['birthDate'],
    readOnly: false,
  },
  isApproved: {
    name: ['isApproved'],
  },
  contact: {
    phoneNumber: {
      name: ['phoneNumber'],
    },
    email: {
      name: ['email'],
      rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
    },
  },
};

export const formItemDocStepTwo = {
  documentType: {
    name: [formItemStepTwoParent, citizenInfo, 'documentType'],
  },
  documentNumber: {
    name: [formItemStepTwoParent, citizenInfo, 'documentNumber'],
    readOnly: true,
  },
};

export const formItemRegAddressStepTwo = {
  regionId: {
    name: [formItemStepTwoParent, registrationAddress, 'regionId'],
    rules: [{ required: true }],
    readOnly: true,
  },
  region: {
    name: [formItemStepTwoParent, registrationAddress, 'region'],
    readOnly: true,
  },
  communityId: {
    name: [formItemStepTwoParent, registrationAddress, 'communityId'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'regionId'],
    readOnly: true,
  },
  community: {
    name: [formItemStepTwoParent, registrationAddress, 'community'],
    readOnly: true,
  },
  settlementId: {
    name: [formItemStepTwoParent, registrationAddress, 'settlementId'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'communityId'],
    readOnly: true,
  },
  settlement: {
    name: [formItemStepTwoParent, registrationAddress, 'settlement'],
    readOnly: true,
  },
  street: {
    name: [formItemStepTwoParent, registrationAddress, 'street'],
    readOnly: true,
    rules: [{ required: true }],
  },
  building: {
    name: [formItemStepTwoParent, registrationAddress, 'building'],
    rules: [{ required: true }],
    readOnly: true,
  },
  buildingType: {
    name: [formItemStepTwoParent, registrationAddress, 'buildingType'],
    rules: [{ required: true }],
    readOnly: true,
  },
  apartment: {
    name: [formItemStepTwoParent, registrationAddress, 'apartment'],
    readOnly: true,
  },
  isActualAddressMatch: {
    name: [formItemStepTwoParent, 'isActualAddressMatch'],
  },
};

export const formItemRegAddressStepTwoView = {
  regionId: {
    name: [formItemStepTwoParent, registrationAddress, 'region'],
    rules: [{ required: true }],
  },
  region: {
    name: [formItemStepTwoParent, registrationAddress, 'region'],
  },
  communityId: {
    name: [formItemStepTwoParent, registrationAddress, 'community'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'regionId'],
  },
  community: {
    name: [formItemStepTwoParent, registrationAddress, 'community'],
  },
  settlementId: {
    name: [formItemStepTwoParent, registrationAddress, 'settlement'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'communityId'],
  },
  settlement: {
    name: [formItemStepTwoParent, registrationAddress, 'settlement'],
  },
  street: {
    name: [formItemStepTwoParent, registrationAddress, 'street'],
    rules: [{ required: true }],
  },
  building: {
    name: [formItemStepTwoParent, registrationAddress, 'building'],
    rules: [{ required: true }],
  },
  buildingType: {
    name: [formItemStepTwoParent, registrationAddress, 'buildingType'],
    rules: [{ required: true }],
  },
  apartment: {
    name: [formItemStepTwoParent, registrationAddress, 'apartment'],
  },
  postIndex: {
    name: [formItemStepTwoParent, registrationAddress, 'postIndex'],
  },
  isActualAddressMatch: {
    name: [formItemStepTwoParent, 'isActualAddressMatch'],
  },
};

export const formItemAddressType = {
  actualResidenceType: {
    name: [formItemStepTwoParent, 'actualResidenceType'],
    rules: [{ required: true }],
  },
  upload: {
    name: [formItemStepTwoParent, 'attachedDocumentTypeHouse'],
  },
};

/** Փաստացի բնակության հասցե */
export const formItemActualAddressStepTwo = {
  regionId: {
    name: [formItemStepTwoParent, actualAddress, 'regionId'],
    rules: [{ required: true }],
  },
  region: {
    name: [formItemStepTwoParent, actualAddress, 'region'],
  },
  communityId: {
    name: [formItemStepTwoParent, actualAddress, 'communityId'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'regionId'],
  },
  community: {
    name: [formItemStepTwoParent, actualAddress, 'community'],
  },
  settlementId: {
    name: [formItemStepTwoParent, actualAddress, 'settlementId'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'communityId'],
  },
  settlement: {
    name: [formItemStepTwoParent, actualAddress, 'settlement'],
  },
  street: {
    name: [formItemStepTwoParent, actualAddress, 'street'],
    rules: [{ required: true }],
  },
  building: {
    name: [formItemStepTwoParent, actualAddress, 'building'],
    rules: [{ required: true }],
  },
  buildingType: {
    name: [formItemStepTwoParent, actualAddress, 'buildingType'],
  },
  buildingTypeId: {
    name: [formItemStepTwoParent, actualAddress, 'buildingTypeId'],
  },
  apartment: {
    name: [formItemStepTwoParent, actualAddress, 'apartment'],
  },
  postIndex: {
    name: [formItemStepTwoParent, actualAddress, 'postIndex'],
  },
  isActualAddressMatch: {
    name: [formItemStepTwoParent, actualAddress, 'isActualAddressMatch'],
  },
};

export const formItemActualAddressStepTwoView = {
  regionId: {
    name: [formItemStepTwoParent, actualAddress, 'region'],
    rules: [{ required: true }],
  },
  region: {
    name: [formItemStepTwoParent, actualAddress, 'region'],
  },
  communityId: {
    name: [formItemStepTwoParent, actualAddress, 'community'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'regionId'],
  },
  community: {
    name: [formItemStepTwoParent, actualAddress, 'community'],
  },
  settlementId: {
    name: [formItemStepTwoParent, actualAddress, 'settlement'],
    rules: [{ required: true }],
    dependencies: [formItemStepTwoParent, registrationAddress, 'communityId'],
  },
  settlement: {
    name: [formItemStepTwoParent, actualAddress, 'settlement'],
  },
  street: {
    name: [formItemStepTwoParent, actualAddress, 'street'],
    rules: [{ required: true }],
  },
  building: {
    name: [formItemStepTwoParent, actualAddress, 'building'],
    rules: [{ required: true }],
  },
  buildingType: {
    name: [formItemStepTwoParent, actualAddress, 'buildingType'],
  },
  buildingTypeId: {
    name: [formItemStepTwoParent, actualAddress, 'buildingTypeId'],
  },
  apartment: {
    name: [formItemStepTwoParent, actualAddress, 'apartment'],
  },
  postIndex: {
    name: [formItemStepTwoParent, actualAddress, 'postIndex'],
  },
  isActualAddressMatch: {
    name: [formItemStepTwoParent, actualAddress, 'isActualAddressMatch'],
  },
};

export const formItemEducation = {
  isStudying: {
    name: [formItemStepTwoParent, 'isStudying'],
  },
  educationalInstitution: {
    name: [formItemStepTwoParent, 'educationalInstitution'],
  },
  studyFilesChecksum: {
    name: [formItemStepTwoParent, files, 'studyFilesChecksum'],
  },
};

export const formItemUtility = {
  electricityAccount: {
    name: [formItemStepTwoParent, utilityAccounts, 'electricityAccount'],
    rules: [{ required: true }],
    label: 'Հայաստանի Էլեկտրական ցանցեր',
  },
  gasAccount: {
    name: [formItemStepTwoParent, utilityAccounts, 'gasAccount'],
    rules: [{ required: true }],
    label: 'Գազպրոմ Արմենիա',
  },
  waterAccount: {
    name: [formItemStepTwoParent, utilityAccounts, 'waterAccount'],
    rules: [{ required: true }],
    label: 'Վեոլիա ջուր',
  },
};

export const formItemMilitary = {
  isMilitaryService: {
    name: [formItemStepTwoParent, 'isMilitaryService'],
  },
  militaryServiceFilesChecksum: {
    name: [formItemStepTwoParent, files, 'militaryServiceFilesChecksum'],
  },
};

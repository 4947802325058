import { Col, Row } from 'antd';
import { SigninCard } from '../../components/card/signin-card';
import { ChangePasswordForm } from '../../components/form/change-password/form';

export const ChangePassword = () => {
    return (
        <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
            <Col>
                <SigninCard>
                    <ChangePasswordForm />
                </SigninCard>
            </Col>
        </Row>
    );
};

import { Button, Space } from 'antd';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';

const StyledButton = styled(Button)`
  && {
    height: 50px;
    background: ${COLORS.PRIMARY.GRAY};
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
  }
`;

export const ActionButton = ({ children, actionIcon, ...props }) => {
  return (
    <StyledButton size="large" {...props}>
      <Space align="center">
        {children}
        {actionIcon}
      </Space>
    </StyledButton>
  );
};

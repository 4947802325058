import { Form, Radio } from 'antd';
import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { APPLICATIONS_WITH_IS_MILITARY } from '../constants';
import { DisabilityDocumentsAttach } from './disability-documents-attach';

export const MilitaryFileAttach = ({ applicationType }) => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const isMilitary = Form.useWatch(['applicationForm', 'isMilitary'], { preserve: true });
  return (
    <>
      {APPLICATIONS_WITH_IS_MILITARY.some(
        (item) => item.id === applicationType.id && item.evaluationTypeId === applicationType.evaluationTypeId
      ) ? (
        <VerticalSpace>
          <Title level={2} color="#0B847F">
            {`Ուժային կառույցի ներկայացուցիչ ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'Է'}`}
          </Title>
          <FormItem name={['applicationForm', 'isMilitary']} rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </FormItem>
          {isMilitary && <DisabilityDocumentsAttach isRequired={false} />}
        </VerticalSpace>
      ) : (
        <DisabilityDocumentsAttach />
      )}
    </>
  );
};

import { BankData } from 'components/form/application/bank';
import { SubmitButton } from '../../components/button';
import { AcceptDataIsCorrect } from '../../components/form/application/accept-data-is-correct';
import { Notify } from '../../components/form/application/notify';
import { VerticalSpace } from '../../components/space/vertical-space';
import { Info } from '../../components/typography/info';
import { useApplication } from '../../context/applicaton-context';

export const FourthStep = () => {
  const { step, jump } = useApplication();
  return (
    <VerticalSpace size="large">
      <BankData />
      <Notify />
      <AcceptDataIsCorrect />
      <Info text="Կանխիկ եղանակով վճարումներ ստանալու իրավունք ունեն միայն 1-ին եւ 2-րդ խմբի հաշմանդամություն կամ ֆունկցիոնալության խորը կամ ծանր սահմանափակում ունեցող անձինք ու 75 և ավել տարիքային խմբին դասվող անձինք: Մնացած բոլոր դեպքերում նպաստի վճարումը կատարվում է անկանխիկ եղանակով։" />
      <SubmitButton
        canceltext="Նախորդ"
        onCancel={() => {
          jump(step - 1);
        }}
      />
    </VerticalSpace>
  );
};

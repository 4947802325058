import { Collapse } from 'antd';
import { Text } from '../typography';
import { COLORS } from '../../helpers/constants';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background: ${COLORS.PRIMARY.GRAY};
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const SectionWrapper = ({ children, title, ...props }) => {
  return (
    <StyledCollapse expandIconPosition="end" ghost={false} bordered={false} {...props}>
      <Panel
        header={
          <Text strong color={COLORS.PRIMARY.BLUE}>
            {title}
          </Text>
        }
        key="1"
      >
        {children}
      </Panel>
    </StyledCollapse>
  );
};

import { Popover } from 'antd';

import './index.css';
import { COLORS } from '../../helpers/constants';

export const LongTextPopover = (props) => (
  <Popover placement="top" mouseEnterDelay={0.4} overlayClassName="long-text" {...props} />
);

export const ActionsPopover = (props) => (
  <Popover
    placement="top"
    mouseEnterDelay={0.4}
    color={COLORS.ALERT.RED}
    arrow={false}
    trigger="click"
    destroyTooltipOnHide
    overlayClassName="actions-button"
    align={{ offset: [25, 100] }}
    {...props}
  />
);

import { Form } from 'antd';
import { COLORS } from '../../helpers/constants';
import { SecondaryText } from '../typography';

const { Item } = Form;

export const FormItem = ({ label, ...props }) => (
  <Item
    {...props}
    label={
      label &&
      ((typeof label === 'string' && <SecondaryText color={COLORS.PRIMARY.BLUE_DARK}>{label}</SecondaryText>) || label)
    }
  />
);

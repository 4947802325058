import {useNavigate} from 'react-router-dom';
import {Input} from "../../input";
import {EditOutlined} from "@ant-design/icons";
import {Card, Col, Form, Row, Spin} from "antd";
import {Title} from "../../typography";
import {PATHS} from "../../../helpers/constants";
import {useDataForApplication} from "../../../api/application/use-get-data-for-application";
import {URL_ACCOUNT_INFO} from "../../../api/application/constants";
import {VerticalSpace} from "../../space/vertical-space";
import {FormItem} from "../form-item";

export const EmailPassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {isLoading} = useDataForApplication(URL_ACCOUNT_INFO, {},

        {
            onSuccess: ({...data}) => {
                form.setFieldsValue({
                    ...data,
                });
            },
            enabled: true,
        });

    const changePassword = () => {
        navigate(`${PATHS.CHANGE_PASSWORD}`, {state: {passChange: true}})
    };

    const changeEmail = () => {
        navigate(`${PATHS.CHANGE_EMAIL}`)
    };

    return (
        <Form
            autoComplete="off"
            layout="vertical"
            form={form}
            // onFinish={onFinish}
        >
            <Spin spinning={isLoading}>
                <VerticalSpace>
                    <Card style={{minHeight: '70vh'}}>
                        <Row gutter={[16, 8]}>
                            <Col xs={24} lg={12}>
                                <Title level={2}>Փոխել գաղտնաբառը</Title>
                                <FormItem label="Գործող գաղտնաբառ">
                                    <Input size="middle" placeholder="*********"
                                           suffix={<EditOutlined onClick={changePassword}/>}/>
                                </FormItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Title level={2}>Փոխել էլ. հասցեն</Title>
                                <FormItem label="Գործող Էլ. հասցե" name="email">
                                    <Input size="middle" suffix={<EditOutlined onClick={changeEmail}/>}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Card>
                </VerticalSpace>
            </Spin>
        </Form>
    )
};
import { Table } from 'antd';

const authoritiesData = [
    {
        key: '1',
        exclusionReason: 'Շարժական գույքի գոյություն',
        authorityName: 'ՀՀ տարածքային կառավարման և ենթակառուցվածքների նախարարություն',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '2',
        exclusionReason: 'Անշարժ գույքի գոյություն',
        authorityName: 'ՀՀ տարածքային կառավարման և ենթակառուցվածքների նախարարություն',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '3',
        exclusionReason: 'Ջեռուցում, Անվճար էլեկտրաէներգիա',
        authorityName: 'ՀՀ աշխատակազմի ավագանու պաշտօնատար ղեկավար',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '4',
        exclusionReason: 'Քաղաքական գազի սպառում',
        authorityName: '«Գազպրոմ Արմենիա» ՓԲԸ',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '5',
        exclusionReason: 'Էլեկտրաէներգիայի սպառում',
        authorityName: '«Հայաստանի էլեկտրական ցանցեր» ՓԲԸ',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '6',
        exclusionReason: 'Սոցիալական աջակցություն',
        authorityName: 'ՀՀ Պետական եկամուտների կոմիտե',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '7',
        exclusionReason: 'Ֆինանսական գործողություններ',
        authorityName: 'ՀՀ Կենտրոնական բանկ',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
    {
        key: '8',
        exclusionReason: 'Վարկ',
        authorityName: '«ԱՐՄՍ» վարկային բյուրո',
        address: 'ք. Երևան, Խորենացի 3, Հեռ. 010202020',
    },
];

const columns = [
    {
        title: 'Բացառող գործոնի անվանումը',
        dataIndex: 'exclusionReason',
        key: 'exclusionReason',
        align: 'center',
    },
    {
        title: 'Տվյալներ տնօրինող գերատեսչության անվանումը',
        dataIndex: 'authorityName',
        key: 'authorityName',
        align: 'center',
    },
    {
        title: 'Հասցե',
        dataIndex: 'address',
        key: 'address',
        align: 'center',
    },
];

export const AuthoritiesAddress = () => {
    return (
        <Table
            columns={columns}
            dataSource={authoritiesData}
            pagination={false}
            bordered
        />
    );
};

import {Col, Row} from "antd";
import {BenefitTypes} from "../personal-cabinet/benefit-types";
import {Text} from "../../components/typography";
import {VerticalSpace} from "../../components/space/vertical-space";


export const Benefit = () => {

    return (
            <Row gutter={50} style={{padding: '40px 60px'}}>
                <Col>
                    <VerticalSpace>
                        <Text style={{margin: 30}} strong>Ընտրեք անհրաժեշտ դիմումի տեսակը.</Text>
                        <BenefitTypes/>
                    </VerticalSpace>
                </Col>
            </Row>
    )
}
import { useCallback, useMemo } from 'react';
import { URL_GET_CITIZEN_FOR_LEGAL } from '../../api/benefit/constants';
import { SubmitButton } from '../../components/button';
import { FamilyHasLivestock } from '../../components/form/application/family-has-livestock';
import { PersonalInfo } from '../../components/form/application/personal-info';
import { RegistrationAddress } from '../../components/form/application/registration-address';
import { Form } from '../../components/form/form';
import { PersonDataSearchForm } from '../../components/form/person-data-search-form';
import { PersonDataForm } from '../../components/form/person-data-form';
import { VerticalSpace } from '../../components/space/vertical-space';
import { FormRequiredInfo } from '../../components/typography/form-required-info';
import { useApplication } from '../../context/applicaton-context';
import {
  APPLICATION_FOR_HIMSELF,
  formItemActualAddressStepTwo,
  formItemAddressType,
  formItemDocStepTwo,
  formItemEducation,
  formItemMilitary,
  formItemPropStepTwoRead,
  formItemRegAddressStepTwo,
  formItemUtility,
} from './constants';
import { FormSectionCard } from '../../components/card/form-section-card';
import { getDocumentTypeProps, setFormCitizenData } from '../../components/form/application/utils';
import { Spin } from 'antd';
import { selectCitizenInfo, useGetCitizenInfo } from '../../hooks/application/get-citizen-info';
import { EducationForm } from '../../components/form/application/education-form';
import { UtilityAccountsForm } from '../../components/form/application/utility-accounts-form';
import { MilitaryForm } from '../../components/form/application/military-form';
import { useCitizenSearchValidationForm } from 'hooks/application/citizen-search-validation-form';

export const SecondStep = ({ getCitizenData }) => {
  const { step, jump, addCityzenData, submittedData, cityzenData } = useApplication();
  const form = Form.useFormInstance();
  const isInitialLoading = useGetCitizenInfo(getCitizenData);
  const formItemPropStepTwo = useCitizenSearchValidationForm();
  const onSuccess = useCallback(
    (data) => {
      setFormCitizenData(form, data);
      addCityzenData(data);
    },
    [addCityzenData, form]
  );

  const documentTypeProps = useMemo(
    () =>
      cityzenData?.citizenInfo.documents
        ? getDocumentTypeProps(form, cityzenData?.citizenInfo.documents, formItemDocStepTwo.documentNumber.name)
        : {},
    [cityzenData?.citizenInfo?.documents, form]
  );

  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace size="large">
        <FormRequiredInfo />
        {submittedData.fillingApplication === APPLICATION_FOR_HIMSELF ? (
          <PersonDataForm
            title="Դիմումատուի տվյալներ"
            formItemProp={formItemPropStepTwoRead}
            formItemContactProp={formItemPropStepTwoRead.contact}
            viewMode
          />
        ) : (
          <FormSectionCard title="Դիմումատուի տվյալներ">
            <PersonDataSearchForm
              formItemProp={formItemPropStepTwo}
              //* Benefit systemId=2
              url={`${URL_GET_CITIZEN_FOR_LEGAL}?systemId=2`}
              onSuccess={onSuccess}
              onSelect={selectCitizenInfo}
            />
          </FormSectionCard>
        )}
        <PersonalInfo formItemDoc={formItemDocStepTwo} selectProps={documentTypeProps} />
        <RegistrationAddress
          formItemRegAddress={formItemRegAddressStepTwo}
          formItemActualAddressStepTwo={formItemActualAddressStepTwo}
          formItemAddressType={formItemAddressType}
          actualAddressAutoFill={true}
        />
        <UtilityAccountsForm formItemUtility={formItemUtility} />
        <EducationForm formItemEducation={formItemEducation} isPersonal />
        <MilitaryForm formItemMilitary={formItemMilitary} isPersonal />
        <FamilyHasLivestock />
        <SubmitButton
          submitText="Հաջորդ"
          canceltext="Նախորդ"
          onCancel={() => {
            jump(step - 1);
          }}
        />
      </VerticalSpace>
    </Spin>
  );
};

import { forwardRef } from 'react';
import { Select } from '.';
import { URL_GET_PAY_METHOD } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_DEFAULT } from './constants';

export const PaymentMethodsSelect = forwardRef((props, ref) => {
  const { data } = useGetFfromBenefit(URL_GET_PAY_METHOD);
  return (
    <Select
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      placeholder="Ընտրել"
      options={data}
      {...props}
      ref={ref}
    />
  );
});

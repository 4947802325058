import { Row, Col } from "antd";
import styled from 'styled-components';

const Label = styled.span`
    font-size: 12px;  
    font-weight: 700;
    line-height: 28px;
`;
const Text = styled.span`
  font-size: 12px;   
  align:left
`;
export const DecisionInfo = ({ data }) => {

    return (
        <>
            <div>
                <Row justify="space-between" >
                    <Col><Label>Դիմումի ներկայացման ամսաթիվ</Label></Col>
                    <Col><Text>{data?.createdDate}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Կարգադրության ամսաթիվ</Label></Col>
                    <Col><Text>{data?.orderDate}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Սոցիալական գործի համար</Label></Col>
                    <Col><Text>{data?.socialOrderNumber}</Text></Col>
                </Row>
            </div>
            <div style={{ paddingTop: '40px', width: '100%' }}>
                <Row justify="space-between" >
                    <Col><Label>Անուն, ազգանուն, հայրանուն</Label></Col>
                    <Col><Text>{data?.citizenFullName}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>ԱՀՓ համար</Label></Col>
                    <Col><Text>{data?.docNumber}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col span={12}><Label>ՀԾՀ համար/ՀԾՀ չունենալու մասին տեղեկանքի համար</Label></Col>
                    <Col ><Text>{data?.ssn}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Ընտանիքի անդամների թիվը</Label></Col>
                    <Col><Text>{data?.familyMemberCount}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col span={12}><Label>Բնակության հասցե</Label></Col>
                    <Col span={12}><Text>{data?.address}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Վճարման եղանակը</Label></Col>
                    <Col><Text>{data?.payMethod}</Text></Col>
                </Row>
                <Row justify="space-between" >
                    <Col><Label>Բանկը</Label></Col>
                    <Col><Text>{data?.bank}</Text></Col>
                </Row>
            </div>
        </>
    )
}
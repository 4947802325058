
import { useState, useCallback } from 'react';
import { Spin, Table, Space } from 'antd';

import { useColumns } from './use-column';
import { DEFAULT_PAGE } from '../../helpers/constants';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import { URL_GET_SERVICES } from '../../api/application/constants';

export const PersonalServices = () => {
    const [page, setPage] = useState(DEFAULT_PAGE);

    const [result, setResult] = useState({ data: [], totalRecords: 0 });
    const { isLoading } = useDataForApplication(URL_GET_SERVICES, page, {
        onSuccess: (data) =>
            setResult(() => ({
                data: data?.data,
                totalRecords: data?.totalRecords,
            })),
        enabled: true,
    });
    const columns = useColumns();

    const onHandleChange = useCallback((pagination) => {
        setPage((prev) => ({
            ...prev,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
        }));
    }, []);

    return (
        <>
            <Spin spinning={isLoading}>
                <Space size="middle" direction="vertical" style={{ width: '100%' }}>
                    <Table
                        style={{ overflow: 'auto' }}
                        columns={columns}
                        dataSource={result?.data}
                        rowKey="profileId"
                        onChange={onHandleChange}
                        pagination={{
                            total: result.totalRecords,
                            showTotal: (total, range) => `${range[0]}-${range[1]} ${result.totalRecords} ից`,
                        }}
                    />
                </Space>
            </Spin>
        </>
    );
};

import { VerticalSpace } from 'components/space/vertical-space';
import { DisabilityDocumentsAttach } from '../components/disability-documents-attach';
import { Divider } from 'antd';
import { DisabilityTrustProvide } from '../components/disability-trust-provide';
import { DisabilityTrustApprove } from '../components/disability-trust-approve';
import { DisabilityNotifyBy } from '../components/disability-notify-by';
import { SecondaryText, SmallText, Title } from 'components/typography';
import { COLORS } from 'helpers/constants';

export const DisabilityDeathDocs = () => {
  return (
    <VerticalSpace>
      <VerticalSpace size={'large'}>
        <Title level={2} color="#0B847F">
          {'Կցվող փաստաթղթեր'}
        </Title>
        <SecondaryText level={1} color={COLORS.ALERT.RED}>
          Խնդրում ենք կցել բոլոր պարտադիր փաստաթղթերը
        </SecondaryText>
        <SmallText color={COLORS.ALERT.RED}>
          *Ուղեգիր, Մահվան վկայական, Մահվան մասին բժշկական վկայական, Պատճառական կապի մասին եզրակացություն կամ այլ
          հավաստող փաստաթուղթ
        </SmallText>
        <DisabilityDocumentsAttach />
      </VerticalSpace>
      <Divider />
      <DisabilityNotifyBy />
      <Divider />
      <DisabilityTrustProvide />
      <DisabilityTrustApprove />
    </VerticalSpace>
  );
};

import { SaveOutlined, PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { Col, Collapse, Modal, notification, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useSaveApplication } from '../../../../api/application/use-save-application';
import { URL_VALIDATE_SSN } from '../../../../api/benefit/constants';
import { useApplication } from '../../../../context/applicaton-context';
import { DATE_FORMAT_YYYY_MM_DD, armenianLettersPattern } from '../../../../helpers/constants';
import { ManageFamilyMemberButton } from '../../../button';
import { Input } from '../../../input';
import { FamilyRelationshipSelect } from '../../../select';
import { VerticalSpace } from '../../../space/vertical-space';
import { Form } from '../../form';
import { FormItem } from '../../form-item';
import { PersonDataSearchForm } from '../../person-data-search-form';
import { calculateAge, errorMessage } from '../../../../helpers/utils';
import { URL_ADD_FAMILY_MEMBER, URL_REMOVE_FAMILY_MEMBER } from '../../../../api/application/constants';
import { FormSectionCard } from '../../../card/form-section-card';
import { EducationForm } from '../education-form';
import { MilitaryForm } from '../military-form';
import { files } from 'pages/application/constants';
import { UploadButton } from 'components/button/upload-button';

const { Panel } = Collapse;

const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

export const useSetFormItemProp = (key) => {
  const birthdate = Form.useWatch(['familyData', 'familyMembers', key, 'birthdate']);
  const age = calculateAge(birthdate);

  return {
    fname: {
      name: [key, 'fname'],
      rules: armenianPattern,
    },
    lname: {
      name: [key, 'lname'],
      rules: armenianPattern,
    },
    patronymicName: {
      name: [key, 'patronymicName'],
      rules: armenianPattern,
    },
    ssn: {
      name: [key, 'ssn'],
      rules: [{ required: true }, { min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' }],
    },
    birthdate: {
      name: [key, 'birthdate'],
    },
    isApproved: {
      name: [key, 'isApproved'],
    },
    contact: {
      email: {
        name: [key, 'email'],
        rules: [{ required: age > 18 }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
      },
    },
  };
};

export const setFamilyEducation = (key) => ({
  isStudying: {
    name: [key, 'isStudying'],
  },
  educationalInstitution: {
    name: [key, 'educationalInstitution'],
  },
  studyFilesChecksum: {
    name: [key, files, 'studyFilesChecksum'],
  },
});

export const FamilyFormForm = ({ field, add, remove }) => {
  const { submittedData } = useApplication();
  const form = Form.useFormInstance();
  const firstName = form.getFieldValue(['familyData', 'familyMembers', field.name, 'fname']);
  const lastName = form.getFieldValue(['familyData', 'familyMembers', field.name, 'lname']);
  const onSuccess = useCallback(
    (data) => {
      form.setFieldValue(['familyData', 'familyMembers', field.name, 'isApproved'], data);
    },
    [field.name, form]
  );

  const { mutate: removeMutate } = useSaveApplication(URL_REMOVE_FAMILY_MEMBER, {
    onSuccess: () => {
      notification.open({
        message: 'Հաջող հեռացում',
        description: 'Ընտանիքի անդամի տվյալները հեռացվեցին',
      });
    },
    onError: errorMessage,
  });

  const { mutate } = useSaveApplication(URL_ADD_FAMILY_MEMBER, {
    onSuccess: () => {
      notification.open({
        message: 'Հաջող գրանցում',
        description: 'Ընտանիքի անդամի տվյալները պահվեցին',
      });
    },
    onError: (err) => {
      form.resetFields([
        ['familyData', 'familyMembers', field.name, 'isApproved'],
        ['familyData', 'familyMembers', field.name, 'fname'],
        ['familyData', 'familyMembers', field.name, 'lname'],
        ['familyData', 'familyMembers', field.name, 'patronymicName'],
        ['familyData', 'familyMembers', field.name, 'ssn'],
        ['familyData', 'familyMembers', field.name, 'birthdate'],
        ['familyData', 'familyMembers', field.name, 'email'],
        ['familyData', 'familyMembers', field.name, 'relativeType'],
        ['familyData', 'familyMembers', field.name, 'isStudying'],
        ['familyData', 'familyMembers', field.name, 'educationalInstitution'],
        ['familyData', 'familyMembers', field.name, 'isMilitaryService'],
        ['familyData', 'familyMembers', field.name, 'studyFilesChecksum'],
        ['familyData', 'familyMembers', field.name, 'militaryServiceFilesChecksum'],
      ]);
      return errorMessage(err);
    },
  });

  const removeFamilyMember = () => {
    const dataToSave = form.getFieldValue(['familyData', 'familyMembers', field.name]);

    remove(field.name);
    if (dataToSave) {
      removeMutate({
        familyMemberId: field.name + 1,
        profileId: submittedData.profileId,
        applicationId: submittedData.tempApplicationId,
      });
    }
  };

  const saveFamilyMember = () => {
    const dataToSave = form.getFieldValue(['familyData', 'familyMembers', field.name]);
    form
      .validateFields([
        ['familyData', 'familyMembers', field.name, 'isApproved'],
        ['familyData', 'familyMembers', field.name, 'fname'],
        ['familyData', 'familyMembers', field.name, 'lname'],
        ['familyData', 'familyMembers', field.name, 'patronymicName'],
        ['familyData', 'familyMembers', field.name, 'ssn'],
        ['familyData', 'familyMembers', field.name, 'birthdate'],
        ['familyData', 'familyMembers', field.name, 'email'],
        ['familyData', 'familyMembers', field.name, 'relativeType'],
        ['familyData', 'familyMembers', field.name, 'isStudying'],
        ['familyData', 'familyMembers', field.name, 'educationalInstitution'],
        ['familyData', 'familyMembers', field.name, 'isMilitaryService'],
        ['familyData', 'familyMembers', field.name, 'studyFilesChecksum'],
        ['familyData', 'familyMembers', field.name, 'militaryServiceFilesChecksum'],
      ])
      .then(() => {
        if (!dataToSave.isApproved) {
          Modal.error({
            title: 'Ընտանիքի անդամը չպահվեց',
            content: 'Չի հաջողվել նույնականացնել',
            footer: false,
            closable: true,
          });

          return;
        }

        mutate({
          id: field.name + 1,
          citizenInfo: {
            firstName: dataToSave.fname,
            lastName: dataToSave.lname,
            patronymicName: dataToSave.patronymicName,
            ssn: dataToSave.ssn,
            birthdate: dayjs(dataToSave.birthdate).format(DATE_FORMAT_YYYY_MM_DD),
          },
          email: dataToSave.email,
          isStudying: dataToSave.isStudying,
          educationalInstitution: dataToSave?.educationalInstitution,
          isMilitaryService: dataToSave?.isMilitaryService,
          relativeType: dataToSave.relativeType,
          applicationId: submittedData.tempApplicationId,
          studyFilesChecksum: dataToSave?.[files]?.studyFilesChecksum?.map((item) => item.response.fileChecksum),
          militaryServiceFilesChecksum: dataToSave?.[files]?.militaryServiceFilesChecksum?.map(
            (item) => item.response.fileChecksum
          ),
        });
      })
      .catch((errorInfo) => {
        errorInfo.errorFields?.forEach((item) => {
          form.scrollToField(item.name);
          return;
        });
      });
  };

  return (
    <Collapse style={{ display: 'block' }} defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        header={
          <Space>
            <Row justify={'space-between'}>
              <Col sm={24} md={24}>
                {firstName ? `${firstName} ${lastName}` : 'Ընտանիքի անդամի տվյալներ'}
              </Col>
            </Row>
          </Space>
        }
        key={field.name}
      >
        <VerticalSpace>
          <FormSectionCard title="Ընտանիքի անդամի տվյալներ">
            <PersonDataSearchForm
              parent={['familyData', 'familyMembers']}
              formItemProp={useSetFormItemProp(field.name)}
              url={URL_VALIDATE_SSN}
              onSuccess={onSuccess}
            />
          </FormSectionCard>
          <FormItem initialvalue={field.name + 1} name={[field.name, 'id']} hidden>
            <Input />
          </FormItem>
          <FormItem
            label="Ազգակցական կապը դիմումատուի նկատմամբ"
            name={[field.name, 'relativeType']}
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <FamilyRelationshipSelect />
          </FormItem>
          <EducationForm parent={['familyData', 'familyMembers']} formItemEducation={setFamilyEducation(field.name)} />
          <MilitaryForm
            parent={['familyData', 'familyMembers']}
            formItemMilitary={{
              isMilitaryService: {
                name: [field.name, 'isMilitaryService'],
              },
              militaryServiceFilesChecksum: {
                name: [field.name, files, 'militaryServiceFilesChecksum'],
              },
            }}
          />
          <Row justify="center">
            <Col>
              <UploadButton
                size="large"
                actionIcon={<SaveOutlined style={{ color: 'red' }} />}
                onClick={saveFamilyMember}
                type="default"
                text="Պահպանել"
              />
            </Col>
          </Row>
          <Row justify="start">
            <Col sm={24} md={12}>
              <ManageFamilyMemberButton
                doAction={add}
                text={`Ավելացնել ընտանիքի այլ անդամ`}
                icon={<PlusCircleFilled style={{ color: 'green' }} />}
              />
            </Col>
          </Row>
          {field.name !== 0 && (
            <Row justify="start">
              <Col sm={24} md={12}>
                <ManageFamilyMemberButton
                  doAction={removeFamilyMember}
                  text="Հեռացնել"
                  icon={<DeleteOutlined style={{ color: 'red' }} />}
                />
              </Col>
            </Row>
          )}
        </VerticalSpace>
      </Panel>
    </Collapse>
  );
};

import { useQuery } from '@tanstack/react-query';
import { client } from '../client';

export const useGetValidateUser = (url, body, options = { enabled: true }) => {
  const result = useQuery({
    queryKey: [url, body],
    queryFn: () => client.post(url, body),
    retry: false,
    ...options,
  });
  const { data, isSuccess } = result;
  return {
    ...result,
    data: isSuccess ? data.data : [],
  };
};

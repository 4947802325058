import { Col, Layout, Row } from 'antd';
import { VerticalSpace } from '../../components/space/vertical-space';
import { Button } from '../../components/button';
import { useGetApplicationTypes } from '../../api/benefit/use-get-application-types';
import { URL_SYSTEM_TYPES } from '../../api/benefit/constants';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { Text, Title } from '../../components/typography';
import { ServicesHeader } from './services-header';
import { ServicesSider } from '../../components/layouts/service-sider';
import { COLORS, PATHS } from '../../helpers/constants';
import { LottieIcon } from '../../components/lottie';
import { ServicesDepartment } from './services-department';
import { useAuth } from '../../context/auth-context';
import { Link } from 'react-router-dom';
import { Card } from '../../components/card';
import { ManagePage } from '../../components/layouts/manage-page';
import { useState } from 'react';
import { useGetNidUrl } from 'api/auth/use-get-nid-url';
import { URL_LOGIN_NID_USER } from 'api/auth/constants';
import { ReactComponent as ArcaxIcon } from './images/arcax-support.svg';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px; // Adjust height as needed
  width: 100%;
`;

export const MyServices = () => {
  const { user } = useAuth();

  const [isLegal, setIsLegal] = useState(false);
  const { data } = useGetApplicationTypes(URL_SYSTEM_TYPES, { isLegal: isLegal });
  const url = useGetNidUrl(URL_LOGIN_NID_USER);

  return (
    <>
      {!user && (
        <>
          <ManagePage />
          <ServicesHeader />
        </>
      )}

      <Layout>
        {!user && <ServicesSider />}
        <Layout>
          <ServicesDepartment setIsLegal={setIsLegal} />
          <ContentWrapper style={{ minHeight: 707, background: COLORS.SECONDARY.ALICE_BLUE }}>
            <Row gutter={[16, 16]}>
              {data &&
                data?.map(
                  (item, key) =>
                    item.id > 0 && (
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={10}
                        xxl={{
                          span: 7,
                          offset: 1,
                        }}
                        style={{ margin: 0 }}
                        key={key}
                      >
                        <Card
                          bodyStyle={{ minHeight: 455 }}
                          actions={[
                            <Button
                              size="large"
                              key="button"
                              style={{
                                float: 'left',
                                background: user ? COLORS.SECONDARY.AQUA_BLUE : COLORS.SECONDARY.OIL,
                                margin: '0 20px',
                              }}
                              type="primary"
                            >
                              {isLegal ? (
                                item.id === 2 ? (
                                  <span style={{ color: COLORS.PRIMARY.WHITE }}>Տեսնել ավելին</span>
                                ) : (
                                  <Link
                                    style={{ color: COLORS.PRIMARY.WHITE }}
                                    to={!user ? url?.data : item.url}
                                    state={{ from: 'homepage' }}
                                  >
                                    Տեսնել ավելին
                                  </Link>
                                )
                              ) : item.id === 9 ? (
                                <Link
                                  style={{ color: COLORS.PRIMARY.WHITE }}
                                  to={!user ? PATHS.DIFFICULT_SITUATION_APPLICATION_SELECTION_PUBLIC : item.url}
                                  state={{ from: 'homepage' }}
                                >
                                  Տեսնել ավելին
                                </Link>
                              ) : (
                                <Link
                                  style={{ color: COLORS.PRIMARY.WHITE }}
                                  to={key === 2 && !user ? PATHS.SIGN_IN : item.id !== 2 && item.url}
                                  state={{ from: 'homepage' }}
                                >
                                  Տեսնել ավելին
                                </Link>
                              )}
                            </Button>,
                          ]}
                        >
                          <IconWrapper>
                            {item.id === 11 ? (
                              <ArcaxIcon style={{ height: '138px' }} />
                            ) : (
                              <LottieIcon src={item.icon} />
                            )}
                          </IconWrapper>
                          <VerticalSpace>
                            <Title style={{ fontSize: 21 }}>{item.displayName}</Title>
                            <Text style={{ fontSize: 17 }}>{item.description}</Text>
                          </VerticalSpace>
                        </Card>
                      </Col>
                    )
                )}
            </Row>
          </ContentWrapper>
        </Layout>
      </Layout>
    </>
  );
};

import { FormSectionCard } from '../../../card/form-section-card';
import { PersonAddress } from '../../person-address';

export const ActualResidentialAddress = ({ formItemActualAddressStepTwo = {}, viewMode = false }) => {
  return (
    <FormSectionCard title="Փաստացի բնակության հասցե">
      <PersonAddress formItemAddress={formItemActualAddressStepTwo} viewMode={viewMode} isActualAddress />
    </FormSectionCard>
  );
};

// import { armenianLettersPattern } from 'helpers/constants';
import { Input } from '.';

export const ArmenianInput = (props) => {
  const handleKeyPress = (event) => {
    const armenianRegex = /^[\u0561-\u0587\u0531-\u0556\s-]+$/;
    const key = event.key;
    const valueWithKey = event.target.value + key;
    if (!armenianRegex.test(valueWithKey)) {
      event.preventDefault();
    }
  };

  return <Input onKeyPress={handleKeyPress} {...props} />;
};

import { Radio, Space } from 'antd';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';
import { files, formItemStepTwoParent } from '../../../../pages/application/constants';
import { FormSectionFullCard } from '../../../card/form-section-full-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { UploadApplicationDocument } from '../../../upload/upload-application-documents';
import { Form } from '../../form';
import { Text, Title } from 'components/typography';

export const BuildingInfoView = () => {
  const isActualAddressMatch = Form.useWatch(['citizenData', 'actualResidenceType'], { preserve: true });

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <Title level={3} as="p" color={COLORS.PRIMARY.BLUE}>
          {isActualAddressMatch}
        </Title>

        <Radio.Group disabled value={1}>
          <Space>
            <Radio value={1}>
              <Text color={COLORS.PRIMARY.BLUE_DARK}>Այո</Text>
            </Radio>
            <Radio value={0}>
              <Text color={COLORS.PRIMARY.BLUE_DARK}>Ոչ</Text>
            </Radio>
          </Space>
        </Radio.Group>
        <UploadApplicationDocument
          checksumName={[formItemStepTwoParent, files, 'residenceFilesChecksum']}
          attachedDocumentType={DOCUMENT_TYPES.FROM_HOME_PLACE}
          infoText="Խնդրում ենք կցել տեղական ինքնակառավարման մարմնի կողմից տրամադրված տեղեկանք"
          viewMode
        />
      </VerticalSpace>
    </FormSectionFullCard>
  );
};

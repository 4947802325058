import {Player} from "@lottiefiles/react-lottie-player";


export const LottieIcon = ({src}) => {

    return (
        <Player
            loop
            hover
            src={src}
            style={{ height: '180px', width: "100%" }}
        >
        </Player>
    )
}
import { useState } from "react";
import { Spin } from "antd";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { URL_GET_FAMILY_AGREEMENT_PRINT } from "../../api/application/constants";
import { useDataForApplication } from "../../api/application/use-get-data-for-application";
import { CustodianApplicationsPrint } from "./views/custodian-applications";
import { MyApplicationPrint } from "./views/my-application";
import { TrusteeApplicationsPrint } from "./views/trustee-applications";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Info } from "./components/Info";
import { Header as LayoutHeader } from "../../components/layouts/header";


const Wrapper = styled.div`
  width: 21cm;
  background: white;
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5); 
  padding: 17px; 
  font: 12pt "Sylfaen"; 
  border: 6px solid #456FAE;
  border-radius: 10px;
  box-sizing: border-box; 
`;
const InnerWrapper = styled.div`
    background: #FFFFFF;
    border: 2px solid #456FAE;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    padding: 30px; 
    box-sizing: border-box;
    .ant-layout-header {
        padding: 0px;
    }
`;


export const FamilyAgreementPrint = () => {
    const params = useParams();
    const [result, setResult] = useState([]);

    const { isLoading } = useDataForApplication(URL_GET_FAMILY_AGREEMENT_PRINT, { applicationId: params.id }, {
        onSuccess: (data) => {
            setResult(data)
        },
        enabled: !!params.id,
    });
    return (
        <Spin spinning={isLoading}>
            <Wrapper className='print'>
                <InnerWrapper className='page'>
                    <LayoutHeader />
                    {result?.legalRepresentativeType === null && (
                        <>
                            <Header number={` Դիմում  N ${result.number}`} createdDate={result.createdDate} title='ԱՆԱՊԱՀՈՎ ՃԱՆԱՉՎԵԼՈՒ ԵՒ ՍՈՑԻԱԼԱԿԱՆ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ ՍՏԱՆԱԼՈՒ ՄԱՍԻՆ' />
                            <MyApplicationPrint />
                            <Info data={[result?.citizenInfo]} title='Դիմումատու' />
                            {result?.familyMembers && <Info data={result?.familyMembers} title='Ընտանիքի անդամներ' />}
                            <Footer printDate={result.printDate} />
                        </>
                    )}
                    {result?.legalRepresentativeType === 'Խնամակալ' && (
                        <>
                            <Header number={` Դիմում  N ${result.number}`} createdDate={result.createdDate} title='ԸՆՏԱՆԻՔԻ ԱՆԱՊԱՀՈՎՈՒԹՅԱՆ ՆՊԱՍՏ ՍՏԱՆԱԼՈՒ ՄԱՍԻՆ' />
                            <CustodianApplicationsPrint />
                            <Info data={[result?.legalRepresentativeInfo]} title='Խնամակալ' />
                            <Info data={[result?.citizenInfo]} title='Դիմումատու' />
                            {result?.familyMembers && <Info data={result?.familyMembers} title='Ընտանիքի անդամներ' />}
                            <Footer printDate={result.printDate} />
                        </>
                    )}
                    {result?.legalRepresentativeType === 'Հոգաբարձու' && (
                        <>
                            <Header number={` Դիմում  N ${result.number}`} createdDate={result.createdDate} title='ԸՆՏԱՆԻՔԻ ԱՆԱՊԱՀՈՎՈՒԹՅԱՆ ՆՊԱՍՏ ՍՏԱՆԱԼՈՒ ՄԱՍԻՆ' />
                            <TrusteeApplicationsPrint />
                            <Info data={[result?.legalRepresentativeInfo]} title='Խնամակալ' />
                            <Info data={[result?.citizenInfo]} title='Դիմումատու' />
                            {result?.familyMembers && <Info data={result?.familyMembers} title='Ընտանիքի անդամներ' />}
                            <Footer printDate={result.printDate} />
                        </>
                    )}

                </InnerWrapper>

            </Wrapper>
        </Spin>
    )
}


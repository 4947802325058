import { Col, Form, Radio, Row, Space, Spin } from 'antd';
import { disabiltyTypes, familyData, personDetails } from '../constants';

import { Input, TextArea } from 'components/input';
import { createOptionsFromArray } from 'helpers/utils';
import { useWatch } from 'rc-field-form';
import { InputNumber } from 'components/input-number';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_USER_DATA_FROM_BPR } from 'api/urrp/constants';
import { GENDER_LIST_USER } from 'helpers/constants';
import { ArmenianInput } from 'components/input/armenian-input';
import { Select } from 'components/select';

export const PersonDetails = ({ educationTypes, vulnerability }) => {
  const checklist = useWatch([familyData, personDetails], {
    preserve: true,
  });
  const {
    data: { sex },
    isLoading,
  } = useGetFfromBenefit(URL_GET_USER_DATA_FROM_BPR);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} xl={12}>
          <Form.Item name={[familyData, personDetails, 'education']} label="Կրթություն" rules={[{ required: true }]}>
            <Select
              options={educationTypes?.map((i) => {
                return {
                  label: i.name,
                  value: i.id,
                };
              })}
              labelInValue
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Form.Item
            name={[familyData, personDetails, 'workHistory']}
            label="Աշխատանքային ստաժ՝ վերջին 3 տարվա ընթացքում"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Form.Item
            name={[familyData, personDetails, 'physicalIssue']}
            label="Առողջական խնդիրներ"
            rules={[{ required: true }]}
          >
            <ArmenianInput />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Form.Item
            name={[familyData, personDetails, 'mentalIssue']}
            label="Հոգեբանական խնդիրներ"
            rules={[{ required: true }]}
          >
            <ArmenianInput />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Form.Item
            name={[familyData, personDetails, 'vulnerability']}
            label="Արդյո՞ք դուք կամ Ձեր ընտանիքի անդամներից որևէ մեկն ունեք նշված սոցիալական կարգավիճակներից"
            rules={[{ required: true }]}
          >
            <Select options={createOptionsFromArray(vulnerability || [])} labelInValue />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={[familyData, personDetails, 'disabilty']}>
        <Form.Item
          name={[familyData, personDetails, 'disabilty', 'hasDisabilty']}
          rules={[{ required: true }]}
          label="Ունե՞ք հաշմանդամություն կամ ֆունկցիոնալության սահմանափակումներ"
        >
          <Radio.Group>
            <Space>
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        {checklist?.disabilty?.hasDisabilty && (
          <Form.Item name={[familyData, personDetails, 'disabilty', 'disabiltyType']} rules={[{ required: true }]}>
            <Select options={createOptionsFromArray(disabiltyTypes || [])} labelInValue />
          </Form.Item>
        )}
        <Form.Item hidden />
        {checklist?.disabilty?.disabiltyType?.value === 5 && (
          <Form.Item name={[familyData, personDetails, 'disabilty', 'comment']} rules={[{ required: true }]}>
            <TextArea />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        name={[familyData, personDetails, 'needsAccommodationAdjustment']}
        label="Ունե՞ք անվասայլակի, պրոթեզի օգտագործման, տեսողական կամ այլ խնդիրներով պայմանավորված՝ կացարանի հարմարեցման կարիք"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[familyData, personDetails, 'needsPermanentCare']}
        label="Առողջական խնդիրներով պայմանավորված ունե՞ք մշտական խնամքի կարիք"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      {sex === GENDER_LIST_USER.F && (
        <Form.Item name={[familyData, personDetails, 'pregnancy']}>
          <Form.Item
            name={[familyData, personDetails, 'pregnancy', 'isPregnant']}
            rules={[{ required: true }]}
            label="Հղիության առկայություն"
          >
            <Radio.Group>
              <Space>
                <Radio value={true}>Այո</Radio>
                <Radio value={false}>Ոչ</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          {checklist?.pregnancy?.isPregnant && (
            <Form.Item
              name={[familyData, personDetails, 'pregnancy', 'pregnancyPeriod']}
              rules={[{ required: true }]}
              label="Հղիության ժամկետր՝ ըստ ամիսների"
            >
              <InputNumber style={{ width: '100%' }} min={1} max={40} />
            </Form.Item>
          )}
          <Form.Item hidden />
        </Form.Item>
      )}
    </Spin>
  );
};

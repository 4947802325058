import { Space, Modal, Typography, Row, Col } from 'antd';

export const NotificationModal = ({ item, handleModalCancel }) => {
  return (
    <Modal
      title={
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Ծանուցում
        </Typography.Title>
      }
      name="notificationModal"
      open={true}
      onCancel={handleModalCancel}
      footer={false}
      bodyStyle={{ height: 'auto' }}
    >
      <Space direction="vertical" size="middle" key={item?.id}>
        <Row justify="center" gutter={16}>
          <Col span={24}>

            <div dangerouslySetInnerHTML={{ __html: item?.notificationText }}></div>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

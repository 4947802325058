import { Row, Col, Typography } from 'antd';


export const Header = ({ number, createdDate, title }) => {

    return (
        <div >
            <Row justify="center" gutter={16}>
                <Col span={24}>
                    <Typography.Title level={5} style={{ textAlign: 'center' }}>
                        {number}
                    </Typography.Title>
                </Col>
                <Col span={24}>
                    <Typography.Title level={5} style={{ textAlign: 'center' }}>{title}</Typography.Title>
                </Col>
            </Row>
            {createdDate && <Row justify="space-between" >
                <Col>
                    <Typography.Text strong>Դիմումի ներկայացման ամսաթիվ</Typography.Text>
                </Col>
                <Col>
                    <Typography.Text strong>{createdDate}</Typography.Text>
                </Col>
            </Row>}
        </div>
    )
}


import { LOTTIE_ICON, PATHS } from 'helpers/constants';

export const USER_HOME_PAGE_ITEMS = [
  {
    title: 'Ծառայություններ',
    text: 'Այս բաժնում կարող եք տեսնել առաջարկվող և Ձեզ տրամադրվող սոցիալական ծառայությունները',
    icon: LOTTIE_ICON.SERVICE_ICON,
    url: PATHS.SERVICES,
  },
  {
    title: 'Իմ Դիմումները',
    text: 'Այս բաժնում կարող եք տեսնել սոցիալական ծառայությունների տրամադրման համար ներկայացված Ձեր բոլոր դիմումները',
    icon: LOTTIE_ICON.DOCUMENT_ICON,
    url: PATHS.APPLICATIONS,
  },
  {
    title: 'Քարտեզ',
    text: 'Այս բաժնում կարող եք տեսնել սոցիալական ծառայությունների տրամադրման համար ներկայացված Ձեր բոլոր դիմումները',
    icon: LOTTIE_ICON.MAP_ICON,
    url: PATHS.MAPS,
  },
  {
    title: 'Ծանուցումներ',
    text: 'Այս բաժնում կարող եք տեսնել Ձեր ծանուցումները',
    icon: LOTTIE_ICON.NOTIFICATION_ICON,
    url: PATHS.NOTIFICATIONS,
  },
  {
    title: 'Կարգավորումներ',
    text: 'Այս բաժնում կարող եք կատարել Ձեր անհատական կաբինետի կարգավորումները',
    icon: LOTTIE_ICON.OPTION_ICON,
    url: PATHS.SETTINGS,
  },
  {
    title: 'Օրացույց',
    text: 'Այս բաժնում կարող եք տեսնել Ձեր սոցիալական ծառայություն(ներ)ի հետ կապված գործողությունները',
    icon: LOTTIE_ICON.CALENDAR_ICON,
    url: PATHS.CALENDAR,
  },
  {
    title: 'Ինքնագնահատում',
    text: 'Պատասխանելով այս բաժնում տրվող հարցերին` կարող եք պարզել Ձեր համար հասանելի սոցիալական ծառայություն(ներ)ը',
    icon: LOTTIE_ICON.SELF_ASSESMENT_ICON,
    url: PATHS.SELF_ASSESSMENT,
  },
];

export const COMPANY_HOME_PAGE_ITEMS = [
  {
    title: 'Ծառայություններ',
    text: 'Այս բաժնում կարող եք տեսնել Ձեր սոցիալական ծառայություն(ներ)ի հետ կապված գործողությունները',
    icon: LOTTIE_ICON.SERVICE_ICON,
    url: PATHS.SERVICES,
  },
  {
    title: 'Իմ դիմումները',
    text: 'Այս բաժնում կարող եք տեսնել առաջարկվող և Ձեզ տրամադրվող սոցիալական ծառայությունները',
    icon: LOTTIE_ICON.DOCUMENT_ICON,
    // url: PATHS.SERVICES,
  },
  {
    title: 'Քարտեզ',
    text: 'Այս բաժնում կարող եք տեսնել սոցիալական ծառայությունների տրամադրման համար ներկայացված Ձեր բոլոր դիմումները',
    icon: LOTTIE_ICON.MAP_ICON,
    // url: PATHS.APPLICATIONS,
  },
  {
    title: 'Ծանուցումներ',
    text: 'Այս բաժնում կարող եք տեսնել Ձեր ծանուցումները',
    icon: LOTTIE_ICON.NOTIFICATION_ICON,
    url: PATHS.NOTIFICATIONS,
  },
  {
    title: 'Կարգավորումներ',
    text: 'Այս բաժնում կարող եք կատարել Ձեր անհատական կաբինետի կարգավորումները',
    icon: LOTTIE_ICON.OPTION_ICON,
    url: PATHS.SETTINGS,
  },
  {
    title: 'Լիազորություն',
    text: 'Պատասխանելով այս բաժնում տրվող հարցերին` կարող եք պարզել Ձեր համար հասանելի սոցիալական ծառայություն(ներ)ը',
    icon: LOTTIE_ICON.SELF_ASSESMENT_ICON,
    // url: PATHS.SELF_ASSESSMENT,
  },
];

import { Skeleton } from 'antd';
import { forwardRef } from 'react';
import { Select } from '.';
import { URL_COMMUNITY_BY_REGION_ID } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_DEFAULT } from './constants';

export const CommunitySelect = forwardRef(({ regionId, ...props }, ref) => {
  const { data, isInitialLoading } = useGetFfromBenefit(
    URL_COMMUNITY_BY_REGION_ID,
    { regionId: regionId },
    { enabled: !!regionId }
  );
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />;
  }

  const filterOption = (input, option) =>
    option?.[FIELD_NAMES_DEFAULT.label]?.toLowerCase()?.includes(input.toLowerCase());

  return (
    <Select
      showSearch
      filterOption={filterOption}
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      options={data}
      {...props}
      ref={ref}
    />
  );
});

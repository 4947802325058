import { Form } from 'components/form/form';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text } from 'components/typography';
import { APPLICATION_FOR_HIMSELF } from './constants';

export const ConfirmStep = () => {
  const applicationFor = Form.useWatch('fillingApplication');
  const legalRepresentativeType = Form.useWatch(['profileData', 'legalRepresentativeType']);
  return (
    <VerticalSpace>
      {applicationFor === APPLICATION_FOR_HIMSELF && (
        <>
          <Text strong>
            Խնդրում եմ ինձ` (ընտանիքիս) հաշվառել ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների
            տեղեկատվական համակարգում՝ անապահով ճանաչելու և սոցիալական աջակցություն տրամադրելու նպատակով: Կից
            ներկայացնում եմ իմ և ընտանիքիս չափահաս անդամների համաձայնության հավաստիացումները։
          </Text>
          <Text strong>
            Մենք՝ ներքոստորագրյալներս, համաձայն ենք սույն դիմումի բովանդակությանը, ինչպես նաև ընդունում ենք դիմումում
            ստորև ներկայացված պարտավորությունները։
          </Text>
          <ul>
            <li>
              համաձայն ենք, որ ՀՀ կառավարության կողմից սահմանված կարգով ստուգվեն մեր ընտանեկան, այդ թվում՝ ամուսնական
              կապերը, ընտանիքիս անդամների առողջական վիճակի, եկամուտների (աշխատավարձերի, դրանց հավասարեցված այլ
              վճարումների, բանկային հաշիվների և ավանդների), վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը,
              սպառած խմելու ջրի քանակը` Վեոլիա Ջուր փակ բաժնետիրական ընկերությունում առկա տվյալներով,սպառած
              էլեկտրաէներգիայի քանակը` Հայկական էլեկտրական ցանցեր փակ բաժնետիրական ընկերությունում առկա տվյալներով,
              սպառած բնական գազի քանակը՝ Գազպրոմ Արմենիա փակ բաժնետիրական ընկերությունում առկա տվյալներով, ինչպես նաև ՀՀ
              կառավարության կողմից սահմանված այլ տվյալներ,
            </li>
            <li>
              երաշխավորում ենք, որ ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում
              հաշվառվելու և հաշվառված լինելու ընթացքում ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (այսուհետ՝
              նախարարություն) միասնական սոցիալական ծառայության տարածքային կենտրոնի (այսուհետ՝ տարածքային կենտրոնի)
              աշխատողը և օրենսդրությամբ նման իրավասություն ունեցող այլ անձինք իմ ընտանիքի կազմի և պայմանների
              ուսումնասիրման նպատակով կարող են առանց խոչընդոտների կատարել տնայց,
            </li>
            <li>
              տեղեկացված ենք, որ առաջին տնայցը կատարվելու է դիմումի հաստատումից 15 աշխատանքային օրվա ընթացքում, և
              պարտավորվում ենք համագործակցել տնայց իրականացնողի հետ անհրաժեշտ տվյալների լիարժեք հավաքագրման նպատակով,
            </li>
            <li>
              համաձայն ենք, որ տնայցի ընթացքում տարածքային կենտրոնի աշխատողը լուսանկարի իմ կամ ընտանիքիս կեցության
              պայմանների առանձին հատվածներ (առանց լուսանկարելու անձանց դեմքերը), և տեղեկացված ենք, որ արված
              լուսանկարները կիրառվելու են միայն ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական
              համակարգում, ինչպես նաև պահվելու և մշակվելու են օրենսդրությամբ սահմանված կարգով,
            </li>
            <li>
              տեղեկացված ենք, որ անապահովության նպաստը (այսուհետ՝ նպաստ) և սոցիալական աջակցության այլ տեսակները
              նշանակվում են մինչև դադարեցման հիմքերն առաջանալը, սակայն ոչ ավելի, քան 12 ամիս ժամկետով,
            </li>
            <li>
              տեղեկացված ենք, որ նպաստ կամ սոցիալական աջակցության այլ տեսակներ նշանակվում են ընտանիքում չափահաս
              աշխատունակ, բայց չզբաղված անդամ(ներ)ի՝ զբաղվածության ակտիվացման ծրագրերում հաշվառվելու և ներառվելու
              նախապայմանով (ՀՀ կառավարության 2014 թվականի հունվարի 30-ի N 145 – Ն որոշմամբ սահմանված են
              բացառությունները),
            </li>
            <li>
              տեղեկացված ենք, որ ընտանիքի մասին ոչ հավաստի տվյալներ ներկայացնելու կամ դրանք թաքցնելու պատճառով ավելի
              վճարված գումարն օրենսդրությամբ սահմանված կարգով հետ է գանձվելու,
            </li>
            <li>
              պարտավորվում ենք ընտանիքի կազմի, բնակության հասցեի, եկամուտների և այլ փոփոխությունների դեպքում 15 օրվա
              ընթացքում կատարել համապատասխան փոփոխություններ այս դիմումում` անհրաժեշտության դեպքում ներկայացնելով նոր
              դիմում և համապատասխան փաստաթղթեր,
            </li>
            <li>
              համաձայն ենք, որ այս դիմումում նշված անձանց նույնականացման տվյալները փոխանցվեն սոցիալական ծառայություններ
              տրամադրող այն կազմակերպություներին, որոնք պետք է աշխատեն ինձ և/կամ ընտանիքիս անդամների հետ, ինչպես նաև
              օրենսդրությամբ սահմանված սոցիալական աջակցության ծրագրեր (դրամական և ոչ դրամական աջակցություն,
              արտոնություններ, զեղչեր և այլն) իրականացնող պետական մարմիններին, տեղական ինքնակառավարման մարմիններին,
              նախարարության հետ կնքված համագործակցության հուշագրերի (պայմանագրերի) հիման վրա՝ այլ կազմակերպությունների,
            </li>
            <li>
              համաձայն ենք, որ ընտանիքի սոցիալական գնահատման նպատակով այս դիմումում նշված նույնականացման տվյալների հիման
              վրա իմ եւ ընտանիքիս վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը և հաջորդ մեկ տարվա ընթացքում
              դրան վերաբերող փոփոխությունները նախարարության և «ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ-ի միջև կնքված պայմանագրով
              սահմանված կարգով փոխանցվեն նախարարությանը։
            </li>
            <li>
              համաձայն ենք, որ այս դիմումում նշված չափահաս անձանց նույնականացման տվյալները տրամադրվեն դրույքախաղեր
              առաջարկող կազմակերպություններին՝ չափահաս անձանց մուտքը խաղադրույքով ծառայություններին արգելափակելու
              նպատակով.
            </li>
          </ul>
        </>
      )}
      {legalRepresentativeType === 1 && (
        <>
          <Text strong>
            Խնդրում եմ խնամարկյալիս՝ հաշվառել ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական
            համակարգում՝ անապահով ճանաչելու և սոցիալական աջակցություն տրամադրելու նպատակով:
          </Text>
          <ul>
            <li>
              համաձայն եմ, որ ՀՀ կառավարության կողմից սահմանված կարգով ստուգվեն, իմ խնամարկյալի ամուսնական
              կապերը,ընտանիքիս անդամների առողջական վիճակի, եկամուտների (աշխատավարձերի, դրանց հավասարեցված այլ
              վճարումների, բանկային հաշիվների և ավանդների), վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը,
              սպառած խմելու ջրի քանակը` Վեոլիա Ջուր փակ բաժնետիրական ընկերությունում առկա տվյալներով,սպառած
              էլեկտրաէներգիայի քանակը` Հայկական էլեկտրական ցանցեր փակ բաժնետիրական ընկերությունում առկա տվյալներով,
              սպառած բնական գազի քանակը՝ Գազպրոմ Արմենիա փակ բաժնետիրական ընկերությունում առկա տվյալներով, ինչպես նաև ՀՀ
              կառավարության կողմից սահմանված այլ տվյալներ,
            </li>
            <li>
              երաշխավորում ենք, որ ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում
              հաշվառվելու և հաշվառված լինելու ընթացքում ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (այսուհետ՝
              նախարարություն) միասնական սոցիալական ծառայության տարածքային կենտրոնի (այսուհետ՝ տարածքային կենտրոնի)
              աշխատողը և օրենսդրությամբ նման իրավասություն ունեցող այլ անձինք իմ ընտանիքի կազմի և պայմանների
              ուսումնասիրման նպատակով կարող են առանց խոչընդոտների կատարել տնայց,
            </li>
            <li>
              տեղյակ եմ, որ առաջին տնայցը կատարվելու է դիմումի հաստատումից 15 աշխատանքային օրվա ընթացքում, և
              պարտավորվում եմ համագործակցել տնայց իրականացնողի հետ՝ անհրաժեշտ տվյալների լիարժեք հավաքագրման նպատակով,
            </li>
            <li>
              համաձայն եմ, որ տնայցի ընթացքում տարածքային կենտրոնի աշխատողը լուսանկարի իմ խնամարկյալի կեցության
              պայմանների առանձին հատվածներ (առանց լուսանկարելու անձանց դեմքերը), և տեղյակ եմ, որ արված լուսանկարները
              կիրառվելու են միայն ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում,
              ինչպես նաև պահվելու և մշակվելու են օրենսդրությամբ սահմանված կարգով,
            </li>
            <li>
              տեղյակ եմ, որ անապահովության նպաստը (այսուհետ՝ նպաստ) և սոցիալական աջակցության այլ տեսակները նշանակվում է
              մինչև դադարեցման հիմքերն առաջանալը, սակայն ոչ ավելի, քան 12 ամիս ժամկետով,
            </li>
            <li>
              տեղյակ եմ, որ իմ խնամարկյալի մասին ոչ հավաստի տվյալներ ներկայացնելու կամ դրանք թաքցնելու պատճառով ավելի
              վճարված գումարն օրենսդրությամբ սահմանված կարգով հետ է գանձվելու,
            </li>
            <li>
              պարտավորվում եմ իմ խնամարկյալի ընտանիքի կազմի, բնակության հասցեի, եկամուտների և այլ փոփոխությունների
              դեպքում 15 օրվա ընթացքում կատարել համապատասխան փոփոխություններ այս դիմումում` անհրաժեշտության դեպքում
              ներկայացնելով նոր դիմում և համապատասխան փաստաթղթեր,
            </li>
            <li>
              համաձայն եմ, որ իմ խնամարկյալի՝ այս դիմումում ներկայացված նույնականացման տվյալները փոխանցվեն սոցիալական
              ծառայություններ տրամադրող այն կազմակերպություներին, որոնք պետք է աշխատեն վերջինիս հետ, ինչպես նաև
              օրենսդրությամբ սահմանված սոցիալական աջակցության ծրագրեր (դրամական և ոչ դրամական աջակցություն,
              արտոնություններ, զեղչեր և այլն) իրականացնող պետական մարմիններին, տեղական ինքնակառավարման մարմիններին,
              նախարարության հետ կնքված համագործակցության հուշագրերի (պայմանագրերի) հիման վրա՝ այլ կազմակերպությունների:
            </li>
            <li>
              համաձայն եմ, որ ընտանիքի սոցիալական գնահատման նպատակով այս դիմումում նշված նույնականացման տվյալների հիման
              վրա իմ խնամարկյալի վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը և հաջորդ մեկ տարվա ընթացքում
              դրան վերաբերող փոփոխությունները նախարարության և «ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ-ի միջև կնքված պայմանագրով
              սահմանված կարգով փոխանցվեն նախարարությանը։
            </li>
            <li>
              համաձայն եմ, որ իմ խնամարկյալի՝ այս դիմումում նշված նույնականացման տվյալները տրամադրվեն դրույքախաղեր
              առաջարկող կազմակերպություններին՝ չափահաս անձանց մուտքը խաղադրույքով ծառայություններին արգելափակելու
              նպատակով.
            </li>
          </ul>
        </>
      )}
      {legalRepresentativeType === 2 && (
        <>
          <Text strong>
            Խնդրում եմ խնամարկյալ(ներ)իս հաշվառել ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների
            տեղեկատվական համակարգում՝ անապահով ճանաչելու և սոցիալական աջակցություն տրամադրելու նպատակով:
          </Text>
          <ul>
            <li>
              համաձայն եմ, որ ՀՀ կառավարության կողմից սահմանված կարգով, առկայության դեպքում, ստուգվեն իմ
              խնամարկյալ(ներ)ի ամուսնական կապերը,ընտանիքիս անդամների առողջական վիճակի, եկամուտների (աշխատավարձերի, դրանց
              հավասարեցված այլ վճարումների, բանկային հաշիվների և ավանդների), վարկային պարտավորությունների վերաբերյալ
              տեղեկատվությունը, սպառած խմելու ջրի քանակը` Վեոլիա Ջուր փակ բաժնետիրական ընկերությունում առկա
              տվյալներով,սպառած էլեկտրաէներգիայի քանակը` Հայկական էլեկտրական ցանցեր փակ բաժնետիրական ընկերությունում
              առկա տվյալներով, սպառած բնական գազի քանակը՝ Գազպրոմ Արմենիա փակ բաժնետիրական ընկերությունում առկա
              տվյալներով, ինչպես նաև ՀՀ կառավարության կողմից սահմանված այլ տվյալներ,
            </li>
            <li>
              երաշխավորում ենք, որ ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում
              հաշվառվելու և հաշվառված լինելու ընթացքում ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (այսուհետ՝
              նախարարություն) միասնական սոցիալական ծառայության տարածքային կենտրոնի (այսուհետ՝ տարածքային կենտրոնի)
              աշխատողը և օրենսդրությամբ նման իրավասություն ունեցող այլ անձինք իմ ընտանիքի կազմի և պայմանների
              ուսումնասիրման նպատակով կարող են առանց խոչընդոտների կատարել տնայց,
            </li>
            <li>
              տեղյակ եմ, որ առաջին տնայցը կատարվելու է դիմումի հաստատումից 15 աշխատանքային օրվա ընթացքում, և և
              պարտավորվում եմ համագործակցել տնայց իրականացնողի հետ՝ անհրաժեշտ տվյալների լիարժեք հավաքագրման նպատակով,
            </li>
            <li>
              համաձայն եմ, որ տնայցի ընթացքում տարածքային կենտրոնի աշխատողը լուսանկարի իմ խնամարկյալ(ներ)ի կեցության
              պայմանների առանձին հատվածներ (առանց լուսանկարելու անձանց դեմքերը), և տեղյակ եմ, որ արված լուսանկարները
              կիրառվելու են միայն ընտանիքի սոցիալական գնահատման և սոցիալական ծառայությունների տեղեկատվական համակարգում,
              ինչպես նաև պահվելու և մշակվելու են օրենսդրությամբ սահմանված կարգով,
            </li>
            <li>
              տեղյակ եմ, որ անապահովության նպաստը (այսուհետ՝ նպաստ)և սոցիալական աջակցության այլ տեսակները նշանակվում է
              մինչև դադարեցման հիմքերն առաջանալը, սակայն ոչ ավելի, քան 12 ամիս ժամկետով,
            </li>
            <li>
              տեղյակ եմ, որ իմ խնամարկյալ(ներ)ի մասին ոչ հավաստի տվյալներ ներկայացնելու կամ դրանք թաքցնելու պատճառով
              ավելի վճարված գումարն օրենսդրությամբ սահմանված կարգով հետ է գանձվելու,
            </li>
            <li>
              պարտավորվում եմ իմ խնամարկյալ(ներ)ի ընտանիքի կազմի, բնակության հասցեի, եկամուտների, ունեցվածքի և այլ
              փոփոխությունների դեպքում 15 օրվա ընթացքում կատարել փոփոխություններ այս դիմումում` անհրաժեշտության դեպքում
              ներկայացնելով նոր դիմում և համապատասխան փաստաթղթեր,
            </li>
            <li>
              համաձայն եմ, որ իմ խնամարկյալ(ներ)ի նույնականացման տվյալները փոխանցվեն սոցիալական ծառայություններ
              տրամադրող այն կազմակերպություներին, որոնք աշխատեն վերջինիս հետ, ինչպես նաև օրենսդրությամբ սահմանված
              սոցիալական աջակցության ծրագրեր (դրամական և ոչ դրամական աջակցություն, արտոնություններ, զեղչեր և այլն)
              իրականացնող պետական մարմիններին, տեղական ինքնակառավարման մարմիններին, նախարարության հետ կնքված
              համագործակցության հուշագրերի (պայմանագրերի) հիման վրա՝ այլ կազմակերպությունների:
            </li>
            <li>
              համաձայն եմ, որ ընտանիքի սոցիալական գնահատման նպատակով այս դիմումում նշված նույնականացման տվյալների հիման
              վրա իմ խնամարկյալ(ներ)ի վարկային պարտավորությունների վերաբերյալ տեղեկատվությունը և հաջորդ մեկ տարվա
              ընթացքում դրան վերաբերող փոփոխությունները նախարարության և «ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ-ի միջև կնքված
              պայմանագրով սահմանված կարգով փոխանցվեն նախարարությանը։
            </li>
            <li>
              համաձայն եմ, որ իմ խնամարկյալ(ներ)ի՝ այս դիմումում նշված նույնականացման տվյալները տրամադրվեն դրույքախաղեր
              առաջարկող կազմակերպություններին՝ չափահաս անձանց մուտքը խաղադրույքով ծառայություններին արգելափակելու
              նպատակով:
            </li>
          </ul>
        </>
      )}
    </VerticalSpace>
  );
};

export const URL_BENEFIT_APPLICATION_TYPES = 'Benefit/GetBenefitApplicationTypes';
export const URL_SYSTEM_TYPES = 'Benefit/GetSystemTypes';
export const URL_RELATIVE_TYPES = 'benefit/getRelativeTypes';
export const URL_ANIMALS = 'benefit/getAnimals';
export const URL_RESIDENCE_TYPES = 'benefit/getResidenceTypes';
export const URL_RESIDENCE_REASONS = 'benefit/GetResidenceReasons';
export const URL_NOTIFICATION_TYPES = 'benefit/getNotificationTypes';
export const URL_LEGAL_REPRESENTIVE_TYPES = 'benefit/getLegalRepresentativeTypes';
export const URL_ALL_REGIONS = 'benefit/getAllRegions';
export const URL_COMMUNITY_BY_REGION_ID = 'benefit/getCommunitiesByRegionId';
export const URL_POSTAL_OFFICES_BY_REGION_ID = 'benefit/getPostOfficesByRegionId';
export const URL_SETTLEMENT_BY_COMMUNITY_ID = 'benefit/getSettlementByCommunityId';
export const URL_POST_OFFICE_BY_REGION_ID = 'benefit/getPostOfficesByRegionId';
export const URL_VALIDATE_SSN = 'benefit/validateSSN';
export const URL_GET_CITIZEN_FOR_LEGAL = 'api/account/getCitizenForLegal';
export const URL_VALIDATE_ACCOUNT_BY_SSN = 'api/Account/ValidateAccountBySsn';
export const URL_VALIDATE_ACCOUNT_BY_SSN_WITH_BPR = 'api/Account/ValidateAccountBySsnWithBPR';
export const URL_BUILDING_TYPES = 'benefit/getBuildingTypes';
export const URL_EDUCATION_TYPES = 'benefit/GetEducationTypes';
export const URL_GET_PHOTO_FOR_USER = 'api/Account/GetPhotoFromBpr';
export const URL_GET_BANKS = 'benefit/getBanks';
export const URL_GET_PAY_METHOD = 'benefit/getPaymentMethods';
export const URL_GET_ALL_HEALTHS = 'EDisability/GetAllHealthFacilities';
export const URL_GET_ALL_OFFICES = 'EDisability/GetOfficesByCommunityId';
export const URL_GET_CAUSES = 'EDisability/GetDisabilityCause';
export const URL_GET_ATTACHED_DOCS = 'EDisability/GetAttachedDocumentTypes';
export const URL_GET_NOTIFICATION_TYPES = 'EDisability/GetAllNotificationTypes';
export const URL_GET_ALL_EDUCATIONS = 'EDisability/GetAllEducations';
export const URL_GET_ALL_OCCUPATIONS = 'EDisability/GetAllOccupations';
export const URL_GET_EVALUATION_WITH_SUBTYPES = 'EDisability/GetEvaluationWithSubtypes';
export const URL_GET_DECISION_NUMBER = 'Edisability/GetDecisionNumber';
export const URL_GET_GET_QKAG_DEATH_INFO = 'Edisability/GetQkagDeathInfo';
export const URL_GET_GET_SERVICE_TYPES = 'api/Dictionary/GetServicetypes';
export const URL_GET_USER_COMPANIES = '/api/Account/GetSelfCompanies';
export const URL_GET_USER_AUTHORITIES = '/api/Account/GetRepresentativeCompanies';
export const URL_GET_IS_ALLOWED_TO_ATTACH_AGREEMENT = '/api/Account/IsAllowedToAttachAgreement';

import { PersonDataForm } from '../../person-data-form';

const formItemProp = {
  fname: {
    name: ['profileData', 'legalRepresentative', 'fname'],
    readOnly: true,
  },
  lname: {
    name: ['profileData', 'legalRepresentative', 'lname'],
    readOnly: true,
  },
  patronymicName: {
    name: ['profileData', 'legalRepresentative', 'patronymicName'],
    readOnly: true,
  },
  ssn: {
    name: ['profileData', 'legalRepresentative', 'ssn'],
    readOnly: true,
  },
  birthdate: {
    name: ['profileData', 'legalRepresentative', 'birthdate'],
    readOnly: true,
  },
};

const formItemContactProp = (viewMode = false) => ({
  phoneNumber: {
    name: ['profileData', 'legalRepresentative', 'phoneNumber'],
    rules: [{ required: true }],
    readOnly: viewMode,
  },
  email: {
    name: ['profileData', 'legalRepresentative', 'email'],
    rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
    readOnly: viewMode,
  },
  gender: {
    name: ['profileData', 'legalRepresentative', 'gender'],
    readOnly: true,
  },
});

export const LegalRepresentative = ({ viewMode = false }) => {
  return (
    <PersonDataForm
      title="Օրինական ներկայացուցչի տվյալներ"
      formItemProp={formItemProp}
      formItemContactProp={formItemContactProp(viewMode)}
      viewMode
    />
  );
};

import { Col, Row } from 'antd';
import { SigninCard } from '../../components/card/signin-card';
import {OneTimePassword} from "../../components/form/one-time-password";

export const OtPassword = () => {
    return (
        <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
            <Col>
                <SigninCard>
                    <OneTimePassword />
                </SigninCard>
            </Col>
        </Row>
    );
};

import { Table } from 'antd';

export const HistoryService = ({ results }) => {
    const columns = [
        {
            title: 'Անվանում',
            dataIndex: 'actionName',
            align: 'center',
            flex: 1,
        },
        {
            title: 'Կատարման ամսաթիվ',
            dataIndex: 'actionDate',
            align: 'center',
            flex: 1,
        },
        {
            title: 'Կարգավիճակ',
            dataIndex: 'status',
            align: 'center',
            flex: 1,
        },
    ];
    return (
        <Table dataSource={results?.history} columns={columns} pagination={false} bordered locale={{ emptyText: ' ' }} />
    )
}

import { Row, Col, Typography } from 'antd';
import dayjs from 'dayjs';

export const FamilyMembers = ({ data }) => {
  return (
    <>
      <Row justify="center" gutter={16}>
        <Col span={24}>
          <Typography.Title level={5} style={{ textAlign: 'center', color: '#456FAE' }}>
            Ընտանիքի անդամներ
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>Ընտանիքում կան այլ անդամներ</Col>
        <Col>{data?.hasOtherFamilyMembers === true ? 'Այո' : 'Ոչ'}</Col>
      </Row>
      {data?.familyMembers &&
        data?.familyMembers.map((member) => {
          return (
            <>
              <Row justify="space-between">
                <Col>Անուն, ազգանուն, հայրանուն</Col>
                <Col>
                  {member?.citizenInfo?.firstName} {member?.citizenInfo?.lastName} {member?.citizenInfo?.patronymicName}
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>Ծննդյան ամսաթիվ</Col>
                <Col>
                  {member?.citizenInfo?.birthdate && dayjs(member?.citizenInfo?.birthdate).format('DD.MM.YYYY')}{' '}
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>Ազգակցական կապը դիմումատուի նկատմամբ</Col>
                <Col>{member?.relativeType} </Col>
              </Row>
              <Row justify="space-between">
                <Col>Հեռախոս</Col>
                <Col>{member?.phoneNumber}</Col>
              </Row>
              <Row justify="space-between">
                <Col>Էլեկտրոնային փոստ</Col>
                <Col>{member?.email}</Col>
              </Row>
              <Row justify="space-between">
                <Col>Սովորում է/եմ առկա ուսուցման համակարգում</Col>
                <Col>{member?.isStudying === true ? 'Այո' : 'Ոչ'}</Col>
              </Row>
              <Row justify="space-between">
                <Col>Հանդիսանում է/եմ զինծառայող</Col>
                <Col>{member?.isMilitaryService === true ? 'Այո' : 'Ոչ'}</Col>
              </Row>
            </>
          );
        })}
    </>
  );
};

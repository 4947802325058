import { Col, Radio, Row, Space } from 'antd';
import { Form } from 'components/form/form';
import { FormItem } from 'components/form/form-item';
import { Input } from 'components/input';
import { VerticalSpace } from 'components/space/vertical-space';
import { SecondaryText } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { useEffect, useState } from 'react';

export const UtilityItem = ({ item, dataKey }) => {
  const form = Form.useFormInstance();
  const itemValue = form.getFieldValue(item.name);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (itemValue) {
      setIsChecked(true);
    }
  }, [itemValue, item.name]);

  const handleCheckChange = (e) => {
    setIsChecked(e.target.value);
    if (!e.target.value) {
      form.setFieldValue(item.name, null);
    }
  };
  return (
    <VerticalSpace>
      <SecondaryText color={COLORS.PRIMARY.BLUE}>{item.label}</SecondaryText>
      {dataKey !== 'electricityAccount' && (
        <Row>
          <Col>
            <Radio.Group onChange={handleCheckChange}>
              <Space>
                <Radio value={true}>
                  <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
                </Radio>
                <Radio value={false}>
                  <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
                </Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      )}
      <Row align="middle">
        <Col xs={24} sm={24} md={8}>
          <FormItem
            {...item}
            rules={[{ required: dataKey === 'electricityAccount' || isChecked }]}
            label={false}
            placeholder="Լրացնել բաժանորդային համարը"
          >
            <Input
              disabled={!isChecked && dataKey !== 'electricityAccount'}
              placeholder="Լրացնել բաժանորդային համարը"
            />
          </FormItem>
        </Col>
      </Row>
    </VerticalSpace>
  );
};

import { forwardRef } from 'react';
import { Select } from '.';
import { URL_ALL_REGIONS } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_DEFAULT } from './constants';

export const RegionsSelect = forwardRef((props, ref) => {
  const { data } = useGetFfromBenefit(URL_ALL_REGIONS);

  const filterOption = (input, option) =>
    option?.[FIELD_NAMES_DEFAULT.label]?.toLowerCase()?.includes(input.toLowerCase());

  return (
    <Select
      showSearch
      filterOption={filterOption}
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      options={data}
      {...props}
      ref={ref}
    />
  );
});

import { Space } from 'antd';
import { Checkbox } from '../../checkbox';
import { FormItem } from '../form-item';

export const AcceptDataIsCorrect = () => {
  return (
    <Space>
      <FormItem propName="checked" name="agreeDataCorrect" rules={[{ required: true }]}>
        <Checkbox.Group>
          <Checkbox value={true}>Հավաստիացնում եմ, որ ներկայացրած տվյալները ճիշտ են։ </Checkbox>
        </Checkbox.Group>
      </FormItem>
    </Space>
  );
};

import { Form, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { armenianLettersPattern } from 'helpers/constants';
import { dateConvert, errorMessage } from 'helpers/utils';
import { useGetValidateUser } from 'api/benefit/use-get-validate-user';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabilityButton } from 'components/button';
import { ErrorText } from 'components/typography/error-text';
import { SuccessText } from 'components/typography/success-text';
import { FormItem } from 'components/form/form-item';
import { URL_CHECK_APPLICATION_EXISTS, URL_CHECK_OTHER_APPLICATION_EXISTS } from 'api/application/constants';
import { useParams } from 'react-router-dom';
import { LEGAL_REPRESENTITIVE } from 'pages/application/constants';
import { usePostMutate } from 'api/urrp/use-post-mutate';

const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

export const formItemProp = {
  fname: {
    name: ['applicationForm', 'firstName'],
    rules: armenianPattern,
  },
  lname: {
    name: ['applicationForm', 'lastName'],
    rules: armenianPattern,
  },
  patronymicName: {
    name: ['applicationForm', 'patronymicName'],
    rules: armenianPattern,
  },
  ssn: {
    name: ['applicationForm', 'ssn'],
    rules: [
      { required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (value === getFieldValue(['applicationForm', 'legalRepresentative', 'ssn'])) {
            return Promise.reject(new Error('Դիմումատուն և Օրինական ներկայուցուցչը չեն կարող լինել նույն մարդը'));
          }

          return Promise.resolve();
        },
      }),
    ],
  },
  birthdate: {
    name: ['applicationForm', 'birthDate'],
  },
  isApproved: {
    name: ['applicationForm', 'isApproved'],
  },
  phoneNumber: {
    name: ['applicationForm', 'phoneNumber'],
    rules: [{ required: true }],
  },
  email: {
    name: ['applicationForm', 'email'],
    rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
  },
  gender: {
    name: ['applicationForm', 'gender'],
  },
};

export const onReset = (form) => {
  form.resetFields(Object.values(formItemProp).map((item) => item.name));
};

export const DisabilityPersonDataSearchForm = ({ url, onSuccess, onSelect, parent = [] }) => {
  const [search, setSearch] = useState(false);
  const form = Form.useFormInstance();
  const params = useParams();
  const isApproved = Form.useWatch([...parent, ...(formItemProp?.isApproved?.name || [])], { preserve: true });
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const ssn = Form.useWatch([...parent, ...(formItemProp.ssn.name || [])], { preserve: true });

  const { mutate: checkOtherApplications, isLoading: isCheckLoading } = usePostMutate(
    ['1', '2', '3', '10'].includes(params.subtypeId)
      ? URL_CHECK_OTHER_APPLICATION_EXISTS
      : URL_CHECK_APPLICATION_EXISTS,
    {
      onSuccess: (data) => {
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], !!data);
        onSearch();
      },
      retry: false,
      enabled: !!(
        false &&
        params.subtypeId &&
        applicantType === LEGAL_REPRESENTITIVE &&
        ['1', '2', '3', '4', '5', '8', '9'].includes(params.subtypeId)
      ),
      onError: (err) => {
        errorMessage(err);
        onReset(form);
      },
    }
  );

  const onSearch = () => {
    form
      ?.validateFields([
        formItemProp.ssn.name,
        formItemProp.fname.name,
        formItemProp.lname.name,
        formItemProp.patronymicName.name,
        formItemProp.birthdate.name,
      ])
      .then((values) => {
        const ssn = values.applicationForm.ssn;
        const firstName = values.applicationForm.firstName;
        const lastName = values.applicationForm.lastName;
        const patronymicName = values.applicationForm.patronymicName;
        const birthdate = dateConvert(values.applicationForm.birthDate);

        if (ssn && firstName && lastName && birthdate) {
          setSearch({ ssn, firstName, lastName, patronymicName, birthdate });
        }
      })
      .catch((errorInfo) => {
        form.setFields(errorInfo.errorFields);
      });
  };

  const { isInitialLoading } = useGetValidateUser(url, search, {
    enabled: !!search.ssn,
    onSuccess: (data) => {
      const isSuccess = onSuccess(data);
      if (isSuccess) {
        checkOtherApplications({ subtypeId: params.subtypeId, ssn: ssn });
      }
    },
    select: (data) => onSelect?.(data) || data.data,
    onError: (error) => {
      errorMessage(error);
      formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
      onSearch();
    },
  });

  return (
    <Spin spinning={isInitialLoading || isCheckLoading}>
      <VerticalSpace>
        {/* <PersonMainData formItemProp={formItemProp} /> */}

        <FormItem
          name={[...parent, ...(formItemProp?.isApproved?.name || [])]}
          label={false}
          rules={[
            {
              validator(_, value) {
                if (value === true) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Անձը չի հաջողվել նույնականացնել'));
              },
            },
          ]}
        >
          {isApproved === null || isApproved === undefined ? (
            <DisabilityButton block size="large" icon={<SearchOutlined />} type="default" onClick={onSearch}>
              Որոնել
            </DisabilityButton>
          ) : (
            <DisabilityButton block size="large" icon={<SearchOutlined />} type="default" onClick={() => onReset(form)}>
              Չեղարկել
            </DisabilityButton>
          )}
        </FormItem>

        {isApproved === false && <ErrorText text="Նույնականացումը չի հաջողվել" />}
        {isApproved === true && <SuccessText text="Անձը նույնականացվել է" />}
      </VerticalSpace>
    </Spin>
  );
};

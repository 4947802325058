import { useOutletContext } from 'react-router-dom';
import { URL_CITIZEN_INFO } from '../../api/application/constants';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import { setFormCitizenData } from '../../components/form/application/utils';
import { Form } from '../../components/form/form';
import { useApplication } from '../../context/applicaton-context';
import { APPLICATION_FOR_HIMSELF } from '../../pages/application/constants';
import { errorMessage } from '../../helpers/utils';

export const selectCitizenInfo = ({ data: { registrationAddress, ...data } }) => ({
  citizenInfo: {
    ...data,
    documentType: data.documents[0].documentType,
    documentNumber: data.documents[0].documentNumber,
    gender: data.sex,
  },
  registrationAddress,
});

export const useGetCitizenInfo = (getCitizenData) => {
  const context = useOutletContext();
  const { addCityzenData, submittedData } = useApplication();
  const form = Form.useFormInstance();

  const { isInitialLoading } = useDataForApplication(
    URL_CITIZEN_INFO,
    {},
    {
      enabled: !!(
        (submittedData.fillingApplication === APPLICATION_FOR_HIMSELF &&
          !context.isEdit &&
          !submittedData.citizenData) ||
        (submittedData.fillingApplication === APPLICATION_FOR_HIMSELF && context.isEdit && getCitizenData)
      ),
      cacheTime: 0,
      select: selectCitizenInfo,
      onSuccess: (data) => {
        setFormCitizenData(form, data);
        addCityzenData(data);
      },
      onError: errorMessage,
    }
  );

  return isInitialLoading;
};

import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

const WrapperContent = styled(Content)`
  margin: 40px 20px;
`;

export const ApplicationContent = (props) => <WrapperContent {...props} />;

import { Table, Space, } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import styled from 'styled-components';
// import CalculatorImg from "../images/calculator.png"; 


const TotalAmount = styled(Title)` 
    justify-content: end;
    display: flex; 
`;



export const PaymentsService = ({ results }) => {
    // console.log(results, 'resultsresults');
    const columns = [
        {
            title: 'Ամիս',
            dataIndex: 'month',
            align: 'center',
            flex: 1,
        },
        {
            title: 'Նպաստի չափ',
            dataIndex: 'money',
            align: 'center',
            flex: 1,
        },
        {
            title: 'Փոխանցման',
            dataIndex: 'paymentDate',
            align: 'center',
            flex: 1,
        },
    ];
    return (
        <VerticalSpace >

            <Space size="middle" direction="vertical" style={{ width: '100%' }}>
                <Table
                    title={() => {
                        return <TotalAmount size={5}>{results?.totalPaidAmount}</TotalAmount>
                    }}
                    dataSource={results?.payments} columns={columns} pagination={false} bordered locale={{ emptyText: ' ' }} />
            </Space>
        </VerticalSpace>

    )
}

import { List } from 'antd';
import { Form } from '../components/form/form';
import { FormSectionCard } from '../components/card/form-section-card';

export const ApplicationHistory = () => {
  const applicationHistory = Form.useWatch('applicationHistory', { preserve: true });
  return (
    <FormSectionCard
      style={{
        height: 250,
        overflow: 'auto',
      }}
      title="Պատմություն"
    >
      <List
        dataSource={applicationHistory}
        renderItem={(item) => (
          <List.Item key={item.applicationId}>
            <List.Item.Meta description={item.description} />
            <div>{item.dateTime}</div>
          </List.Item>
        )}
      />
    </FormSectionCard>
  );
};

import { Modal as ModalComponent } from 'antd';
import styled from "styled-components";
import {COLORS} from "../../helpers/constants";

export const Notification = styled(ModalComponent)`
    .ant-modal-content {
        padding: 0;
        
        .ant-modal-body {
           padding: 20px;
           background: ${COLORS.PRIMARY.WHITE};
           color: ${COLORS.PRIMARY.RED};
           text-align: center;
        }

        .ant-modal-close-x {
           color: ${COLORS.PRIMARY.RED};
        }

        .ant-modal-header {
           text-align: center;
           padding: 15px;
           margin: 0;

         .ant-modal-title {
           color: ${COLORS.PRIMARY.RED};
         }       
        }
    }
`;
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from '../../helpers/utils';

import { client } from '../client';
import { GET_NOTIFICATIONS, GET_UNREAD_FOR_CURRENT_USER } from './constants';

export const usePostNotification = (url, { onSuccess = noop, ...restOptions }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((values) => client.post(url, {}, { params: values }), {
    ...restOptions,
    onSuccess: () => {
      queryClient.invalidateQueries([GET_NOTIFICATIONS]);
      queryClient.invalidateQueries([GET_UNREAD_FOR_CURRENT_USER]);
      onSuccess();
    },
  });
  return mutation;
};


import { useState } from 'react';
import { CloseOutlined, FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import { FormItem } from '../form/form-item';
import { UploadDocument } from './upload-document';
import { useAuth } from '../../context/auth-context';
import { VerticalSpace } from '../space/vertical-space';
import { Info } from '../typography/info';
import { Modal, Upload, message } from 'antd';
import dayjs from 'dayjs';
import { SmallText } from '../typography';
import { COLORS } from '../../helpers/constants';
import { Form } from 'components/form/form';
import { usePostNoMutate } from 'api/application/use-post-no-mutate';
import { URL_DELETE_FILE, URL_PREVIEW_FILE } from 'api/application/constants';
import { useParams } from 'react-router-dom';
import { useGetFile } from 'api/application/use-get-file';
import styled from 'styled-components';
import { URL_UPLOAD_FILE } from 'api/file/constants';

const UploadStyled = styled(UploadDocument)`
  &.ant-upload-wrapper {
    display: flex;
    gap: 32px;

    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .ant-upload-list-item {
        cursor: pointer;

        &:hover {
          background-color: transparent;
        }
      }

      .ant-upload-list-item-name {
        color: ${COLORS.PRIMARY.BLUE};
        border-bottom: 1px solid ${COLORS.PRIMARY.BLUE};
        white-space: normal;
        word-break: break-all;
      }
    }
    
    @media only screen and (max-width: 768px) {
      display: block;
      margin: 10px;
    }
  }
`;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const getData = (user, attachedDocumentType, tempApplicationId = '', applicationId = '') => ({
  ssn: user.ssn,
  birthDate: dayjs(user.birthdate).format('YYYY-MM-DD'),
  applicationId: tempApplicationId ? '' : applicationId,
  incompleteApplicationId: tempApplicationId || '',
  hash: '',
  attachedDocumentTypeId: attachedDocumentType,
});

const fileTypes = ['.pdf', '.png', '.jpeg', '.jpg'];
const allowedTypes = fileTypes.join(',');
const maxSize = 10; // in MB

const defaultProps = {
  beforeUpload: (file) => {
    const isFileTypeAllowed = fileTypes.some((type) => file.name.toLowerCase().endsWith(type));
    const isFileSizeAllowed = file.size / 1024 / 1024 < maxSize;
    if (!isFileTypeAllowed) {
      message.error(`Միայն ${allowedTypes} տեսակի ֆայլերն են թույլատրվում.`);
    }
    if (!isFileSizeAllowed) {
      message.error(`Ֆայլի չափսը պետք է լինի ${maxSize}MB-ից փոքր`);
    }
    return (isFileTypeAllowed && isFileSizeAllowed) || Upload.LIST_IGNORE;
  },
  actionIcon: <PaperClipOutlined />,
  accept: allowedTypes,
  showUploadList: {
    showPreviewIcon: true,
    // previewIcon: <FileOutlined style={{ color: COLORS.PRIMARY.BLUE }} />,
    showRemoveIcon: true,
    removeIcon: <CloseOutlined style={{ color: COLORS.ALERT.RED }} />,
  },
  // previewFile: () => <FileOutlined style={{ color: COLORS.PRIMARY.BLUE }} />,
  //   showUploadList: false,
};

export const UploadApplicationDocument = ({
  checksumName,
  isRequired = true,
  attachedDocumentType,
  noInfo = false,
  infoText,
  tempApplicationId,
  viewMode = false,
  userData,
  uploadUrl = URL_UPLOAD_FILE,
  text = "Տեղեկանք",
  ...props
}) => {
  const params = useParams();

  const [checksumToRemove, setChecksumToRemove] = useState();
  const [checksumToView, setChecksumToView] = useState();
  const { user } = useAuth();
  const form = Form.useFormInstance();

  usePostNoMutate(
    `${URL_DELETE_FILE}?checksum=${checksumToRemove}`,
    {},
    {
      enabled: !!checksumToRemove,
      onSuccess: () => {
        setChecksumToRemove();
        Modal.success({
          title: 'Ֆայլը հաջողությամբ ջնջվեց',
        });
      },
    }
  );

  useGetFile(
    URL_PREVIEW_FILE,
    { fileChecksum: checksumToView },
    {
      enabled: !!(checksumToView && (params.id || tempApplicationId)),
      onSuccess: () => {
        setChecksumToView();
      },
    }
  );

  const handleRemove = async (file) => {
    setChecksumToRemove(file.response.fileChecksum);
  };

  const handlePreview = async (file) => {
    setChecksumToView(file.response.fileChecksum);
  };

  const onChange = (info) => {
    if (info.file.status === 'done') {
      form.setFieldValue(checksumName, info.fileList);
    }
  };

  return (
    <VerticalSpace size="small">
      <FormItem
        label=""
        valuePropName="fileList"
        name={checksumName}
        getValueFromEvent={normFile}
        rules={[{ required: isRequired, message: 'Տեղեկանք կցված չէ' }]}
      >
        <UploadStyled
          openFileDialogOnClick={!!(attachedDocumentType && !viewMode)}
          data={getData(userData || user, attachedDocumentType, tempApplicationId, params.id)}
          text={text}
          onChange={onChange}
          onRemove={handleRemove}
          onPreview={handlePreview}
          headers={{
            Authorization: `Bearer ${user.accessToken}`,
          }}
          iconRender={() => <FileOutlined width={32} height={32} style={{ color: COLORS.PRIMARY.BLUE }} />}
          multiple
          // eslint-disable-next-line no-undef
          action={`${process.env.REACT_APP_BASE_URL}${uploadUrl}`}
          {...defaultProps}
          {...props}
        />
      </FormItem>
      {!noInfo && (
        <Info
          text={
            <VerticalSpace size="small">
              {infoText && <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>{infoText}</SmallText>}
              <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>
                Կցվող փաստաթուղթը պետք է լինի jpg/png/pdf ֆորմատի և չգերազանցի 10MB-ը։
              </SmallText>
            </VerticalSpace>
          }
        />
      )}
    </VerticalSpace>
  );
};

import {useNavigate} from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { URL_CHANGE_PASSWORD } from '../../../api/auth/constants';
import { useMutateUserData } from '../../../api/auth/use-mutate-user-data';
import { Password } from '../../input/password';
import { VerticalSpace } from '../../space/vertical-space';
import { Form } from '../form';
import { FormItem } from '../form-item';
import { Button } from '../../button';
import {PATHS, REQUEST_TYPES} from '../../../helpers/constants';
import { useAuth } from '../../../context/auth-context';
import {PasswordForm} from "../password-form";
import {errorMessage} from "../../../helpers/utils";

export const ChangePasswordForm = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { mutate } = useMutateUserData(URL_CHANGE_PASSWORD, REQUEST_TYPES.POST, {
        onSuccess: () => {
            logout();
            navigate(`/${PATHS.SIGN_IN}`)
        },
        onError: errorMessage,
    });
    const onFinish = (values) => {
        delete values.newPasswordRepeat;
        mutate(values);
    };
    return (
        <Form
            name="user-form-pass"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
        >
            <VerticalSpace size="large">
                <FormItem name="oldPassword" label="Հին գաղտնաբառ" rules={[{ required: true }]}>
                    <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
                </FormItem>
                <PasswordForm/>
                <VerticalSpace size="middle">
                    <Button htmlType="submit" block type="primary" size="large">
                        Հաստատել
                    </Button>
                </VerticalSpace>
            </VerticalSpace>
        </Form>
    );
};

export const useColumns = () => {

  return [
    {
      title: 'ՀԾՀ N/ՀԾՀ չունենալու մասին տեղեկանքի N',
      dataIndex: 'ssn',
      key: 'ssn',
      flex: 1
    },
    {
      title: 'Անուն',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Ազգանուն',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Հայրանուն',
      dataIndex: 'patronymicName',
      key: 'patronymicName',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Ստորագրություն',
      align: 'center',
      flex: 1,
    },
  ];
};



import {Col, Row} from "antd";
import {Text, Title} from "../../components/typography";
import {Button} from "../../components/button";
import {COLORS} from "../../helpers/constants";
import HeadImg from "./images/services-header.png";
import Questionnaire from './images/questionnaire.svg';
import styled, {css} from "styled-components";

export const BackgroundHeader = styled(Row)`
  ${(props) =>
    props.inner
        ? css`
          color: #ffffff;
        `
        : css`
          background-image: url(${HeadImg});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: 650px;
        `}
`;

export const ServicesHeader = () => {

    return (
        <BackgroundHeader>
            <Row style={{padding: 30}}>
                <Col xs={24} sm={22} md={14} lg={8}>
                    <Row gutter={[10, 40]} style={{padding: '0 0 30px 20px'}}>
                        <Col>
                            <Title level={2} style={{color: COLORS.PRIMARY.WHITE}}>Հարգելի քաղաքացի,</Title>
                        </Col>
                        <Col md={24}>
                            <Text style={{color: COLORS.PRIMARY.WHITE}}>Բարի գալուստ ՀՀ սոցիալական պաշտպանության ոլորտի
                                դիմումների միասնական հարթակ։ Եթե դեռ
                                չեք
                                կողմնորոշվել,
                                թե ՀՀ սոցիալական պաշտպանության ոլորտում ինչ ծառայության անհրաժեշտություն ունեք, ապա
                                կարող եք
                                լրացնել ինքնագնահատման հարցաշարը,
                                որի արդյունքում համակարգը ձեզ կհուշի, թե որ ծառայությունն ստանալու անհրաժեշտություն դուք
                                ունեք։</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} xl={18}>
                            <Button block
                                    style={{height: 57, borderRadius: 12, display: "flex", fontSize: 12, alignItems: "center", justifyContent: "center", background: COLORS.SECONDARY.OIL}}
                                    type="primary">
                                <img src={Questionnaire} style={{width: 25, marginRight: 10}} alt=""/> ԻՆՔՆԱԳՆԱՀԱՏՄԱՆ ՀԱՐՑԱՇԱՐ
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </BackgroundHeader>
    )
}
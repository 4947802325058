import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { SecondaryText } from '../../../typography';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { useApplication } from 'context/applicaton-context';
import { Form } from 'components/form/form';
import { useApplicationForText } from 'hooks/application/use-application-for-text';

export const MilitaryForm = ({ formItemMilitary = {}, parent = [], isPersonal = false }) => {
  const { submittedData, cityzenData } = useApplication();
  const isMilitary = Form.useWatch([...parent, ...formItemMilitary.isMilitaryService.name]);
  const applicationForText = useApplicationForText('եմ', 'է');

  return (
    <FormSectionCard title={`Ժամկետային զինծառայող ${isPersonal ? applicationForText : 'է'}`}>
      <FormItem rules={[{ required: true }]} {...formItemMilitary.isMilitaryService}>
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      {isMilitary === true && (
        <UploadApplicationDocument
          tempApplicationId={submittedData.tempApplicationId}
          checksumName={formItemMilitary.militaryServiceFilesChecksum.name}
          attachedDocumentType={DOCUMENT_TYPES.MILITARY}
          viewMode={!cityzenData?.citizenInfo}
          userData={cityzenData?.citizenInfo}
          infoText="Խնդրում ենք կցել զինկոմիսարիատի կողմից տրամադրված տեղեկանքը։"
        />
      )}
    </FormSectionCard>
  );
};

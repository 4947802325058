import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';
import { Card } from 'antd';

export const RegistrationCard = styled(Card)`
  & {
    padding: 30px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);

    .ant-card-head {
      padding: 0;
      background-color: ${COLORS.PRIMARY.GRAY_MEDIUM};
      min-height: 46px;
      justify-content: flex-start;

      .ant-tabs-nav {
        margin-bottom: 4px;
      }

      .ant-tabs-tab {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .ant-card-body {
      padding: 0;
    }
  }
`;

import { forwardRef } from 'react';
import { Select } from '.';
import { URL_EDUCATION_TYPES } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_DEFAULT } from './constants';

export const EducationTypesSelect = forwardRef((props, ref) => {
  const { data } = useGetFfromBenefit(URL_EDUCATION_TYPES);
  return <Select fieldNames={FIELD_NAMES_DEFAULT} style={{ width: '100%' }} options={data} {...props} ref={ref} />;
});

import styled from 'styled-components';

import { Profile } from './profile';

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
`;

export const SidebarProfile = () => {

  return (
    <ProfileWrapper>
      <Profile />
    </ProfileWrapper>
  );
};


import { Radio, Space } from 'antd';
import { COLORS } from '../../../../helpers/constants';
import { FormSectionCard } from '../../../card/form-section-card';
import { Form } from '../../form';
import { FormItem } from '../../form-item';
import { Info } from 'components/typography/info';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SecondaryText } from 'components/typography';

export const HasFamilyMember = ({ viewMode = false }) => {
  const form = Form.useFormInstance();
  const hasFamilyMember = Form.useWatch('familyData');
  const context = useOutletContext();
  useEffect(() => {
    if (
      hasFamilyMember &&
      form.getFieldValue(['familyData', 'hasOtherFamilyMembers']) !== true &&
      context?.isEdit === true
    ) {
      form.setFieldValue(['familyData', 'hasOtherFamilyMembers'], false);
    }
  }, [form, hasFamilyMember, context]);

  const onChange = (e) => {
    // Remove
    if (e.target.value === true) {
      form.getFieldValue(['familyData', 'familyMembers', 0, 'relativeType']);
    }

    return e.target.value;
  };
  return (
    <FormSectionCard title="Ընտանիքում կան այլ անդամներ">
      <FormItem rules={[{ required: true }]} name={['familyData', 'hasOtherFamilyMembers']}>
        <Radio.Group onChange={onChange} disabled={viewMode}>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      <Info text="Ընտանիքի անդամները պետք է հաշվառված լինեն նույն հասցեում" />
    </FormSectionCard>
  );
};

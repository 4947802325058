import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Space } from 'antd';

import { APPLICATION_STATUSES, PATHS } from '../../helpers/constants';
import { ReactComponent as RightCircle } from './images/right-circle.svg';
import {APPLICATION_TYPES} from "../applications/constants";

export const useColumns = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'N',
      dataIndex: 'n',
      align: 'center',
      flex: 1,
      render: (_, record, key) => +key + 1,
    },
    {
      title: 'Ծառայության տեսակ',
      dataIndex: 'serviceType',
      key: 'serviceType',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Դիմումի ներկայացման ամսաթիվ',
      dataIndex: 'applicationCreatedDate',
      key: 'applicationCreatedDate',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Որոշման համար',
      dataIndex: 'decisionNumber',
      key: 'decisionNumber',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Որոշման ամսաթիվ',
      dataIndex: 'decisionCreatedDate',
      key: 'decisionCreatedDate',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.decisionCreatedDate, 'DD/MM/YYYY').diff(dayjs(b.decisionCreatedDate, 'DD/MM/YYYY')),
    },
    {
      title: 'Որոշումը վավեր է մինչեւ',
      dataIndex: 'decisionValidUntil',
      key: 'decisionValidUntil',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.decisionValidUntil, 'DD/MM/YYYY').diff(dayjs(b.decisionValidUntil, 'DD/MM/YYYY')),
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      flex: 1,
      filters: APPLICATION_STATUSES,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Կարգավիճակի ստացման ամսաթիվ',
      dataIndex: 'stateChangedDate',
      key: 'stateChangedDate',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.stateChangedDate, 'DD/MM/YYYY').diff(dayjs(b.stateChangedDate, 'DD/MM/YYYY')),
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      render: (item) => (
        <Space>
          <RightCircle
            style={{ cursor: 'pointer' }}
            key={item}
            onClick={() => navigate(`${PATHS.SERVICES_VIEW.replace(':type', APPLICATION_TYPES.MY_SERVICES).replace(':id', item.applicationId)}`)}
          />
        </Space>
      ),
    },
  ];
};

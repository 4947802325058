import { COLORS } from '../../helpers/constants';
import { SmallText } from './text';

export const FormRequiredInfo = () => {
  return (
    <SmallText>
      <span style={{ color: COLORS.ALERT.RED }}>*</span>-ով նշված դաշտերում տվյալների մուտքագրումը պարտադիր է
    </SmallText>
  );
};

import { useState } from 'react';
import { Modal } from 'components/modal';
import { Table, Button, Checkbox, Space, Spin } from 'antd';
import { useDataForApplication } from "../../../api/application/use-get-data-for-application";
import { useSaveApplication } from "../../../api/application/use-save-application";
import { URL_GET_APPLICATION_STOP_FACTORS, URL_CREATE_APPEAL } from "../../../api/application/constants";
import { AppealText } from "../constants";
import { errorMessage } from 'helpers/utils';


export const AppealModal = ({ visible, appealApplicationId, onClose }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [rejectionReasons, setRejectionReasons] = useState([]);

    const handleSelectRow = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const handleConfirm = () => {
        // eslint-disable-next-line no-console
        console.log("Selected Rows:", selectedRows);
        // Handle confirmation logic
        appealSubmit({ "applicationId": appealApplicationId, "stopFactors": selectedRows })
        handleModalClose();
    };
    const handleModalClose = () => {
        setSelectedRows([]); // Clear selected checkboxes
        onClose(); // Call the parent close function
    };

    const { isLoading } = useDataForApplication(URL_GET_APPLICATION_STOP_FACTORS, { applicationId: appealApplicationId }, {
        onSuccess: (data) => {
            // eslint-disable-next-line no-console
            console.log('data', data);
            setRejectionReasons(data);

        },
        enabled: !!appealApplicationId,
    });

    const { mutate: appealSubmit } = useSaveApplication(URL_CREATE_APPEAL,
        {
            onSuccess: () => {
                Modal.success({
                    title: 'Շնորհակալություն',
                    footer: false,
                    closable: true,
                });
            },
            onError: errorMessage,
        }
    );

    const columns = [
        {
            title: 'Ընտրել',
            dataIndex: 'rejectionReasonId',
            key: 'checkbox',
            width: 100,
            render: (rejectionReasonId) => (
                <Checkbox
                    checked={selectedRows.includes(rejectionReasonId)}
                    onChange={() => handleSelectRow(rejectionReasonId)}
                />
            ),
        },
        {
            title: 'Բացասման գործոն',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Սահմանված գումար',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        },
    ];
    return (
        <Modal
            title=""
            open={visible}
            onCancel={handleModalClose}
            width={'70%'}
            footer={[
                <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }} key="footer-space">
                    <Button key="back" onClick={handleModalClose}>
                        Չեղարկել
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleConfirm} disabled={rejectionReasons?.IsAllowedToAppeal}>
                        Բողոքարկել
                    </Button>
                </Space>
                ,
            ]}
        >
            <Spin spinning={isLoading}>
                {!isLoading && (
                    <AppealText rejectionReasons={rejectionReasons} />
                )}

                <Table
                    columns={columns}
                    dataSource={rejectionReasons?.stopFactors}
                    pagination={false}
                    rowKey="rejectionReasonId"
                />
            </Spin>
        </Modal>
    );
};

import { useState } from "react";
import { Spin } from "antd";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { URL_GET_PRINT_APPLICATION } from "../../api/application/constants";
import { useDataForApplication } from "../../api/application/use-get-data-for-application";
import { CitizenInfo } from "./components/applications/citizen-info";
import { FamilyMembers } from "./components/applications/family-members";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Header as LayoutHeader } from "../../components/layouts/header";


const Wrapper = styled.div`
  width: 21cm;
  background: white;
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5); 
  padding: 17px; 
  font: 12pt "Sylfaen"; 
  border: 6px solid #456FAE;
  border-radius: 10px;
  box-sizing: border-box; 
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
`;
const InnerWrapper = styled.div`
    background: #FFFFFF;
    border: 2px solid #456FAE;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    padding: 30px; 
    box-sizing: border-box;
    .ant-layout-header {
        padding: 0px;
    }
`;


export const ApplicationPrint = () => {
    const params = useParams();
    const [result, setResult] = useState([]);

    const { isLoading } = useDataForApplication(URL_GET_PRINT_APPLICATION, { applicationId: params.id }, {
        onSuccess: (data) => {
            setResult(data)
        },
        enabled: !!params.id,
    });
    return (
        <Spin spinning={isLoading}>
            <Wrapper className='print'>
                <InnerWrapper className='page'>
                    <LayoutHeader />
                    <Header number={` Դիմում  N ${result.number}`} createdDate={result.createdDate} title='Ընտանիքի անապահովության նպաստ ստանալու մասին' />
                    <>
                        <CitizenInfo data={result} />
                        <FamilyMembers data={result} />
                        <Footer printDate={result.printDate} />
                    </>
                </InnerWrapper>

            </Wrapper>
        </Spin>
    )
}


import { UploadButton } from './upload-button';

export const ManageFamilyMemberButton = ({ doAction, text, icon, ...props }) => {
  return (
    <UploadButton
      actionIcon={icon}
      size="large"
      text={text}
      onClick={(event) => {
        event.stopPropagation();
        doAction();
      }}
      {...props}
    />
  );
};

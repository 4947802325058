import {useNavigate, useLocation} from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import {URL_RECOVER_PASSWORD} from '../../../api/auth/constants';
import { useMutateUserData } from '../../../api/auth/use-mutate-user-data';
import { Password } from '../../input/password';
import { VerticalSpace } from '../../space/vertical-space';
import { Form } from '../form';
import { FormItem } from '../form-item';
import { Button } from '../../button';
import {PATHS, REQUEST_TYPES} from '../../../helpers/constants';
import {PasswordForm} from "../password-form";

export const RecoverPass = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { mutate } = useMutateUserData(URL_RECOVER_PASSWORD, REQUEST_TYPES.POST, {
        onSuccess: () => {
            navigate(`/${PATHS.SIGN_IN}`)
        },
    });
    const onFinish = (values) => {
        const email = location.state ? location.state.email : '';
        const password = values.newPassword;

        delete values.newPasswordRepeat;
        delete values.newPassword;

        delete values.birthDate;
        const result = {
            ...values,
            password,
            email
        };
        mutate(result);
    };
    return (
        <Form
            name="user-form-pass"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
        >
            <VerticalSpace size="large">
                <FormItem name="code" label="Վերականգնման կոդ" rules={[{ required: true }]}>
                    <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
                </FormItem>
                <PasswordForm/>
                <VerticalSpace size="middle">
                    <Button htmlType="submit" block type="primary" size="large">
                        Հաստատել
                    </Button>
                </VerticalSpace>
            </VerticalSpace>
        </Form>
    );
};

/* eslint-disable no-unused-vars */
import { Form, Modal } from 'antd';
import { useDataForDisabilityApplication } from 'api/application/use-get-data-for-disability-application';
import { URL_GET_DECISION_NUMBER } from 'api/benefit/constants';
import { PATHS } from 'helpers/constants';
import { errorMessage } from 'helpers/utils';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useGetDisabilityDecisionNumber = () => {
  const params = useParams();
  const navigate = useNavigate();
  const form = Form.useFormInstance();
  const ssn = Form.useWatch(['applicationForm', 'validSSN'], { preserve: true });

  const { isInitialLoading: dataDecisionIsLoading, data: dataDecision } = useDataForDisabilityApplication(
    URL_GET_DECISION_NUMBER,
    { ssn },
    {
      enabled: !!(['7'].includes(params.subtypeId) && ssn),
      onError: (err) => {
        if (+params.typeId !== 3 && err?.response?.data) {
          errorMessage(err);
          navigate(PATHS.SERVICES);
        }
      },
    }
  );

  useEffect(() => {
    if (dataDecision?.decisionId && dataDecision?.isAllowed === true) {
      form.setFieldsValue({
        decisionId: dataDecision.decisionId,
      });
    }
  }, [dataDecision.decisionId, dataDecision.decisionNumber, dataDecision?.isAllowed, form, navigate]);

  return { dataDecision, dataDecisionIsLoading };
};

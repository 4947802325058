import { useNavigate } from 'react-router-dom';
import {Form} from "../../../components/form/form";
import {PersonDataSearchForm} from "../person-data-search-form";
import {URL_VALIDATE_ACCOUNT_BY_SSN_WITH_BPR} from "../../../api/benefit/constants";
import {Col, Row} from "antd";
import {Button} from "../../button";
import {VerticalSpace} from "../../space/vertical-space";
import {useCallback, useState} from "react";
import {useUserCreate} from "../../../api/auth/use-user-create";
import {convertOldDate, errorMessage} from "../../../helpers/utils";
import {URL_FORGOT_PASSWORD} from "../../../api/auth/constants";
import {Notification} from "../../modal/Notification";
import {Title} from "../../typography";
import {PATHS} from "../../../helpers/constants";

export const ForgotPassword = ({ formItemProp }) => {
    const navigate = useNavigate();
    const [getNavigate, setNavigate] = useState();

    const [form] = Form.useForm();

    const { mutate } = useUserCreate(URL_FORGOT_PASSWORD,
        {
            onSuccess: () => {
                setNavigate({
                    path: `${PATHS.RECOVER_PASSWORD}`,
                    state: { email: form.getFieldValue("email") },
                    context: "Շարունակելու համար խնդրում ենք ստուգել Ձեր էլեկտրոնային փոստը։",
                    modal: true
                });
            },
            onError: errorMessage,
        }
    );

    const handleCancel = () => {
        navigate(getNavigate.path , { state: getNavigate.state });
    };

    const onFinish = (values) => {
        const birthDate = convertOldDate(values.birthDate);
        const result = {
            ...values,
            birthDate
        };
        mutate(result);
    };

    const onSuccess = useCallback(
        (data) => {
            form.setFieldValue(['isApproved'], data);
            if(data === true) {
                formItemProp.fname.readOnly = true;
                formItemProp.lname.readOnly = true;
                formItemProp.patronymicName.readOnly = true;
                formItemProp.ssn.readOnly = true;
                formItemProp.birthdate.readOnly = true;
            } else {
                form.resetFields();
            }
        },
        [form, formItemProp]
    );


    return (
        <>
            <Title>Վերականգնել Գաղտնաբառը</Title>
            <Form
                layout="vertical"
                name="application-form-sign-in"
                form={form}
                autoComplete="off"
                onFinish={onFinish}
            >
                <VerticalSpace>
                    <PersonDataSearchForm formItemProp={formItemProp} url={URL_VALIDATE_ACCOUNT_BY_SSN_WITH_BPR} onSuccess={onSuccess}/>
                    <Row justify="center">
                        <Col span={12}>
                            <Button size="large" block type="primary" htmlType="submit">
                                Հաստատել
                            </Button>
                        </Col>
                    </Row>
                </VerticalSpace>
            </Form>
            {
                getNavigate?.modal &&
                <Notification centered title="Ծանուցում" footer={null} open={getNavigate?.modal} onCancel={handleCancel}>
                    <p>{getNavigate?.context}</p>
                </Notification>
            }
        </>
    );
};

import styled from 'styled-components';
import { Space, Col, Row, Alert, Badge } from 'antd';

import { Modal } from '../../../components/modal'
import { CalendarMonth } from './calendar-month';
import { BADGE_STATUS } from '../utils';

const StyledWrapper = styled.div`
  border-radius: 5px;
  background: #F0F5FF;
  border: 1px dashed #7B61FF;
`;

export const CalendarModal = ({ handleModalCancel, year, month }) => {

    return (
        <StyledWrapper>
            <Modal
                title=''
                centered={false}
                open={true}
                onCancel={handleModalCancel}
                width='90%'
                height='90vh'
                footer={null}
                headStyle={{ backgroundColor: '#F0F5FF' }}
            >
                <Space direction="horizontal" style={{ paddingTop: '25px' }}>
                    <Row gutter={40}>
                        <Col>
                            <Alert message={
                                <Space>
                                    <Badge color={BADGE_STATUS[0]} text='Ստեղծված' />
                                    <Badge color={BADGE_STATUS[2]} text='Հարցված' />
                                    <Badge color={BADGE_STATUS[3]} text='Մակագրված' />
                                    <Badge color={BADGE_STATUS[4]} text='Տնայց' />
                                    <Badge color={BADGE_STATUS[5]} text='Որոշում' />
                                    <Badge color={BADGE_STATUS[99]} text='Ոչ աշխատանքային օր' />
                                </Space>
                            } />
                            <CalendarMonth year={year} month={month} />
                        </Col>
                    </Row>
                </Space>
            </Modal>
        </StyledWrapper>
    )
}


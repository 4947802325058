import { useApplication } from '../../context/applicaton-context';
import { FirstStep } from './first-step';
import { FourthStep } from './fourth-step';
import { SecondStep } from './second-step';
import { ThirdStep } from './third-step';
import { useCheckApplicationExists } from '../../hooks/application/check-application-exists';
import { useGetApplicationEditData } from '../../hooks/application/get-application-edit-data';
import { Spin } from 'antd';

export const ManageSteps = () => {
  const { step } = useApplication();

  useCheckApplicationExists();
  const { isInitialLoading, isFetched, data } = useGetApplicationEditData();

  return (
    <Spin spinning={isInitialLoading}>
      {step === 0 && <FirstStep />}
      {step === 1 && <SecondStep getCitizenData={isFetched && !data.citizenData?.citizenInfo && step === 1} />}
      {step === 2 && <ThirdStep />}
      {step === 3 && <FourthStep />}
    </Spin>
  );
};

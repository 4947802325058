import {useGetApplicationTypes} from "../../api/benefit/use-get-application-types";
import {URL_BENEFIT_APPLICATION_TYPES} from "../../api/benefit/constants";
import {Avatar, Card} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";
import {PcCard} from "../../components/card/pc-card";
import {useState} from "react";
import {PcModal} from "../../components/modal/pc-modal";
import {COLORS, PATHS} from "../../helpers/constants";
import {useNavigate} from "react-router";

const {Meta} = Card;

const gridStyle = {
    minWidth: '50%',
    height: 110,
    textAlign: 'center',
    cursor: 'pointer',
};

export const BenefitTypes = () => {
    const {data} = useGetApplicationTypes(URL_BENEFIT_APPLICATION_TYPES);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent] = useState();
    const navigate = useNavigate();

    const showModal = (description) => {
        setContent(description)
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setContent('');
        setIsModalOpen(false);
    };

    return (
        <PcCard>
            <PcModal centered title="Տեղեկատվություն" footer={null} open={isModalOpen} onCancel={handleCancel}>
                <p>{content}</p>
            </PcModal>

            {data?.map((item) =>
                <Card.Grid key={item.id} hoverable={false} style={gridStyle}>
                    <InfoCircleFilled onClick={() => showModal(item.description)}
                                      style={{
                                          float: "right",
                                          color: COLORS.SECONDARY.GRAY_MIDLIGHT,
                                          cursor: "pointer"
                                      }}/>
                    <Meta
                        avatar={
                            // eslint-disable-next-line
                            <Avatar size={42} src={require('./images/benefit-icons/' + item.icon)}/>
                        }
                        description={item.name}
                        onClick={() => item.id === 1 && navigate(PATHS.APPLICATION)}
                    />
                </Card.Grid>
            )}
        </PcCard>
    )
};
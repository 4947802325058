import { ActionButton } from './action-button';
import { ReactComponent as Print } from '../../icons/print.svg';
import { COLORS } from '../../helpers/constants';
import { Text } from '../typography';

export const PrintButton = ({ text = 'Տպել փաստաթուղթը', ...props }) => (
  <ActionButton actionIcon={<Print style={{ width: '32px' }} />} {...props}>
    <Text color={COLORS.PRIMARY.BLUE}>{text}</Text>
  </ActionButton>
);

import { Form } from '../../../components/form/form';
import { PersonDataSearchForm } from '../person-data-search-form';
import { URL_VALIDATE_ACCOUNT_BY_SSN } from '../../../api/benefit/constants';
import { Col, Modal, Row, Spin } from 'antd';
import { Button } from '../../button';
import { VerticalSpace } from '../../space/vertical-space';
import {useCallback, useEffect, useState} from 'react';
import { useUserCreate } from '../../../api/auth/use-user-create';
import {convertOldDate, errorMessage} from '../../../helpers/utils';
import {URL_CREATE_ACCOUNT, URL_CREATE_NID_ACCOUNT} from '../../../api/auth/constants';
import { Notification } from '../../modal/Notification';
import { Identification } from 'pages/user-app/identification';
import dayjs from "dayjs";
import {PATHS} from "../../../helpers/constants";
import {useNavigate} from "react-router-dom";

export const SignIn = ({ formItemProp, locationResponse = null, loginAccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if(locationResponse !== null) {
      const dateResponce = new Date(locationResponse?.birthDate).toUTCString();
      const formattedDate = dayjs(dateResponce);

      form.setFieldsValue({
        'firstName': locationResponse?.firstName,
        'lastName': locationResponse?.lastName,
        'ssn': locationResponse?.ssn,
        'patronymicName': locationResponse?.patronymicName,
        'birthDate': formattedDate
      })
    }

  }, [form , locationResponse]);

  const { mutate, isLoading } = useUserCreate(locationResponse === null ? URL_CREATE_ACCOUNT : URL_CREATE_NID_ACCOUNT, {
    onSuccess: () => {
      if(locationResponse === null) {
        setContent('Շարունակելու համար խնդրում ենք ստուգել Ձեր էլեկտրոնային փոստը։');
        setIsModalOpen(true);
      } else {
        navigate(`${PATHS.NIDLOGINCALLBACK}`, {state: {ssn: loginAccess[0], accessToken: loginAccess[1]}})
      }
    },
    onError: errorMessage,
    enabled: false,
  });

  const handleCancel = () => {
    setContent('');
    setIsModalOpen(false);
    window.location.reload(false);
  };

  const onFinish = (values) => {
    const isApproved = form.getFieldValue([formItemProp.isApproved.name]);

    if (!isApproved) {
      Modal.error({
        title: 'Տեղի է ունեցել սխալ',
        content: 'Անձը չի նույնականացվել',
        footer: false,
        closable: true,
      });

      return;
    }
    const birthDate = convertOldDate(values.birthDate);

    delete values.birthDate;
    const result = {
      ...values,
      birthDate,
    };

    mutate(result);
  };

  const onSuccess = useCallback(
    (data) => {
      form.setFieldValue([formItemProp.isApproved.name], data);
      if (data === true) {
        formItemProp.fname.readOnly = true;
        formItemProp.lname.readOnly = true;
        formItemProp.patronymicName.readOnly = true;
        formItemProp.ssn.readOnly = true;
        formItemProp.birthdate.readOnly = true;
      } else {
        form.resetFields();
      }
    },
    [form, formItemProp]
  );

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical" name="application-form-sign-in" form={form} autoComplete="off" onFinish={onFinish}>
        <VerticalSpace>
          <PersonDataSearchForm formItemProp={formItemProp} url={URL_VALIDATE_ACCOUNT_BY_SSN} onSuccess={onSuccess} locationRes={locationResponse}/>
          <Row justify="center">
            <Col span={12}>
              <Button size="large" block type="primary" htmlType="submit">
                Գրանցվել
              </Button>
            </Col>
          </Row>
          <Identification />
        </VerticalSpace>
      </Form>
      {isModalOpen && (
        <Notification centered title="Ծանուցում" footer={null} open={isModalOpen} onCancel={handleCancel}>
          <p>{content}</p>
        </Notification>
      )}
    </Spin>
  );
};

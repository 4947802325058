import { ActionButton } from './action-button';
import { ReactComponent as Upload } from '../../icons/upload-filled.svg';
import { COLORS } from '../../helpers/constants';
import { Text } from '../typography';

const icon = <Upload style={{ width: '32px' }} />;

export const UploadButton = ({ actionIcon = icon, text = 'Ներբեռնել ստորագրված փաստաթուղթը', ...props }) => (
  <ActionButton actionIcon={actionIcon} {...props}>
    <Text color={COLORS.PRIMARY.BLUE}>{text}</Text>
  </ActionButton>
);

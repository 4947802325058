import { COLORS } from '../../helpers/constants';
import { FormSectionCard } from '../card/form-section-card';
import { WarningSection } from '../card/warning-section';
import { VerticalSpace } from '../space/vertical-space';
import { Text } from '../typography';
import { PersonContactForm } from './person-contact-form';
import { PersonMainData } from './person-main-data';

export const PersonDataForm = ({ title, formItemProp, formItemContactProp, viewMode = false }) => {
  return (
    <FormSectionCard title={title}>
      <VerticalSpace>
        <PersonMainData formItemProp={formItemProp} />
        <PersonContactForm formItemContactProp={formItemContactProp} />
        {!viewMode && (
          <WarningSection>
            <Text color={COLORS.SECONDARY.GRAY_DARK}>
              Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան
            </Text>
          </WarningSection>
        )}
      </VerticalSpace>
    </FormSectionCard>
  );
};

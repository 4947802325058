import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { initialValues } from '../components/form/application/utils';
import { ApplicationContent } from '../components/layouts/application/application-content';
import { ApplicationSider } from '../components/sider/application-sider';
import { BackgroundLayout } from '../components/layouts/main-layout';

export const ApplicationContext = createContext();

const STEPS = {
  APPLICATION_START: 'APPLICATION_START',
  PERSONAL_INFO: 'PERSONAL_INFO',
  FAMILY_MEMBERS: 'FAMILY_MEMBERS',
  APPLICATION_FINISH: 'APPLICATION_FINISH',
};

const initialState = {
  step: 0,
  finishedSteps: [],
  submittedData: initialValues,
};

function dataSheetReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'jump':
      return {
        ...state,
        step: payload.step,
      };
    case 'finish':
      return {
        ...state,
        finishedSteps: [...state.finishedSteps, state.step],
        step: state.step + 1,
        submittedData: {
          ...state.submittedData,
          tempApplicationId: payload.id,
        },
      };
    case 'data':
      return {
        ...state,
        submittedData: payload.data,
      };
    case 'citizen-data':
      return {
        ...state,
        cityzenData: payload.data,
      };
    default:
      state;
  }
}

function ApplicationProvider({ children, viewMode = false, ...props }) {
  const [state, dispatch] = useReducer(dataSheetReducer, initialState);

  const finish = useCallback((tempApplicationId) => {
    dispatch({ type: 'finish', payload: { id: tempApplicationId } });
  }, []);

  const addData = useCallback((data) => {
    dispatch({ type: 'data', payload: { data } });
  }, []);

  const addCityzenData = useCallback((data) => {
    dispatch({ type: 'citizen-data', payload: { data } });
  }, []);

  const jump = useCallback((step) => {
    dispatch({ type: 'jump', payload: { step } });
  }, []);

  const providerValues = useMemo(
    () => ({ finish, jump, addData, addCityzenData, ...state }),
    [addCityzenData, addData, finish, jump, state]
  );

  if (viewMode) {
    return (
      <ApplicationContext.Provider value={providerValues} {...props}>
        {children}
      </ApplicationContext.Provider>
    );
  }

  return (
    <ApplicationContext.Provider value={providerValues} {...props}>
      <BackgroundLayout style={{ height: '100%', minHeight: '100vh' }}>
        <ApplicationSider />
        <ApplicationContent>{children}</ApplicationContent>
      </BackgroundLayout>
    </ApplicationContext.Provider>
  );
}

function useApplication() {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error(`useApplication must be used within a ApplicationProvider`);
  }
  return context;
}

export { ApplicationProvider, useApplication, STEPS };

import { Col, Divider, Form, Input, Row, Select } from 'antd';
import { Datepicker } from 'components/datepicker';
import { FormItem } from 'components/form/form-item';
import { PhoneNumber } from 'components/input/phone-number';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { phonePattern } from 'helpers/constants';

export const DisabilityLegalPersonData = () => {
  const documents = Form.useWatch(['applicationForm', 'legalRepresentative', 'documents'], { preserve: true });
  // const phoneNumber = Form.useWatch(['applicationForm', 'legalRepresentative', 'phoneNumber'], { preserve: true });
  const form = Form.useFormInstance();

  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        Օրինական ներկայացուցչի տվյալներ
      </Title>
      <VerticalSpace size="small">
        <Row justify="space-between">
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'firstName']} label="Անուն">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'lastName']} label="Ազգանուն">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'patronymicName']} label="Հայրանուն">
              <Input disabled />
            </FormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'ssn']} label="ՀԾՀ">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'birthDate']} label="Ծննդյան ամսաթիվ ">
              <Datepicker disabled style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col span={7}></Col>
        </Row>
        <Row justify="space-between">
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'gender']} label="Սեռ">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            {/* <FormItem name={['applicationForm', 'legalRepresentative', 'phoneNumber']} label="Հեռախոսի համար" rules={[{ required: true }]}>
              <Input />
            </FormItem> */}
            <Form.Item
              label={'Հեռախոսահամար'}
              rules={[
                {
                  required: true,
                  pattern: phonePattern,
                  message: 'Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով',
                },
              ]}
              name={['applicationForm', 'legalRepresentative', 'phoneNumber']}
            >
              <PhoneNumber />
            </Form.Item>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'legalRepresentative', 'email']} label="Էլեկտրոնային փոստի հասցե ">
              <Input disabled />
            </FormItem>
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <Title level={2} color="#0B847F">
        Անձը հաստատող փաստաթուղթ
      </Title>
      <Row justify="space-between">
        <Col span={10}>
          <FormItem
            name={['applicationForm', 'legalRepresentative', 'documentType']}
            label="ԱՀՓ տեսակ"
            rules={[{ required: true }]}
          >
            <Select
              options={documents}
              labelInValue
              fieldNames={{ label: 'documentType', value: 'documentNumber' }}
              onChange={(option) => {
                form.setFieldsValue({
                  applicationForm: {
                    legalRepresentative: {
                      showDocumentType: option.label,
                      showDocumentNumber: option.value,
                      documentNumber: option.value,
                    },
                  },
                });
              }}
            ></Select>
          </FormItem>
        </Col>
        <Col span={10}>
          <FormItem
            name={['applicationForm', 'legalRepresentative', 'documentNumber']}
            label="ԱՀՓ համար"
            rules={[{ required: true }]}
          >
            <Input disabled />
          </FormItem>
        </Col>
      </Row>
    </VerticalSpace>
  );
};

import { Col, Form, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { URL_ALL_REGIONS } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import {
  URL_GET_COMMUNITY_LIST_BY_REGIONS,
  URL_GET_DONOR_AGREEMENT,
  URL_GET_DONOR_CATEGORIES,
} from 'api/urrp/constants';
import { useGetCommunitiesList } from 'api/urrp/use-get-communities-by-list';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { ArmenianInput } from 'components/input/armenian-input';
import { FIELD_NAMES_DEFAULT } from 'components/select/constants';
import { useAuth } from 'context/auth-context';
import { createOptionsFromArray } from 'helpers/utils';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';
import { useMatches } from 'react-router-dom';

export const ProgramInfo = () => {
  const { user } = useAuth();
  const matches = useMatches();
  const match = matches?.find((match) => match.handle);
  const type = match?.handle?.type;
  const selectedRegions = useWatch(['programDetails', 'regions']);
  const plainRegionList = selectedRegions?.map((region) => {
    return region.value;
  });
  const { data: regions } = useGetFfromBenefit(URL_ALL_REGIONS);
  const { data: sphere } = useGetFfromBenefit(URL_GET_DONOR_CATEGORIES, {
    ...(user.isLegal ? { companyId: user.companyId || user.profileId } : {}),
  });
  const form = useFormInstance();
  const selectedSphere = useWatch(['programDetails', 'sphere'], {
    preserve: true,
  });
  const selectedSupport = useWatch(['programDetails', 'supportType'], {
    preserve: true,
  });

  const { data: communities } = useGetCommunitiesList(
    URL_GET_COMMUNITY_LIST_BY_REGIONS,
    { regionIds: plainRegionList },
    { enabled: !!plainRegionList?.length }
  );

  useGetFfromBenefit(
    URL_GET_DONOR_AGREEMENT,
    {
      categoryBaseId: selectedSupport?.value,
      ...(user.isLegal ? { companyId: user.companyId || user.profileId } : {}),
    },
    {
      enabled: !!selectedSupport?.value && type !== APPLICATION_MODE.VIEW,
      onSuccess: (res) => {
        form.setFieldsValue({
          programDetails: {
            ...res.data,
            agreementDate: res.data.agreementDate,
            agreementDeadline: res.data.agreementValidUntil,
          },
        });
      },
    }
  );

  const onRegionChange = () => {
    form.resetFields([['programDetails', 'communities']]);
  };

  const filterOption = (input, option) =>
    option?.[FIELD_NAMES_DEFAULT.label]?.toLowerCase()?.includes(input.toLowerCase());

  return (
    <FormSectionCard title={'Ծրագրի տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'personName']}
            label={user.isLegal ? 'Իրականացնող կազմակերպություն' : 'Իրականացնող անձ'}
            rules={[{ required: true }]}
            initialValue={user.isLegal ? user.companyName : `${user.firstName} ${user.lastName}`}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'programName']}
            label="Ֆինանսավորող (դոնոր) կազմակերպության անվանումը"
            rules={[{ required: true }]}
          >
            <ArmenianInput />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'regions']} label="Մարզ" rules={[{ required: true }]}>
            <Select
              showSearch
              filterOption={filterOption}
              mode="multiple"
              labelInValue
              allowClear
              options={regions}
              fieldNames={FIELD_NAMES_DEFAULT}
              onChange={onRegionChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'communities']} label="Համայնք" rules={[{ required: true }]}>
            <Select
              showSearch
              filterOption={filterOption}
              mode="multiple"
              labelInValue
              allowClear
              options={communities}
              fieldNames={FIELD_NAMES_DEFAULT}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'sphere']} label="Գործունեության ոլորտ" rules={[{ required: true }]}>
            <Select
              labelInValue
              options={sphere?.map((item) => {
                return { label: item.name, value: item.baseId };
              })}
              onChange={() => form.resetFields([['programDetails', 'supportType']])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'supportType']} label="Աջակցության տեսակ" rules={[{ required: true }]}>
            <Select
              options={createOptionsFromArray(
                sphere?.find((i) => i?.baseId === selectedSphere?.value)?.categories || []
              )}
              labelInValue
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'agreementNumber']}
            label="Համաձայնագրի համար"
            rules={[{ required: true }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'agreementDate']}
            label="Համաձայնագրի ամսաթիվ"
            rules={[{ required: true }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'agreementDeadline']}
            label="Համաձայնագրի վերջնաժամկետ"
            rules={[{ required: true }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};

import { useApplication } from 'context/applicaton-context';
import { formItemPropStepTwo } from 'pages/application/constants';

/** use inside ApplicationProvider Provider */
export const useCitizenSearchValidationForm = () => {
  const { submittedData } = useApplication();

  return {
    ...formItemPropStepTwo,
    ssn: {
      ...formItemPropStepTwo.ssn,
      rules: [
        { required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' },
        {
          message: 'Դիմումատուն և Օրինական ներկայուցուցչը չեն կարող լինել նույն մարդը',
          validator: (_, value) => {
            if (submittedData?.profileData && value === submittedData?.profileData?.legalRepresentative?.ssn) {
              return Promise.reject('not allowed');
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    },
  };
};

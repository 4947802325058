import { ForgotPassword } from '../../../components/form/forgot-password';
import { Col, Row } from 'antd';
import { RegistrationCard } from '../../../components/card/registration-card';
import { armenianLettersPattern } from 'helpers/constants';

export const AppForgotPass = () => {
  const armenianPattern = [
    {
      pattern: armenianLettersPattern,
      message: 'Տեղեկատվությունը սխալ է լրացված',
    },
  ];

  const formItemPropForgotPass = {
    fname: {
      name: ['firstName'],
      rules: armenianPattern,
      readOnly: false,
    },
    lname: {
      name: ['lastName'],
      rules: armenianPattern,
      readOnly: false,
    },
    patronymicName: {
      name: ['patronymicName'],
      rules: armenianPattern,
      readOnly: false,
    },
    ssn: {
      name: ['ssn'],
      rules: [{ required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' }],
      readOnly: false,
    },
    birthdate: {
      name: ['birthDate'],
      readOnly: false,
    },
    isApproved: {
      name: ['isApproved'],
    },
    contact: {
      email: {
        name: ['email'],
        rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
      },
    },
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
      <Col lg={12}>
        <RegistrationCard>
          <ForgotPassword formItemProp={formItemPropForgotPass} />
        </RegistrationCard>
      </Col>
    </Row>
  );
};

import { Layout, Space } from 'antd';
import styled from 'styled-components';

import { ManagePage } from './manage-page';
import { screenSize } from '../../helpers/constants';
import { UserCompanySelect } from 'components/select/user-company-select';

const { Content } = Layout;

const Wrapper = styled(Content)`
  padding: 32px;

  @media only screen and (max-width: ${screenSize.xs}) {
    padding: 0;
  }
`;

export const DefaultContent = ({ children }) => (
  <Wrapper>
    <Space
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 15px',
        flexWrap: 'wrap',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <UserCompanySelect />
      <ManagePage />
    </Space>
    {children}
  </Wrapper>
);

import { useQueryClient } from '@tanstack/react-query';
import {
  URL_GET_APPLICATION,
  URL_GET_INCOMPLETE_APPLICATION,
  URL_NOTIFY_AGGREMENT_ATTACHED,
} from 'api/application/constants';
import { usePostNoMutate } from 'api/application/use-post-no-mutate';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_GET_IS_ALLOWED_TO_ATTACH_AGREEMENT } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_UPLOAD_FILE_AGGREMENT } from 'api/file/constants';
import { Button } from 'components/button';
import { UploadButton } from 'components/button/upload-button';
import { Form } from 'components/form/form';
import { FormItem } from 'components/form/form-item';
import { Modal } from 'components/modal';
import { VerticalSpace } from 'components/space/vertical-space';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { DOCUMENT_TYPES, PATHS } from 'helpers/constants';
import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { URL_DELETE_ALL_FILES } from 'api/application/constants';
import { errorMessage } from 'helpers/utils';
import { useNavigate, useParams } from 'react-router-dom';


export const UploadSubmitDocument = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const navigate = useNavigate();
  const cityzenData = Form.useWatch(['citizenData', 'citizenInfo'], { preserve: true });

  const canAttachAgreement = Form.useWatch('canAttachAgreement', { preserve: true });
  const agreementFilesChecksum = Form.useWatch('agreementFilesChecksum', { preserve: true });

  const [form] = Form.useForm();
  const onFinish = () => {
    setNotify(true);
  };


  const { data } = useGetFfromBenefit(
    URL_GET_IS_ALLOWED_TO_ATTACH_AGREEMENT,
    { applicationId: params.id },
    {
      enabled: !!(canAttachAgreement && params.id),
      onError: (er) => {
        Modal.error({
          title: false,
          content: <div dangerouslySetInnerHTML={{ __html: er.response?.data?.Message }} />,
          onCancel: () => navigate(PATHS.APPLICATIONS),
          footer: false,
          closable: true,
        });
      },
    }
  );

  const { mutate: deleteAllFiles } = useSaveApplication(URL_DELETE_ALL_FILES, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        params.status && params.status === 'incomplete'
          ? URL_GET_INCOMPLETE_APPLICATION
          : URL_GET_APPLICATION,
      ]);
      form.setFieldValue('agreementFilesChecksum', null);

    },
    onError: errorMessage,
  });

  usePostNoMutate(
    URL_NOTIFY_AGGREMENT_ATTACHED,
    {
      applicationId: params.id,
    },
    {
      enabled: !!(notify && params.id),
      onSuccess: () => {
        setNotify(false);
        setOpen(false);
        queryClient.invalidateQueries({
          queryKey: [
            params.status && params.status === 'incomplete' ? URL_GET_INCOMPLETE_APPLICATION : URL_GET_APPLICATION,
          ],
        });
        Modal.success({
          title: 'Շնորհակալություն',
          content: 'Փաստաթուղթը հաջողությամբ կցվել է։',
          footer: false,
          closable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (agreementFilesChecksum && agreementFilesChecksum.length) {
      form.setFieldValue('agreementFilesChecksum', agreementFilesChecksum);
    }
  }, [agreementFilesChecksum, form]);
  const handleCancel = () => {
    const fileChecksum = form.getFieldValue('agreementFilesChecksum')

    if (fileChecksum && fileChecksum.length > 0) {
      const checksums = fileChecksum
        .filter((item) => item.status === 'done' && item.response?.fileChecksum)
        .map((item) => item.response.fileChecksum);
      deleteAllFiles(checksums);

    }
    setOpen(false);
  }
  return (
    <>
      <UploadButton
        text={canAttachAgreement ? 'Կցել թերթիկը' : 'Դիտել կցված թերթիկը'}
        onClick={() => setOpen(true)}
        block
        disabled={false}
      />
      <Modal
        className="project-modal"
        open={open}
        footer={false}
        onCancel={handleCancel}
        width={788}
        maxWidth={'90%'}
        closable={!canAttachAgreement}
        maskClosable={!canAttachAgreement}
      >
        <Form
          name="upload-documents"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          disabled={false}
        >
          {data !== false && (
            <VerticalSpace>
              <UploadApplicationDocument
                text="Կցել թերթիկը"
                checksumName="agreementFilesChecksum"
                attachedDocumentType={DOCUMENT_TYPES.FAMILY_MEMEBER}
                infoText="Խնդրում ենք կցել հավաստման թերթիկը՝ ստորագրված Ձեր և ընտանիքի անդամների կողմից"
                noInfo={!canAttachAgreement}
                userData={cityzenData}
                uploadUrl={URL_UPLOAD_FILE_AGGREMENT}
                viewMode={!canAttachAgreement}
                disabled={!canAttachAgreement}
              />
              {canAttachAgreement && (
                <FormItem >
                  <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }} key="footer-space">
                    <Button key="cancel" onClick={handleCancel}>
                      Չեղարկել
                    </Button>
                    <Button htmlType="submit" block type="primary">
                      Հաստատել
                    </Button>
                  </Space>
                </FormItem>
              )}
            </VerticalSpace>
          )}
        </Form>
      </Modal>
    </>
  );
};

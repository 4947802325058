import { Checkbox } from 'antd';
import { FormItem } from '../../form-item';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { Info } from 'components/typography/info';
import { useApplicationForText } from 'hooks/application/use-application-for-text';

export const ApplciationNotification = () => {
  return (
    <>
      <FormItem propName="checked" name="isNotifyBySMS">
        <Checkbox.Group>
          <Checkbox value={true}>
            <Text color={COLORS.PRIMARY.BLUE}>{`Ծանուցումները ցանկանում ${useApplicationForText(
              'եմ',
              'ենք'
            )} ստանալ նաեւ SMS-ով`}</Text>{' '}
          </Checkbox>
        </Checkbox.Group>
      </FormItem>
      <Info text="Անկախ պատասխանից ծանուցումները կուղարկվեն նաեւ էլ. փոստով։" />
    </>
  );
};

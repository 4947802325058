import { Skeleton } from 'antd';
import { forwardRef } from 'react';
import { Select } from '.';
import { URL_POSTAL_OFFICES_BY_REGION_ID } from '../../api/benefit/constants';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { FIELD_NAMES_POSTAL_CODE } from './constants';

export const PostIndexSelect = forwardRef(({ regionId, ...props }, ref) => {
  const { data, isInitialLoading } = useGetFfromBenefit(
    URL_POSTAL_OFFICES_BY_REGION_ID,
    { regionId: regionId },
    { enabled: !!regionId }
  );
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />;
  }

  return <Select fieldNames={FIELD_NAMES_POSTAL_CODE} style={{ width: '100%' }} options={data} {...props} ref={ref} />;
});

import { Form } from 'components/form/form';
import { useApplication } from 'context/applicaton-context';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';

export const useApplicationForText = (trueText, falsetext) => {
  const applicationFor = Form.useWatch('fillingApplication');
  const { submittedData } = useApplication();

  return applicationFor === APPLICATION_FOR_HIMSELF || submittedData.fillingApplication === APPLICATION_FOR_HIMSELF
    ? trueText
    : falsetext;
};

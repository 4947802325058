import { Table } from 'antd';
import { Input } from 'components/input';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #0b847f;
  }
`;

const columnsTableInfo = [
  {
    title: 'Որոշման համար',
    dataIndex: 'decisionNumber',
    align: 'center',
    width: 200,
  },
  {
    title: 'Ֆունկցիոնալության գնահատման ամսաթիվ',
    dataIndex: 'functionalityEvaluationDate',
    align: 'center',
    width: 200,
  },
  {
    title: 'Ֆունկցիոնալության գնահատման տեսակ',
    dataIndex: 'typeOfFunctionalEvaluation',
    align: 'center',
    width: 200,
  },
  {
    title: 'Իրավունակ է բողոքարկման համար',
    dataIndex: 'eligibleForAppeal',
    align: 'center',
    width: 200,
  },
];

export const DisabilityDecisionInfo = ({ dataDecision }) => {
  const dataSource = [
    {
      decisionNumber: <Input size="large" disabled value={dataDecision ? dataDecision.decisionNumber : ''} />,
      functionalityEvaluationDate: (
        <Input size="large" disabled value={dataDecision ? dataDecision.evaluationDate : ''} />
      ),
      typeOfFunctionalEvaluation: (
        <Input size="large" disabled value={dataDecision ? dataDecision.evaluationType : ''} />
      ),
      eligibleForAppeal: (
        <Input size="large" disabled value={dataDecision ? (dataDecision.isAllowed === true ? 'Այո' : 'Ոչ') : ''} />
      ),
    },
  ];

  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        Բողոքարկման համար անհրաժեշտ որոշման առկայություն
      </Title>
      <StyledTable columns={columnsTableInfo} dataSource={dataSource} pagination={false} />
    </VerticalSpace>
  );
};
